<template>
  <VueSelect
    :options="options"
    :multiple="multiple"
    :value="value"
    :filterable="false"
    @input="$emit('input', $event)"
    :reduce="reduce"
    label="name"
  ></VueSelect>
</template>

<script>
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'CompanySelect',
  components: { VueSelect },
  props: {
    value: {
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    reduce: {
      type: Function,
      default: item => item.id
    }
  },
  data () {
    return {
      options: []
    }
  },
  mounted () {
    this.options = this.$store.getters['auth/loggedUser'].companies
  }
}
</script>

<style scoped>

</style>
