<template>
  <b-dropdown
    id="#ban-user-dropdown"
    ref="banDropdown"
    size="sm"
    :variant="user.ban_until >= timestamp ? 'danger' : 'outline-danger'"
    no-caret
  >
    <template #button-content>
      <span v-if="user.ban_until >= timestamp"><b-icon icon="lock"></b-icon></span>
      <span v-else> <b-icon icon="unlock"></b-icon> </span>
    </template>
    <b-dropdown-text style="width: 380px;">
      <h6 v-if="user.ban_until >= timestamp" class="font-weight-bold text-danger mb-0">
        <template v-if="user.ban_until === '2100-01-01 00:00:00'">Șofer blocat permanent</template>
        <template v-else>Blocat până la - {{ formatBanDateTime(user.ban_until) }}</template>
      </h6>
      <h6 v-else class="font-weight-bold text-success mb-0">Șofer activ</h6>
    </b-dropdown-text>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item-button
      v-if="user.ban_until !== null"
      @click="unban"
    >
      Deblocare
    </b-dropdown-item-button>
    <b-dropdown-item-button
      v-if="user.ban_until === null"
      @click="banHours(3)"
    >
      Blocare 3 ore
    </b-dropdown-item-button>
    <b-dropdown-item-button
      v-if="user.ban_until === null"
      @click="banHours(12)"
    >
      Blocare 12 ore
    </b-dropdown-item-button>
    <b-dropdown-item-button
      v-if="user.ban_until === null"
      @click="banHours(24)"
    >
      Blocare 24 ore
    </b-dropdown-item-button>
    <b-dropdown-item-button
      v-if="user.ban_until === null"
      @click="banHours(48)"
    >
      Blocare 48 ore
    </b-dropdown-item-button>
    <b-dropdown-item-button
      v-if="user.ban_until === null"
      @click="banDays(4)"
    >
      Blocare 4 zile
    </b-dropdown-item-button>
    <b-dropdown-form @submit.prevent="banUntil" v-if="false">
      <b-form-group label="Ban until" label-for="dropdown-form-ban-until" class="mb-0">
        <b-form-datepicker :class="{'is-invalid': !isValidBanDate }" v-model="banDateTime.banDate"></b-form-datepicker>
        <b-form-timepicker v-model="banDateTime.banTime" class="mt-2"></b-form-timepicker>
        <div class="text-center mt-2">
          <b-button type="submit">Blocare șofer</b-button>
        </div>
      </b-form-group>
    </b-dropdown-form>
    <b-dropdown-divider v-if="user.ban_until === null"></b-dropdown-divider>
    <b-dropdown-item-button
      v-if="user.ban_until === null"
      @click="banForever"
    >
      Blocare permanentă
    </b-dropdown-item-button>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item-button
      @click="closeUserBanDropdown"
    >
      Închide fereastra
    </b-dropdown-item-button>
  </b-dropdown>
</template>

<script>

import dayjs from 'dayjs'
import config from '@/config'

export default {
  name: 'UserBanControl',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  mounted () {
    if (this.user.ban_until) {
      const date = dayjs(this.user.ban_until, 'YYYY-MM-DD HH:mm:ss')
      this.banDateTime.banDate = date.format('YYYY-MM-DD')
      this.banDateTime.banTime = date.format('HH:mm:ss')
    }
    setInterval(this.getNow, 1000)
  },
  data () {
    return {
      isBanDropdownCloseable: false,
      timestamp: null,
      banDateTime: {
        banDate: null,
        banTime: '00:00:00'
      },
      isValidBanDate: true
    }
  },
  computed: {
    banTimestamp () {
      const date = this.banDateTime.banDate
      const time = this.banDateTime.banTime
      if (date && time) {
        return date + ' ' + time
      }
      return null
    }
  },
  methods: {
    getNow () {
      const today = new Date()
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
      const dateTime = date + ' ' + time
      this.timestamp = dateTime
    },
    formatBanDateTime (value) {
      return this.$day.utc(value).local().format('DD MMM YYYY HH:mm:ss')
    },
    closeUserBanDropdown () {
      this.isBanDropdownCloseable = true
      this.$refs.banDropdown.hide()
    },
    banHours (hours) {
      return this.saveBan({
        unit: 'hour',
        amount: hours
      })
        .then(() => {
          this.$toasted.success('Driver banned', {
            duration: 3000
          })
          this.closeUserBanDropdown()
        })
    },
    banDays (days) {
      return this.saveBan({
        unit: 'day',
        amount: days
      })
        .then(() => {
          this.$toasted.success('Driver banned', {
            duration: 3000
          })
          this.closeUserBanDropdown()
        })
    },
    banForever () {
      return this.saveBan({
        ban_forever: true
      })
        .then(() => {
          this.$toasted.success('Driver banned', {
            duration: 3000
          })
          this.closeUserBanDropdown()
        })
    },
    banUntil () {
      this.isValidBanDate = true
      if (!this.banDateTime.banDate) {
        this.isValidBanDate = false
      } else {
        return this.saveBan({
          ban_until: this.banTimestamp
        })
          .then(() => {
            this.$toasted.success('Driver banned', {
              duration: 3000
            })
            this.closeUserBanDropdown()
          })
      }
    },
    unban () {
      return this.saveBan({})
        .then(() => {
          this.user.ban_until = null
          this.$toasted.success('Driver unbanned', {
            duration: 3000
          })
          this.closeUserBanDropdown()
        })
    },
    saveBan (data) {
      if (typeof data.unit !== 'undefined' || typeof data.ban_forever !== 'undefined') {
        return this.$dialog
          .prompt({
            title: 'Please provide ban reason'
          }, {
            promptHelp: 'Type in the box below and click "[+:okText]"',
            okText: 'Ban user',
            cancelText: 'Close'
          })
          .then(dialog => {
            data.reason = dialog.data
            return this.$axios.patch(config.baseApiUrl + '/api/users/' + this.user.id + '/ban', data)
              .then((response) => {
                this.user.ban_until = response.data.date
                this.user.ban_reason = response.data.reason
              })
          })
      }
      return this.$axios.patch(config.baseApiUrl + '/api/users/' + this.user.id + '/ban', data)
        .then((response) => {
          this.user.ban_until = response.data.date
          this.user.ban_reason = response.data.reason
        })
    }
  }
}
</script>

<style scoped>

</style>
