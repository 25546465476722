<template>
  <div class="income-calls px-1 overflow-hidden">
    <b-row class="my-2 justify-content-start flex-nowrap">
      <b-button-group
        v-for="data in pendingCalls"
        size="sm"
        class="mx-1 mr-2"
        :key="'call' + data.phoneNumber"
        style="min-width: 180px"
        @click="acceptCall({ phoneNumber: data.phoneNumber, sipCallerId: data.callSession._remote_identity._uri._user })"
      >
        <b-button variant="danger" v-if="data.phoneNumber === '+437367311180' || data.phoneNumber === '+40748818929'">PROBA (Ignorați)</b-button>
        <b-button v-else  variant="success"> {{ data.phoneNumber }}</b-button>
      </b-button-group>
      <b-skeleton type="button" v-for="i in freeLinesCount"  variant="dark" size="sm"  class="ml-1 mr-2" style="opacity: .1; min-width: 180px" :key="'fl-' + i">
      </b-skeleton>
    </b-row>
    <b-button v-if="getShortkeysType() === 'lines'" class="d-none" v-shortkey="['`']" @shortkey="quickAcceptCall"></b-button>
    <b-button v-else class="d-none" v-shortkey="['f1']" @shortkey="quickAcceptCall"></b-button>
  </div>
</template>

<script>
import jsSip from '@/services/jsSip'
// import sipPhoneFormatter from '@/services/sipPhoneFormatter'

export default {
  name: 'PendingCalls',
  computed: {
    pendingCalls () {
      return this.$store.getters['sip/pendingCalls']
    },
    pendingCallsCount () {
      return Object.keys(this.pendingCalls).length
    },
    freeLinesCount () {
      return this.pendingCallsCount >= 15 ? 0 : (15 - this.pendingCallsCount)
    }
  },
  mounted () {
    this.getShortkeysType()
    this.$store.dispatch('blockedPhone/getList')
  },
  methods: {
    getShortkeysType () {
      if (localStorage.lineAnswer) {
        return localStorage.lineAnswer
      } else {
        return 'simple'
      }
    },
    acceptCall (payload) {
      console.log('------', payload, '-----', this.pendingCalls)
      this.$router.push({ name: 'OperatorOrderView', params: { id: 0 } })
      try {
        this.$store.dispatch('userAddresses/getUserAddresses', {
          phone: payload.phoneNumber
        }).then((response) => {
          console.log('------>>>>>', response)
          this.$eventBus.triggerEvent('userAddressesLoaded', response)
        })
      } catch (e) {

      }
      for (const phone in this.$store.state.sip.calls) {
        if (this.$store.state.sip.calls[phone].callSession._is_confirmed === true) {
          if (payload.phoneNumber !== phone && this.$store.state.sip.calls[phone].callSession.isOnHold().local === false) {
            this.$store.state.sip.calls[phone].callSession.hold(this.$store.getters['auth/useSipUpdate'])
          }
        }
      }
      this.$store.state.sip.calls[payload.phoneNumber].callSession.answer(jsSip.answerOptions)
      this.$emit('call-accepted', payload)
    },
    quickAcceptCall () {
      const phones = Object.keys(this.pendingCalls)
      if (typeof this.pendingCalls[phones[0]] !== 'undefined') {
        this.acceptCall({ phoneNumber: this.pendingCalls[phones[0]].phoneNumber })
      }
    }
  }
}
</script>

<style scoped>

</style>
