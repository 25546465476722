<template>
  <div>
    <b-button v-if="$store.state.messages.unreadCount == 0" tabindex="0" id="showNotifications" @click="toggleChatVisibility" class="ml-1 mr-md-1  pull-right" size="sm" variant="outline-dark">
      <span class="d-none d-md-inline mr-2"><b-icon icon="envelope"></b-icon> </span>
      <b-badge variant="dark">{{ $store.state.messages.unreadCount }}</b-badge>
    </b-button>
    <b-button v-else tabindex="0" id="showNotifications" @click="toggleChatVisibility" class="ml-1 mr-md-1  pull-right" size="sm" variant="danger">
      <span class="d-none d-md-inline mr-2"><b-icon animation="fade" icon="envelope-fill"></b-icon> </span>
      <b-badge variant="light">{{ $store.state.messages.unreadCount }}</b-badge>
    </b-button>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'MessagesBadge',
  data () {
    return {
      messageText: null
    }
  },
  created () {
    this.$axios.get(config.baseApiUrl + '/api/messages/unread-count')
      .then((response) => {
        this.$store.state.messages.unreadCount = response.data.count
      })

    this.$eventBus.addEventListener('ws_message_received', (event) => {
      this.$store.dispatch('messages/messageReceived', {
        message: event.message
      })
    }, 'messagesBadge')
  },
  mounted () {
    this.$eventBus.addEventListener('ws_message_received', (event) => {
      const message = event.message
      console.log(message)
      this.$axios.get(config.baseApiUrl + '/api/users/' + message.sender_id)
        .then((response) => {
          const from = response.data.default_car ? response.data.default_car.indicativ : response.data.name
          this.$bvToast.toast(message.message, {
            title: 'Mesaj nou de la: ' + from,
            autoHideDelay: 5000,
            variant: 'success',
            solid: true
          })
          const sound = localStorage.getItem('default_sound') ? localStorage.getItem('default_sound') : '/audio/sound.wav'

          const audio = new window.Audio(sound)
          audio.muted = true
          audio.muted = false
          audio.play()
        })
    })
  },
  beforeDestroy () {
    this.$eventBus.removeEventListener('ws_message_received', 'messagesBadge')
  },
  methods: {
    getName (user) {
      this.$axios.get(config.baseApiUrl + '/api/users/' + user)
        .then((response) => {
          this.userName = response.data.name
        })
    },
    toggleChatVisibility () {
      if (!this.$store.state.messages.isOpen) {
        this.$store.dispatch('messages/fetchConversations')
      }
      this.$store.state.messages.isOpen = !this.$store.state.messages.isOpen
      this.$store.state.messages.activeConversationIndex = 0
    }
  }
}
</script>

<style scoped>

</style>
