<template>
  <div>
    <small v-if="canceledDriver !== null">
      <strong>{{ canceledDriver.default_car ? canceledDriver.default_car.indicativ : 'xxx' }}</strong>: {{ this.order.cancellation_reason_text.split('~')[1] }}
    </small>
  </div>
</template>

<script>
import config from '@/config'
export default {
  name: 'OrderCancelationReason',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      canceledDriver: null
    }
  },
  mounted () {
    if (this.order.cancellation_reason_type === 'driver_canceled') {
      this.$axios.get(config.baseApiUrl + '/api/users/' + this.order.cancellation_reason_text.split('~')[0])
        .then((response) => {
          this.canceledDriver = response.data
        })
    }
  }
}
</script>

<style>

</style>
