<template>
  <div>
    <div class="signUp-splash">
      <div class="left">
        <div class="text">
          <h1>Înregistrare</h1>
          <h2>Acces la versiunea demo a aplicațiilor mobile, dashboard administrator și operator.</h2>
        </div>
      </div>
      <div class="right">
          <b-form class="signUp-form" @submit.prevent="createCompany">
            <b-row>
              <b-col md="6">
                  <b-input type="text" @input="getCui" v-model.number="company.cui" placeholder="CUI"></b-input>
                  <b-input type="text" v-model="company.user_email" placeholder="E-mail"  autocomplete="new-email"></b-input>
                  <b-input type="text" v-model="company.phone" placeholder="Telefon" autocomplete="off"></b-input>
              </b-col>
              <b-col md="6">
                  <b-input  type="password" v-model="company.user_password" placeholder="Setează parolă" autocomplete="off"></b-input>
                  <b-input type="text" v-model="company.user_name" placeholder="Persoana de contact" autocomplete="off"></b-input>
                  <b-form-select v-model="company.package_id" :options="options"></b-form-select>
              </b-col>
            </b-row>
            <div v-if="companyFinded" class="company-square">
              <strong>{{ company.name }}</strong> <br>
              <span>{{ company.address }} </span>
            </div>
            <div class="bottom">
              <div class="my-3">
                <b-form-checkbox required>
                  Sunt de acord cu Politica de confidențialitate și Politica pentru utilizatori a OGO și sunt de acord să primesc e-mailuri periodice de la OGO.
                </b-form-checkbox>
              </div>
              <div>
                <button class="bottom-btn" type="submit">Începe perioada de probă</button>
              </div>
            </div>
          </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import auth from '@/services/auth'
import router from '@/router'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Vue from 'vue'

Vue.use(VueGoogleAutocomplete, {
  load: {
    key: config.googleMapsApiKey,
    libraries: 'places'
  }
})

export default {
  name: 'SignUp',
  components: { },
  data () {
    return {
      place: {
        city: ''
      },
      company: {
        id: null,
        name: null,
        package_id: null,
        phone: null,
        address: null,
        lat: null,
        lng: null,
        euid: null,
        description: null,
        logo: null,
        user_name: null,
        user_email: null,
        user_password: null
      },
      options: [
        { value: null, text: 'Tip afacere' },
        { value: '1', text: 'Dispecerat taxi' },
        { value: '2', text: 'Firmă curierat' },
        { value: '3', text: 'Restaurant' }
      ]
    }
  },
  mounted () {
    this.$refs.address.focus()
  },
  methods: {
    getAddressData: function (addressData, placeResultData, id) {
      this.address = addressData
    },
    getPlace () {
      this.$axios({
        method: 'get',
        url: config.addrApiUrl + '/places-search?input=' + this.company.address
      })
        .then((response) => {
          this.place.city = response.data.predictions[0].terms[2].value
          this.$axios({
            method: 'get',
            url: config.addrApiUrl + '/place-details?place_id=' + response.data.predictions[0].place_id + '&fields=geometry'
          })
            .then((response) => {
              this.company.lat = response.data.result.geometry.location.lat
              this.company.lng = response.data.result.geometry.location.lng
            })
        })
    },
    getCui () {
      const onlyCui = this.company.cui.toString().includes('RO') ? this.company.cui.slice(2, 20) : this.company.cui

      this.$axios({
        method: 'get',
        url: config.baseApiUrl + '/api/check-cui',
        params: { cui: onlyCui }
      })
        .then((response) => {
          this.company.name = response.data.found[0].denumire
          if (response.data.found[0].scpTVA === true) {
            this.company.cui = 'RO' + response.data.found[0].cui
          }
          this.company.euid = response.data.found[0].cui
          this.company.address = response.data.found[0].adresa
          this.getPlace()
          this.companyFinded = true
          // console.log(response.data.found[0])
        })
    },
    createCompany () {
      this.$axios({
        method: 'post',
        url: config.baseApiUrl + '/api/company',
        data: this.company
      })
        .then((response) => {
          this.companyData = response.data
          this.$toasted.success('Saved', {
            duration: 3000
          })
          auth.login(this.company.user_email, this.company.user_password)
            .then(() => {
              router.push('/dashboard')
              this.$toasted.success('You successfully logged in')
            })
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
