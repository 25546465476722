<template>
  <div style="max-height: 200px; overflow: auto">
    <b-list-group style="border:none;">
      <b-list-group-item style="cursor:pointer"
        v-for="address in addresses"
        :key="'uas-' + address.id"
        :value="address"
        @click="$emit('selected', address)"
        class="d-flex p-1 pl-2 justify-content-between align-items-center"
       button >
        {{ address.name }} {{ address.comment ? (' - ' + address.comment) : '' }}>
        <b-button @click="deleteAddress(address.id)" variant="outline-danger" size="sm" to="">Sterge</b-button>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'ClientAddressesHistory',
  props: {
    userId: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      addresses: [],
      selected: []
    }
  },
  methods: {
    deleteAddress (placeId) {
      if (confirm('Doriti sa stergeti aceasta adresa?') === true) {
        this.$axios.patch(config.baseApiUrl + '/api/dispatcher-orders/' + this.userId + '/address-history-delete/' + placeId)
          .then((response) => {
            this.updateAddresses()
          })
      }
    },
    updateAddresses () {
      this.$axios.get(config.userAddressesUrl + '/addresses/' + this.userId)
        .then((response) => {
          this.addresses = response.data
        })
    }
  },
  mounted () {
    return this.updateAddresses()
  }

}
</script>

<style scoped>

</style>
