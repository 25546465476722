<template>
  <header class="header">
    <div class="container-fluid">
      <b-row align-v="center">
        <b-col cols="4" sm="6" md="auto" class="header-brand mr-auto">
          <div class="d-flex align-items-center justify-content-between">
          <router-link to="/dashboard" class="taxi-brand">
          <span class="d-block">OGO</span>
          </router-link>

          <b-button style="width: 80px" class="ml-3 pull-right d-none d-md-block" size="sm" variant="light">
            {{ timeNow }}
          </b-button>
          </div>
        </b-col>
        <b-col class="header-menu" cols="12" md="auto">
          <b-nav>
            <b-nav-item to="/dashboard" active>Dashboard</b-nav-item>
            <b-nav-item to="/operator" >Operator</b-nav-item>
            <b-nav-item-dropdown
              text="Utilizatori"
            >
              <b-dropdown-item to="/users/dispatcher">Operatori</b-dropdown-item>
              <b-dropdown-item to="/users/driver">Șoferi</b-dropdown-item>
              <b-dropdown-item to="/users/client">Clienți</b-dropdown-item>
              <b-dropdown-item to="/users/company">Protocol</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item to="/cars">Transport</b-dropdown-item>
              <b-dropdown-item to="/devices">Devaisuri</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item v-if="$store.state.auth.user.roles.includes('admin')" to="/users/admin">Administratori</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              text="Financiar"
            >
              <b-dropdown-item to="/finances/sold">Sold șoferi</b-dropdown-item>
              <b-dropdown-item to="/finances/transfer">Transfer balanță</b-dropdown-item>
              <b-dropdown-item to="/finances/transfer-discount">Transfer balanță (discount)</b-dropdown-item>
              <b-dropdown-item to="/discounts">Discounts</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item to="/finances/reports">Rapoarte</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item to="/finances">Setări</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item to="/settings/polygons">Delimitare zone</b-nav-item>
            <b-nav-item to="/reports/orders">Rapoarte</b-nav-item>
            <b-nav-item-dropdown
              text="Sistem"
            >
              <b-dropdown-item to="/content">Conținut</b-dropdown-item>
              <b-dropdown-item variant="danger" to="/settings">Setări</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-nav>
        </b-col>
        <b-col cols="6" sm="6" md="auto" class="header-right ml-auto">
          <b-dropdown class=" pull-right" id="top-profile" right :text="'Salut, ' + this.$store.getters['auth/loggedUser'].name" size="sm" variant="success">
            <router-link to="/operator" class="dropdown-item">Panou operator</router-link>
            <router-link to="/settings/account" class="dropdown-item">Setări cont</router-link>
            <router-link to="/logout" class="dropdown-item">Logout</router-link>
          </b-dropdown>
          <MessagesBadge></MessagesBadge>
          <b-tooltip target="top-phone" title="Nu ai niciun apel pierdut"></b-tooltip>
          <b-button class="ml-1 pull-right  d-block d-md-none" size="sm" variant="dark">
            <b-icon icon="menu-button-wide"></b-icon>
          </b-button>

        </b-col>
      </b-row>
    </div>
    <audio id="jssip-audio"></audio>
    <Chat v-if="$store.state.messages.isOpen"></Chat>
  </header>
</template>

<script>
import NewClientCallToast from './NewClientCallToast'
import Chat from '../common/chat/Chat.vue'
import MessagesBadge from '@/components/common/chat/MessagesBadge'

export default {
  name: 'AppHeader',
  components: {
    MessagesBadge,
    // eslint-disable-next-line vue/no-unused-components
    NewClientCallToast,
    Chat
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    notificationsCount () {
      return this.$store.getters['messages/getConversations'].filter((conversation) => {
        return conversation.has_unread
      }).length
    },
    timeNow () {
      return this.$day(this.$store.state.now).format('HH:mm:ss')
    }
  }
}
</script>

<style scoped>

</style>
