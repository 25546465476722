<template>
  <div class="home">
    <DispatcherDashboard></DispatcherDashboard>
  </div>
</template>

<script>

import DispatcherDashboard from '@/components/dashboard/DispatcherDashboard'
export default {
  name: 'Dashboard',
  components: { DispatcherDashboard }
}
</script>
