<template>
  <div>
    <OrderForm v-if="orderInView" :order="orderInView"></OrderForm>
    <div>
      <OrderDriverSearch v-if="orderInView && orderInView.id" :order="orderInView"></OrderDriverSearch>
    </div>
    <div v-if="orderInView && orderInView.id"  class="pl-3">
      <OrderTimeline v-if="orderInView && orderInView.id" :order-id="orderInView.id"></OrderTimeline>
    </div>
  </div>
</template>

<script>
import OrderForm from '@/components/dashboard/Operator/OrderForm'
import OrderDriverSearch from '@/components/dashboard/Operator/OrderDriverSearch'
import OrderTimeline from '@/components/common/OrderTimeline'

export default {
  name: 'OrderView',
  components: {
    OrderTimeline,
    OrderForm: OrderForm,
    OrderDriverSearch: OrderDriverSearch
  },
  data () {
    return {
      checkInterval: null
    }
  },
  mounted () {
    this.$store.dispatch('dispatcherDashboard/getServices')
    this.getOrderData()
    this.checkInterval = setInterval(() => {
      // console.log(this.$store.getters['sip/activeCall'], this.$store.getters['sip/activeCall'].acceptStarted, this.$store.getters['sip/activeCall'].acceptFinished, this.$route.params.id)
      // eslint-disable-next-line eqeqeq
      if (this.$store.getters['sip/activeCall'] && this.$store.getters['sip/activeCall'].acceptStarted === false && this.$store.getters['sip/activeCall'].acceptFinished === false && this.$route.params.id == 0) {
        this.$store.dispatch('dispatcherDashboard/acceptCall', {
          phoneNumber: this.$store.getters['sip/activeCallPhone'],
          sipCallerId: this.$store.getters['sip/activeCall'].callSession._remote_identity._uri._user
        })
          .then((response) => {
            this.$store.commit('dispatcherDashboard/onCallAccepted', {
              phoneNumber: this.$store.getters['sip/activeCallPhone'],
              data: response.data
            })
          })
        this.$store.state.dispatcherDashboard.orderInView.phone = this.$store.getters['sip/activeCallPhone']
        this.$store.state.dispatcherDashboard.orderInView.client.phone = this.$store.getters['sip/activeCallPhone']
        this.$store.dispatch('userAddresses/getUserAddresses', {
          phone: this.$store.getters['sip/activeCallPhone']
        }).then((response) => {
          this.$eventBus.triggerEvent('userAddressesLoaded', response)
        })
      }
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.checkInterval)
  },
  computed: {
    orderInView () {
      return this.$store.getters['dispatcherDashboard/getOrderInView']
    }
  },
  methods: {
    getOrderData () {
      const orderId = parseInt(this.$route.params.id)
      if (this.$store.state.dispatcherDashboard.orderInView === null || this.$store.state.dispatcherDashboard.orderInView.id !== orderId) {
        if (orderId !== 0) {
          this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: this.$route.params.id })
        } else {
          this.$store.commit('dispatcherDashboard/createNewOrder')
        }
      }
    }
  },
  watch: {
    '$route.params.id' () {
      this.getOrderData()
    }
  }
}
</script>

<style scoped>

</style>
