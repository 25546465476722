<template>
  <div class="mt-2">
    <template v-if="orders">
      <OrdersList :orders="orders"></OrdersList>
    </template>
    <div v-else class="text-center">Loading...</div>
  </div>

</template>

<script>
import OrdersList from '@/components/dashboard/Operator/Orders/OrdersList'

export default {
  name: 'InCallOrders',
  props: {
    ordersView: {
      type: String,
      required: true
    }
  },
  components: {
    OrdersList
  },
  mounted () {
    this.fetchOrders()
    this.$eventBus.addEventListener('ws_live_orders_updated', (payload) => {
      this.$store.commit('dispatcherDashboard/setOrders', {
        orders: payload.orders
      })
    }, 'liveOrdersUpdated')
    this.$eventBus.addEventListener('remove_live_order', (event) => {
      this.$store.commit('dispatcherDashboard/removeOrder', {
        orderId: event.orderId,
        predicate: order => order.state !== 'new'
      })
    }, 'remove_live_order')
  },
  beforeDestroy () {
    this.$eventBus.removeEventListener('ws_live_orders_updated', 'liveOrdersUpdated')
    this.$eventBus.removeEventListener('remove_live_order', 'remove_live_order')
  },
  data () {
    return {
      updateInterval: null
    }
  },
  computed: {
    orders () {
      const ownOrders = []
      const otherOrders = []
      this.$store.getters['dispatcherDashboard/getOrders'].forEach((order) => {
        if (order.dispatcher_id === this.$store.getters['auth/loggedUser'].id) {
          ownOrders.push(order)
        } else {
          otherOrders.push(order)
        }
      })
      return [...ownOrders, ...otherOrders]
    }
  },
  methods: {
    fetchOrders () {
      window.rowKeyArr = {}
      this.$store.dispatch('dispatcherDashboard/getOrders', { type: 'inCall', useCommit: true })
    }
  },
  watch: {
    ordersView: function (val) {
      if (val === 'inCall') {
        this.fetchOrders()
      }
    }
  }
}
</script>

<style scoped>

</style>
