<template>
  <div>
    <div class="row-wp">
      <div class="left-bar">
        <OperatorSideBar/>
      </div>
      <div class="main-bar">
        <div class="order-bar py-2 d-block d-md-flex justify-content-between">
          <div class="order-bar-btn">
            <router-link :to="{ name: 'DeliveryOrderView', params: { id: 0 } }" class="btn btn-sm btn-success">
              Comandă nouă
            </router-link>
          </div>
          <div class="d-flex">

          <div class="operatorLiveStats mr-auto">
            <b-button size="sm" variant="light" class="statItem" v-b-tooltip.hover.bottom title="Comenzi în ultimele 24 ore">
              Comenzi în ultimele 24 ore <strong>{{ commonStatistics.ordersTodayCount }}</strong>
            </b-button>
            <b-button size="sm" variant="light" class="statItem" v-b-tooltip.hover.bottom title="Comenzi anulate">
              Comenzi anulate <strong>{{ commonStatistics.canceledOrdersTodayCount }}</strong>
            </b-button>
            <b-button size="sm" variant="light" class="statItem" v-b-tooltip.hover.bottom title="Curieri / șoferi online">
              Curieri / șoferi online <strong>{{ commonStatistics.driversOnlineCount }}</strong>
            </b-button>
          </div>
          <b-button class="ml-3 mt-3 mt-md-0 btn-outline-dark btnStats d-flex align-items-center" size="sm" v-b-toggle.zone-stats>
            <b-icon icon="card-list" font-scale="0.9"></b-icon>
            <span class="d-none ml-md-2 d-md-inline">Zone</span>
          </b-button>
          </div>
        </div>
        <router-view></router-view>
      </div>
    </div>
    <b-sidebar v-model="isStatisticsSidebarOpen" right id="zone-stats" title="Statistici zone" bg-variant="light" text-variant="dark" backdrop backdrop-variant="light">
      <div class="px-3 py-2">
        <PolygonsStatistics v-if="isStatisticsSidebarOpen"></PolygonsStatistics>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import OperatorSideBar from '@/components/dashboard/Delivery/SideBar'
import PolygonsStatistics from '@/components/dashboard/PolygonsStatistics'
import config from '@/config'

export default {
  name: 'DeliveryDashboard',
  components: { PolygonsStatistics, OperatorSideBar },
  data () {
    return {
      googleLoaded: true,
      isStatisticsSidebarOpen: false,
      commonStatistics: {
        driversCount: 0,
        driversOnlineCount: 0,
        driversBusyCount: 0,
        clientsCount: 0,
        ordersTodayCount: 0,
        ordersTotalCount: 0,
        announcesCount: 0,
        incidentsCount: 0
      },
      commonStatisticsUpdateInterval: null
    }
  },
  mounted () {
    this.getCommonStatistics()

    this.commonStatisticsUpdateInterval = setInterval(() => {
      this.getCommonStatistics()
    }, 30000)
  },
  beforeDestroy () {
    clearInterval(this.commonStatisticsUpdateInterval)
  },
  methods: {
    getCommonStatistics () {
      this.$axios.get(config.baseApiUrl + '/api/statistics/common')
        .then((response) => {
          this.commonStatistics = response.data
        })
    }
  }
}
</script>

<style scoped>
  .btn.btn-outline-dark {
    outline: none !important;
    box-shadow: none;
  }
  .btn.btn-outline-dark.router-link-exact-active {
    color: #ffffff;
    background-color: #343a40 !important;
  }
</style>
