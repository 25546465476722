<template>
  <div>
    <template v-if="isMyOrder">
      <b-button
        v-if="order.state === 'canceled' || order.state === 'pending'"
        @click="retryOrder"
        style="background: #563d7c; border-color: #563d7c;" size="sm" variant="info" class="ml-2"
        title="Retry"
      >
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
      </b-button>
      <b-button
        v-if="(order.state === 'pending' || order.state === 'created') && order.in_pending_queue === false"
        @click="moveToPendingQueue"
        class="ml-2" size="sm" variant="info"
        title="In coada"
      >
        <b-icon icon="view-list"></b-icon>
      </b-button>
    </template>
    <b-button
      v-if="order.always_in_call"
      @click="removeAlwaysInCall"
      size="sm" :variant="order.state === 'canceled' || order.state === 'pending' ? 'danger' : 'warning'" class="ml-2"
      :title="order.state === 'canceled' || order.state === 'pending' ? 'In anulate' : 'In curs'"
    >
      <b-icon-arrow-right-circle></b-icon-arrow-right-circle>
    </b-button>
    <b-button
      v-if="order.state !== 'new'"
      @click="duplicateOrder"
      size="sm"
      variant="success"
      class="ml-2"
      title="Dublează"
    >
      <b-icon-files></b-icon-files>
    </b-button>
    <b-button
      v-if="order.driver === null"
      size="sm"
      variant="dark"
      class="ml-2"
      :id="'popover-manual-' + order.id"
    >
      <b-icon-hand-index-thumb></b-icon-hand-index-thumb>
    </b-button>
    <b-popover :key="'mp-' + order.id" ref="manualPopover" :target="'popover-manual-' + order.id" triggers="click">
      <template #title>Indicativ manual</template>
      <form @submit.prevent="setDriverAndFinish">
        <UserSelect v-model="manualDriver" :roles="['driver']"></UserSelect>
        <div>
          <b-button type="submit" variant="success" block class="mt-2">Finalizează</b-button>
        </div>
      </form>
    </b-popover>
    <b-button
      v-if="order.driver !== null"
      size="sm"
      variant="dark"
      class="ml-2"
      :id="'popover-locations-' + order.id"
    >
      <b-icon-map></b-icon-map>
    </b-button>
    <b-popover :key="'lp-' + order.id" :customClass="'locations-popover'" v-if="order.driver !== null" ref="locationsPopover" :target="'popover-locations-' + order.id" triggers="click" placement="left">
      <OrderLocalisation :order="order"></OrderLocalisation>
    </b-popover>
  </div>
</template>

<script>
import config from '@/config'
import UserSelect from '@/components/common/UserSelect'
import OrderLocalisation from '@/components/dashboard/Operator/OrderLocalisation'

export default {
  name: 'OrderListItemActionsControls',
  components: { OrderLocalisation, UserSelect },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      manualDriver: null
    }
  },
  computed: {
    isMyOrder () {
      return this.order.dispatcher_id === this.$store.getters['auth/loggedUser'].id
    }
  },
  methods: {
    retryOrder () {
      this.$axios.patch(config.baseApiUrl + '/api/orders/' + this.order.id + '/retry')
        .then(() => {
          this.order.in_pending_queue = false
          this.order.state = 'created'
        })
    },
    removeAlwaysInCall () {
      this.$axios.delete(config.baseApiUrl + '/api/orders/' + this.order.id + '/always-in-call')
        .then(() => {
          this.order.always_in_call = false
        })
    },
    moveToPendingQueue () {
      this.$axios.patch(config.baseApiUrl + '/api/orders/' + this.order.id + '/move-to-pending')
        .then(() => {
          this.order.in_pending_queue = true
        })
    },
    duplicateOrder () {
      this.$axios.post(config.baseApiUrl + '/api/orders/' + this.order.id + '/duplicate')
        .then((response) => {
          response.data.state = 'new'
          response.data.departure_time = this.$day().format('YYYY-MM-DD HH:mm:ss')
          this.$axios.patch(config.baseApiUrl + '/api/orders/' + response.data.id, response.data)
          this.$toasted.success('Сomanda dublicata', {
            duration: 2000
          })
        })
    },
    setDriverAndFinish () {
      this.$axios.patch(config.baseApiUrl + '/api/orders/' + this.order.id + '/set-driver-and-finish', {
        driverId: this.manualDriver.id
      }).then(() => {
        this.order.driver = this.manualDriver
        this.manualDriver = null
        this.$toasted.success('Comanda finalizata', {
          duration: 2000
        })
        this.$refs.manualPopover.$emit('close')
      })
    }
  }
}
</script>

<style scoped>

</style>
