<template>
  <b-card>
      <template #header>
        <b-button size="sm" variant="outline-success" @click="add"> <b-icon icon="plus"></b-icon> Adaugă condiție</b-button>
      </template>
      <draggable tag="ul" v-model="method.definitions.items" class="list-group" handle=".handle">
        <li
          class="list-group-item"
          v-for="(element, idx) in method.definitions.items"
          :key="element.idx"
        >
          <BuilderItem :idx="idx" :key="'step-' + idx" :item="element" @remove="removeAt" />
        </li>
      </draggable>
      <template #footer>
        <b-button size="sm" variant="success" @click="save"> <b-icon icon="file-earmark-check"></b-icon> Salvează</b-button>
      </template>
  </b-card>
</template>

<script>
import config from '@/config'
import draggable from 'vuedraggable'
import BuilderItem from '@/components/settings/builder/BuilderItem'

export default {
  name: 'BuilderItemParent',
  order: 5,
  components: {
    draggable,
    BuilderItem
  },
  props: {
    method: {
      type: Object,
      required: true
    }
  },
  computed: {
    draggingInfo () {
      return this.dragging ? 'under drag' : ''
    }
  },
  methods: {
    add: function () {
      this.method.definitions.items.push({
        search_type: null,
        search_time: null,
        search_limit: null,
        search_order_by: null,
        notification_method: null,
        exclude_previous: null,
        ban_conditions: {
          active: false,
          type: 'ban',
          drivers: {
            from: 1,
            to: 1
          },
          count: 5,
          reset_interval: 60
        }
      })
    },
    removeAt (idx) {
      this.method.definitions.items.splice(idx, 1)
    },
    save () {
      this.$axios({
        method: 'patch',
        url: config.baseApiUrl + '/api/search-methods/' + this.method.id,
        data: this.method
      })
        .then((response) => {
          this.$toasted.success('Salvat cu succes!', {
            duration: 3000
          })
        })
        .catch(function (error) {
          console.log(error)
        })
    }

  }
}
</script>
<style scoped>
</style>
