<template>
  <div class="row">
    <div class="col-9">
      <div v-if="order.state === 'created'">
        <h5>Așteptare șofer ...</h5>
        <b-card>
          <b-skeleton animation="wave" width="85%"></b-skeleton>
          <b-skeleton animation="wave" width="55%"></b-skeleton>
          <b-skeleton animation="wave" width="70%"></b-skeleton>
        </b-card>
      </div>
      <div v-else-if="order.state !== 'canceled' && driver">
        <b-card text-variant="dark" sub-title="Șofer alocat">
          <b-card-text>
            <div class="row">
              <div class="col-1">
                <h4>
                <b-badge v-if="order.manually_assigned" variant="danger" class="ml-3">Manual</b-badge>
                </h4>
              </div>
            </div>
            <div class="row">
            <table class="table table-borderless">
              <tbody>
              <tr class="d-flex align-items-center justify-content-between">
                <td v-if="driver.default_car">
                  <b-button-group>
                    <b-button variant="success" size="lg">
                      {{ driver.default_car.indicativ }} - <span v-if="order.waiting_driver_time !== null">{{ order.waiting_driver_time }} min</span>
                    </b-button>
                    <b-button v-if="order.state === 'assigned'" @click="showLocalisation = !showLocalisation" size="lg" variant="outline-info">
                      <b-icon icon="geo-alt-fill"></b-icon>
                      Localizare
                    </b-button>
                  </b-button-group>
                </td>
                <td v-if="driver.default_car">
                  <div>
                    {{ driver.default_car.number }} /
                    <span v-if="driver.default_car">{{ driver.default_car.make }} {{ driver.default_car.model }}
                    {{ driver.default_car.color }}
                    </span>
                  </div>
                </td>
                <td v-if="driver">{{ driver.name }}</td>
              </tr>
              </tbody>
            </table>
            </div>
            <OrderLocalisation v-if="showLocalisation" :order="order"></OrderLocalisation>
          </b-card-text>
          <b-button-group size="sm" v-if="false">
            <b-button variant="success">
              <b-icon icon="check"></b-icon>
              Aprobă
            </b-button>
            <b-button variant="warning">
              <b-icon icon="arrow-clockwise"></b-icon>
              Caută din nou
            </b-button>
            <b-button variant="danger">
              <b-icon icon="x"></b-icon>
              Anulează
            </b-button>
          </b-button-group>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import OrderLocalisation from '@/components/dashboard/Operator/OrderLocalisation'

export default {
  name: 'OrderDriverSearch',
  components: { OrderLocalisation },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  beforeDestroy () {
    this.showLocalisation = false
  },
  data () {
    return {
      showLocalisation: false
    }
  },
  computed: {
    driver () {
      return this.order.driver
    },
    orderData () {
      return this.order
    }
  },
  watch: {
    'order.id': function () {
      this.showLocalisation = false
    }
  }
}
</script>

<style scoped>

</style>
