<template>
  <div class="home">
    <DeliveryDashboard></DeliveryDashboard>
  </div>
</template>

<script>

import DeliveryDashboard from '@/components/dashboard/DeliveryDashboard'
export default {
  name: 'Dashboard',
  components: { DeliveryDashboard }
}
</script>
