<template>
  <div>
      <draggable tag="ul" v-model="intervals" class="list-group" handle=".handle" @change="applySort">
        <li
          class="list-group-item handle"
          v-for="(item, idx) in intervals"
          :key="item.id"
        >
          <BuilderInterval :idx="idx" :key="item.id" :item="item"  @removeItem="remove(item.id) ? remove(item.id) : removeInterval(idx)" />
        </li>
        <li
          class="list-group-item handle"
        >
          <b-button size="md" variant="outline-success" class="py-4 w-100"  @click="addFirstMethod"> <b-icon icon="plus"></b-icon> Adaugă interval nou</b-button>
        </li>
      </draggable>
  </div>
</template>

<script>
import config from '@/config'
import draggable from 'vuedraggable'
import BuilderInterval from '@/components/settings/builder/BuilderInterval'
let idx = 0
export default {
  name: 'Builder',
  order: 5,
  components: {
    draggable,
    BuilderInterval
  },
  data () {
    return {
      idx: null,
      intervals: null,
      dragging: false,
      intervals_store: {
        search_method_id: null,
        type: 'weekdays',
        value: {
          time_from: '00:00:00',
          time_to: '23:59:59',
          selected_day: null,
          custom_day: null
        }
      },
      methods: {
        definitions: {
          items: [
            {
              search_type: 'by_zone',
              search_time: 6,
              search_limit: 50,
              search_order_by: 'by_distance',
              notification_method: 'all',
              exclude_previous: false,
              ban_conditions: {
                active: false,
                type: 'ban',
                drivers: {
                  from: 1,
                  to: 1
                },
                count: 5,
                reset_interval: 60
              }
            }
          ]
        }
      }
    }
  },
  computed: {
    draggingInfo () {
      return this.dragging ? 'under drag' : ''
    }
  },
  mounted () {
    this.getIntervals()
  },
  methods: {
    applySort () {
      this.$axios.post(config.baseApiUrl + '/api/search-intervals/sort',
        { ids: this.intervals.map((interval) => interval.id) }
      )
        .then((response) => {
          this.$toasted.success('Ordinea de intervale, actualizată!', {
            duration: 3000
          })
        })
    },
    getIntervals () {
      this.$axios.get(config.baseApiUrl + '/api/search-intervals')
        .then((response) => {
          this.intervals = response.data
        })
    },
    removeInterval (idx) {
      this.intervals.splice(idx, 1)
    },
    addInterval: function () {
      idx++
      this.intervals.push({ idx })
    },
    addFirstMethod () {
      this.$axios({
        method: 'post',
        url: config.baseApiUrl + '/api/search-methods',
        data: this.methods
      })
        .then((response) => {
          this.item = response.data
          this.method_id = response.data.id

          this.$axios({
            method: 'post',
            url: config.baseApiUrl + '/api/search-intervals',
            data: Object.assign(this.intervals_store, { search_method_id: response.data.id, company_id: this.$store.getters['auth/loggedUser'].companies[0].id })
          })
            .then(() => {
              this.$toasted.success('Interval creat cu succes!', {
                duration: 3000
              })
              this.getIntervals()
            })
        })
        .catch(function (error) {
          console.log(error)
        })
    },

    remove (idToRemove) {
      if (confirm('Remove?')) {
        this.$axios.delete(config.baseApiUrl + '/api/search-intervals/' + idToRemove)
          .then(() => {
            this.$toasted.success('Șters!', {
              duration: 3000
            })
          })
      }
    }

  }
}
</script>
<style scoped>
</style>
