<template>
  <div>
    <b-container>
      <h1 class="py-3">{{ item.title }}</h1>
      <div v-html="item.content"></div>
    </b-container>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'GetContent',
  data () {
    return {
      item: null
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      this.$axios.get(config.baseApiUrl + '/api/content/1')
        .then((response) => {
          this.item = response.data
        })
    }
  }
}
</script>
