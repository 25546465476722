<template>
  <div class="order-address" >
      <vue-autosuggest
        ref="select"
        v-model="orderPlace.name"
        :disabled="disabled"
        :suggestions="[{ data: uniqueSearchResults }]"
        @selected="fetchPlaceDetails($event.item, orderPlace.name)"
        @input="onSearch"
        :key="'pvs-' + orderPlace.order_id"
        :get-suggestion-value="(s) => s.item.label"
        :input-props="{id:'autosuggest__input', disabled: disabled, style: 'width:100%;height: 38px;border: 1px solid #ccc;padding: 0 15px', placeholder:'Introduce-ți adresa (ex.: 13 Calea București)'}">
        <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
          <div style="width:100%; display: flex; justify-content: space-between;">
            {{ formatName(suggestion.item) }}
            {{suggestion.item.comment ? ' - (' + suggestion.item.comment + ')' : null }}

            <b-button size="sm" variant="outline-danger" class="py-0 px-1" @mouseup.stop="deleteItem(suggestion.item)">
              <b-icon icon="x"></b-icon>
            </b-button>
          </div>
        </div>
      </vue-autosuggest>
      <div class="manual-address">
        <b-btn squared variant="white" size="sm" @click="openManualModal">
        <b-icon :icon="setManual === false ? 'plus-square' : 'plus-square-fill'" variant="success" ></b-icon>
      </b-btn>
      </div>
      <b-modal v-model="manualModal" size="xl" hide-header hide-footer>
        <ManualAddress :address="orderPlace" @address-saved="addressSaved" />
      </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import config from '@/config'
import { createPopper } from '@popperjs/core'
import store from '@/store/index.js'
import { VueAutosuggest } from 'vue-autosuggest'
import axios from 'axios'
import ManualAddress from '../../../../common/ManualAddress.vue'

export default {
  name: 'AddressSearch',
  components: {
    VueAutosuggest,
    ManualAddress
  },
  props: {
    orderPlace: {
      type: Object,
      required: true
    },
    client: {
      type: Object,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loadUserAddresses: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchResults: [],
      selectTitle: 'Adresa ...',
      setManual: false,
      manualModal: false
    }
  },
  created () {
    console.log('---- CREATED')
    this.searchResults = []
    this.$eventBus.addEventListener('userAddressesLoaded', (response) => {
      this.searchResults = response.data.map((address) => {
        return {
          id: address.id,
          label: address.name,
          lat: address.lat,
          long: address.long,
          place_id: address.place_id,
          comment: address.comment,
          scara: address.scara,
          bloc: address.bloc,
          reper: address.reper
        }
      })
      this.$nextTick(() => {
        this.$refs.select.$el.querySelector('input').click()
        this.$refs.select.$el.querySelector('input').focus()
      })
    }, 'userAddressesLoaded')
  },
  // beforeDestroy () {
  //   this.$eventBus.removeEventListener('userAddressesLoaded', 'userAddressesLoaded')
  // },
  mounted () {
    console.log('---- MOUNTED')
    this.searchResults = this.$store.state.userAddresses.addresses.map((address) => {
      return {
        id: address.id,
        label: address.name,
        lat: address.lat,
        long: address.long,
        place_id: address.place_id,
        comment: address.comment,
        scara: address.scara,
        bloc: address.bloc,
        reper: address.reper
      }
    })
    if (this.orderPlace?.order_id && this.loadUserAddresses && this.client?.id) {
      this.getClientAddressesHistory()
    } else {
      if (this.orderPlace.place_id) {
        this.searchResults = [{
          id: this.orderPlace.place_id,
          label: this.orderPlace.name
        }]
      }
    }
  },
  computed: {
    searchInputProps () {
      const props = { class: 'form-control form-control-sm', style: 'width: 400px' }
      if (this.isInputDisabled) {
        props.disabled = 'disabled'
      }
      return props
    },
    uniqueSearchResults () {
      const results = {}
      this.searchResults.forEach((address) => {
        results[address.id] = address
      })
      return Object.entries(results).map((item) => {
        return item[1]
      })
    }
  },
  methods: {
    openManualModal () {
      this.manualModal = true
    },
    formatName (place) {
      const parts = [
        place.label
      ]
      if (place.bloc) {
        parts.push('BLOC: ' + place.bloc)
      }
      if (place.scara) {
        parts.push('SCARA: ' + place.scara)
      }
      if (place.reper) {
        parts.push('REPER: ' + place.reper)
      }

      return parts.join(' / ')
    },
    deleteItem (item) {
      if (confirm('Confirmă ștergerea acestei adrese')) {
        const deleteUrl = typeof item.isInternal !== 'undefined' ? (config.addrApiUrl + '/delete-place/' + item.id) : (config.userAddressesUrl + '/addresses/' + item.id)
        axios.delete(deleteUrl).then(
          () => {
            this.searchResults = this.searchResults.filter((i) => {
              return i.id !== item.id
            })
          }
        )
      }
    },
    onCloseSelect () {
      // this.$nextTick(() => { this.$store.state.dispatcherDashboard.selectOpen = false })
      setTimeout(() => { this.$store.state.dispatcherDashboard.selectOpen = false }, 500)
    },
    optionSelecting (e) {
      console.log(arguments)
    },
    onSearch (search) {
      if (search.length > 2) {
        this.search = search
        this.apiSearch(search, this)
      }
    },
    apiSearch: _.debounce((search, vm) => {
      vm.$axios.get(config.addrApiUrl + '/places-search?input=' + search + '&language=ro&components=country:ro&location=' + store.getters['auth/companyLocation'].lat + ',' + store.getters['auth/companyLocation'].lng + '&radius=50000&strictbounds')
        .then((response) => {
          vm.searchResults = response.data.predictions.map((item) => {
            return {
              id: item.place_id,
              label: item.description,
              isInternal: typeof item.is_internal !== 'undefined',
              lat: item.lat,
              lng: item.lng,
              comment: item.comment,
              bloc: item.bloc ?? null,
              scara: item.scara ?? null,
              reper: item.reper ?? null
            }
          })
        })
    }, 500),
    fetchPlaceDetails (item, placeName) {
      this.$refs.select.$el.querySelector('input').blur()

      if (item.isInternal) {
        if (this.inUpdate) {
          this.$store.dispatch('dispatcherDashboard/updateOrderPlace', {
            orderPlace: {
              id: this.orderPlace.id,
              order_id: this.orderPlace.order_id,
              name: item.label,
              lat: item.lat,
              long: item.lng,
              place_id: item.id,
              comment: item.comment,
              bloc: item.bloc ?? null,
              scara: item.scara ?? null,
              reper: item.reper ?? null
            }
          })
            .then(() => {
              this.orderPlace.name = item.label
              this.orderPlace.lat = item.lat
              this.orderPlace.long = item.lng
              this.orderPlace.place_id = item.id
              this.orderPlace.comment = item.comment
              this.orderPlace.bloc = item.bloc
              this.orderPlace.scara = item.scara
              this.orderPlace.reper = item.reper
              if (this.orderPlace.order_id) {
                this.$axios.patch(config.baseApiUrl + '/api/orders/' + this.orderPlace.order_id + '/places/' + this.orderPlace.id, this.orderPlace)
              }
              this.$toasted.success('Adresa actualizata')
            })
            .catch(() => {
              this.$toasted.error('Adresa invalida')
            })
            .then(() => {
              this.inUpdate = false
            })
        } else {
          this.orderPlace.name = item.label
          this.orderPlace.lat = item.lat
          this.orderPlace.long = item.lng
          this.orderPlace.place_id = item.id
          this.orderPlace.comment = item.comment
          this.orderPlace.bloc = item.bloc ?? null
          this.orderPlace.scara = item.scara ?? null
          this.orderPlace.reper = item.reper ?? null
          if (this.orderPlace.order_id) {
            this.$axios.patch(config.baseApiUrl + '/api/orders/' + this.orderPlace.order_id + '/places/' + this.orderPlace.id, this.orderPlace)
          }
        }
      } else {
        if (typeof item.lat !== 'undefined') {
          this.$store.dispatch('dispatcherDashboard/updateOrderPlace', {
            orderPlace: {
              id: this.orderPlace.id,
              order_id: this.orderPlace.order_id,
              name: item.label,
              lat: item.lat,
              long: item.long,
              place_id: item.place_id,
              comment: item.comment,
              bloc: item.bloc ?? '',
              scara: item.scara ?? '',
              reper: item.reper ?? ''
            }
          })
          this.orderPlace.name = item.label
          this.orderPlace.comment = item.comment
          this.orderPlace.lat = item.lat
          this.orderPlace.long = item.long
          this.orderPlace.place_id = item.place_id
        } else {
          this.$axios.get(config.addrApiUrl + '/place-details?place_id=' + item.id + '&fields=geometry,formatted_address,address_component,name&language=ro')
            .then((response) => {
              this.placeChanged(response.data.result, item.id, item.label, item.comment)
            })
        }
      }
    },
    placeChanged (place, placeId, placeName, comment) {
      if (this.inUpdate) {
        this.$store.dispatch('dispatcherDashboard/updateOrderPlace', {
          orderPlace: {
            id: this.orderPlace.id,
            order_id: this.orderPlace.order_id,
            name: this._formatGooglePlaceName(place, placeName),
            lat: place.geometry.location.lat,
            long: place.geometry.location.lng,
            place_id: placeId,
            comment: comment
          }
        })
          .then(() => {
            this.changePlaceInOrder(place, placeId, placeName, comment)
            this.$toasted.success('Adresa actualizata')
          })
          .catch(() => {
            this.$toasted.error('Adresa invalida')
          })
          .then(() => {
            this.inUpdate = false
          })
      } else {
        this.changePlaceInOrder(place, placeId, placeName, comment)
      }
    },
    changePlaceInOrder (place, placeId, placeName, comment) {
      this.orderPlace.name = this._formatGooglePlaceName(place, placeName)
      this.orderPlace.lat = place.geometry.location.lat
      this.orderPlace.long = place.geometry.location.lng
      this.orderPlace.place_id = placeId
      this.orderPlace.comment = comment
      if (this.orderPlace.order_id) {
        this.$axios.patch(config.baseApiUrl + '/api/orders/' + this.orderPlace.order_id + '/places/' + this.orderPlace.id, this.orderPlace)
      }
    },
    _formatGooglePlaceName (place, placeName) {
      let isPremise = false
      let premise = ''
      let streetNumber = ''
      let isStreetNumber = false
      let street = ''
      let city = ''
      place.address_components.forEach((item) => {
        if (item.types.includes('premise')) {
          isPremise = true
          premise = item.long_name
        } else if (item.types.includes('street_number')) {
          streetNumber = item.long_name
          isStreetNumber = true
        } else if (item.types.includes('route')) {
          street = item.long_name
        } else if (item.types.includes('locality')) {
          city = item.long_name
        }
      })

      if (!isStreetNumber) {
        return placeName
      }

      const parts = []
      if (isPremise) {
        parts.push(premise)
      } else {
        parts.push(street + ' ' + streetNumber)
      }
      if (city) {
        parts.push(city)
      }
      return ((street + ' ' + streetNumber) === place.name ? '' : '(' + place.name + ') ') + parts.join(', ')
    },
    getClientAddressesHistory () {
      this.$axios.get(config.userAddressesUrl + '/addresses/' + this.client.phone)
        .then((response) => {
          this.searchResults = response.data.map((address) => {
            return {
              id: address.id,
              label: address.name,
              lat: address.lat,
              long: address.long,
              place_id: address.place_id,
              comment: address.comment,
              scara: address.scara,
              bloc: address.bloc,
              reper: address.reper
            }
          })
          this.$refs.select.$el.querySelector('input').click()
          this.$refs.select.$el.querySelector('input').focus()
        })
    },
    withPopper (dropdownList, component, { width }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle(
                'drop-bottom',
                state.placement === 'bottom'
              )
            }
          }
        ]
      })
      return () => popper.destroy()
    },
    addressSaved (address) {
      this.manualModal = false
      this.orderPlace.bloc = address.bloc
      this.orderPlace.comment = address.comment
      this.orderPlace.lat = address.lat
      this.orderPlace.long = address.lng
      this.orderPlace.name = address.name
      this.orderPlace.place_id = address.place_id
      this.orderPlace.reper = address.reper
      this.orderPlace.scara = address.scara
    }
  },
  watch: {
    searchResults: function (newVal, oldVal) {
      if (this.searchResults.length === 0) {
        this.setManual = true
      } else {
        this.setManual = false
      }
    },
    'orderPlace.order_id' () {
      this.$nextTick(() => {
        console.log('CLICKED!!!!!!!!!!!')
        this.$refs.select.$el.querySelector('input').click()
        this.$refs.select.$el.querySelector('input').focus()
      })
      if (parseInt(this.$route.params.id) === 0) {
        // this.searchResults = []
      }
      if (this.loadUserAddresses && this.client?.id) {
        this.getClientAddressesHistory()
      } else {
        if (this.orderPlace.place_id) {
          this.searchResults = [{
            id: this.orderPlace.place_id,
            label: this.orderPlace.name
          }]
        }
      }
    },
    'client.id': {
      handler (value, oldValue) {
        if (oldValue != null) {
          this.$nextTick(() => {
            this.$refs.select.$el.querySelector('input').click()
            this.$refs.select.$el.querySelector('input').focus()
          })
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
  .vs__selected-options {
    width: 450px;
  }
  #autosuggest__input {
      outline: none;
      position: relative;
      display: block;
      border: 1px solid #616161;
      width: 100%;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
    }
    #autosuggest__input.autosuggest__input-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .autosuggest__results-container {
      position: relative;
      width: 100%;
    }
    .autosuggest__results {
      font-weight: 300;
      margin: 0;
      position: absolute;
      z-index: 10000001;
      width: 100%;
      border: 1px solid #e0e0e0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      background: white;
      padding: 0px;
      overflow: scroll;
      max-height: 200px;
    }
    .autosuggest__results ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
    }
    .autosuggest__results .autosuggest__results_item {
      cursor: pointer;
      padding: 15px;
    }
    #autosuggest ul:nth-child(1) > .autosuggest__results_title {
      border-top: none;
    }
    .autosuggest__results .autosuggest__results_title {
      color: gray;
      font-size: 11px;
      margin-left: 0;
      padding: 15px 13px 5px;
      border-top: 1px solid lightgray;
    }
    .autosuggest__results .autosuggest__results_item:active,
    .autosuggest__results .autosuggest__results_item:hover,
    .autosuggest__results .autosuggest__results_item:focus,
    .autosuggest__results_item-highlighted {
      background-color: red;
    }

</style>
