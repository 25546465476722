<template>
  <div>
  <b-button size="sm" class="p-0" variant="link" v-b-modal.history-modal>
      Istoric procesare
    </b-button>
    <b-modal id="history-modal" title="Istoric procesare" size="lg" centered ok-only>
      <ul class="list-group">
        <li v-for="item in items" :key="'oh-' + item.id" class="list-group-item">
          <div class="row">
            <div class="col">
              {{ formattedDate(item.timestamp) }}
            </div>
            <div class="col">
              {{ formattedType(item) }}
            </div>
            <div class="col">
              <template v-if="item.initiator">
                <template v-if="item.initiator.default_car">
                  <b-badge>{{ item.initiator.default_car.indicativ }}</b-badge> -
                </template>
                {{ item.initiator.name }}
              </template>
            </div>
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          <b-button size="sm" variant="dark" @click="getItems">
            REFRESH
          </b-button>
        </li>
      </ul>
    </b-modal>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'OrderTimeline',
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      items: null
    }
  },
  beforeMount () {
    this.getItems()
  },
  methods: {
    getItems () {
      this.$axios.get(config.baseApiUrl + '/api/order-history/' + this.orderId)
        .then((response) => {
          this.items = response.data
        })
    },
    formattedType (item) {
      switch (item.type) {
        case 'driver_assigned_order':
          return 'Comanda preluata'
        case 'driver_canceled_order':
          return 'Comanda anulata'
      }
      return '-'
    },
    formattedDate (date) {
      return this.$day(date + 'Z', 'Europe/Bucharest').format('HH:mm - DD MMM')
    }
  },
  watch: {
    orderId: function () {
      this.getItems()
    }
  }
}
</script>

<style scoped>

</style>
