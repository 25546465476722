<template>
<div>
  <div v-if="orders.length > 0">
    <b-editable-table small hover  class="editable-table table-orders" v-model="orders" :fields="fields" @input-change="handleInput">
      <template #cell(control)="data">
        <b-button v-if="type === 'inCall'" squared variant="dark">
          {{ getRowLabel(data.item) }}
        </b-button>
        <div class="d-flex align-items-center">
          <CallDuration class="ml-3" :order="data.item" />
          <a href="#" @click.prevent="openEdit(data.item.id)">#{{ data.item.id }}</a>
        </div>
      </template>
      <template #cell(created_at)="data">
        <DateTime :date-time="data.value" format="HH:mm:ss"></DateTime>
      </template>
      <template #cell(client_phone)="data">
        {{ data.item.client.phone }}
      </template>
      <template #cell(driver)="data">
        <div class="table-col bg-success font-weight-bold text-white" v-if="data.item.driver">
          <template>
            {{ data.item.driver.default_car ? data.item.driver.default_car.indicativ : 'xxx' }} - {{ data.item.waiting_driver_time }}m
          </template>

        </div>
        <div v-else class="table-col bl bg-light font-weight-bold">
          <template v-if="data.item.state === 'created'">
            <b-spinner
              variant="warning"
            ></b-spinner>
          </template>

          <OrderCancelationReason :order="data.item" />
          <b-button
            v-if="data.item.state === 'canceled'"
            @click="_retryOrder(data.item)"
            style="background: #563d7c; border-color: #563d7c;" size="sm" variant="info" class="ml-2"
          >
            <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
          </b-button>
        </div>
      </template>
      <template #cell(order_place)="data">
        <!-- <AddressSearch  :order-place="data.item.places[0]" :client-id="data.item.client.id"></AddressSearch> -->
        <b-input disabled @keyup.enter.ctrl="_placeOrder(data.item)"  v-model="data.item.places[0].name" size="sm"></b-input>
        <b-input  disabled v-model="data.item.places[0].comment" size="sm"></b-input>
      </template>
      <template #cell(client_name)="data">
        <b-input  @keyup.enter.ctrl="_placeOrder(data.item)" v-model="data.item.client.name" size="sm"></b-input>
      </template>
      <template #cell(actions)="data">
        <OrderListItemActionsControls :order="data.item"></OrderListItemActionsControls>
      </template>
      <template #cell(sip)="data">
        <OrderListItemSipControls :order="data.item"></OrderListItemSipControls>
      </template>
    </b-editable-table>
    <button v-if="type === 'inCall'" v-shortkey="{f1: ['f1'], f2: ['f2'], f3: ['f3'], f4: ['f4'], f5: ['f5'], f6: ['f6'], f7: ['f7'], f8: ['f8'], f9: ['f9']}" @shortkey="sipAction" class="d-none"></button>
    <button v-if="type === 'inCall'" v-shortkey="['esc']" @shortkey="sipEndCall" class="d-none"></button>
  </div>
  <div v-else>
    <Game />
  </div>
</div>
</template>

<script>
import BEditableTable from 'bootstrap-vue-editable-table'
import DateTime from '@/components/common/DateTime'
// import AddressSearch from '@/components/dashboard/Operator/Orders/v2/AddressSearch'
import OrderListItemSipControls from '@/components/dashboard/Operator/Orders/v2/OrderListItemSipControls'
import OrderListItemActionsControls from '@/components/dashboard/Operator/Orders/v2/OrderListItemActionsControls'
import ordersService from '@/services/orders'
import Game from '@/components/game/Game'
import OrderCancelationReason from '../../../../tools/OrderCancelationReason.vue'
import CallDuration from '../../../../tools/CallDuration.vue'
export default {
  name: 'OrdersListTable',
  components: {
    OrderListItemActionsControls,
    OrderListItemSipControls,
    // AddressSearch,
    BEditableTable,
    DateTime,
    Game,
    OrderCancelationReason,
    CallDuration
  },
  props: {
    type: {
      type: String,
      default: 'inCall'
    }
  },
  data () {
    return {
      fields: [
        { key: 'control', label: 'Control', type: 'text', editable: false },
        { key: 'created_at', label: 'Time', type: 'custom', editable: false },
        { key: 'client_phone', label: 'Phone', type: 'custom', editable: false },
        { key: 'driver', label: 'Driver', type: 'custom', editable: false },
        { key: 'order_place', label: 'Address', type: 'text', editable: false, validate: this.validateOrderPlace },
        { key: 'client_name', label: 'Client', type: 'text', editable: false, validate: function () { return { valid: true } } },
        { key: 'actions', label: 'Actions', type: 'custom', editable: false },
        { key: 'sip', label: 'SIP', type: 'custom', editable: false }
      ],
      updateInterval: null,
      canceledDriver: null
    }
  },
  computed: {
    services () {
      return this.$store.getters['dispatcherDashboard/getServices']
    },
    orders () {
      return this.$store.state.dispatcherDashboard.newOrdersList
    }
  },
  mounted () {
    window.rowKey = 0
    const timeout = this.type === 'inCall' ? 3000 : 10000
    this.$store.state.dispatcherDashboard.newOrdersList = []
    this.$store.dispatch('dispatcherDashboard/getNewOrders', { type: this.type })
    this.updateInterval = setInterval(() => {
      this.$store.dispatch('dispatcherDashboard/getNewOrders', { type: this.type })
    }, timeout)
  },
  destroyed () {
    clearInterval(this.updateInterval)
  },
  methods: {
    handleInput (data) {},
    cons (data) {
      console.log(data)
    },
    validateOrderPlace (value) {
      if (value === '') {
        return {
          valid: false,
          errorMessage: 'Please enter address'
        }
      }
      return { valid: true }
    },
    _placeOrder (order) {
      ordersService.placeOrder(order, this)
    },
    _retryOrder (order) {
      ordersService.retryOrder(order, this)
    },
    sipAction (event) {
      const index = parseInt(event.srcKey.replace('f', '')) - 1
      const holdUnholdControls = document.querySelectorAll('.sip-hold-unhold-control')
      if (typeof holdUnholdControls[index] !== 'undefined') {
        holdUnholdControls[index].click()
      }
    },
    sipEndCall (event) {
      const endControl = document.querySelector('.sip-end-control.active-call')
      console.log(endControl)
      if (endControl !== null) {
        endControl.click()
      }
    },
    getRowLabel (order) {
      if (typeof this.$store.state.sip.calls[order.client.phone] !== 'undefined') {
        window.rowKey++
        return 'F' + window.rowKey
      }
      return '- -'
    },
    openEdit (id) {
      this.$store.state.dispatcherDashboard.quickEditOrderId = id
    }
  }
}
</script>

<style>
table.editable-table,
table.b-table {
  margin: auto;
  width: 100% !important;
}

table.editable-table td {
  vertical-align: middle;
}

.editable-table .data-cell {
  padding: 8px;
  vertical-align: middle;
}

.editable-table .custom-checkbox {
  width: 50px;
}

.name-col {
  width: 120px;
}

.department-col {
  width: 150px;
}

.age-col {
  width: 100px;
}

.date-col {
  width: 200px;
}

.is-active-col {
  width: 100px
}
</style>
