<template>
  <time v-text="time"></time>
</template>

<script>
export default {
  name: 'DateTime',
  props: {
    dateTime: {
      type: String,
      required: true
    },
    format: {
      type: String,
      default () {
        return 'DD.MM.YYYY HH:mm:ss'
      }
    }
  },
  computed: {
    time () {
      return this.$day.utc(this.dateTime).local().format(this.format)
    }
  }
}
</script>

<style scoped>

</style>
