<template>
  <footer class="footer">
    <div class="container-fluid ">
      <b-row no-gutters class="justify-content-left">
          &copy; {{ new Date().getFullYear() }} - NIXAP DEVELOPMENT
          <ul class="footer-menu">
            <li><a href="#">Blog</a></li>
            <li><a href="#">Despre</a></li>
            <li><a href="#">Acorduri legale</a></li>
            <li><a href="#">Suport</a></li>
            <li><a href="#">Starea sistemului</a></li>
            <li><a href="#">Contact</a></li>
          </ul>
      </b-row>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'Footer'
}
</script>

<style scoped>

</style>
