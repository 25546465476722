<template>

  <div class="Game " >
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.1.0/css/all.css" integrity="sha384-lKuwvrZot6UHsBSfcMvOkWwlCMgc0TaWr+30HWe3a4ltaBwTZhyTEggF5tJv8tbt" crossorigin="anonymous">
    <div :class="{ oversss: !isGameStarted || isGameEnded }">

    <div class="buttons-container">

      <div class="left">
        <h4>Eu:</h4>

        <button :class="{ disabled: !isGameStarted || isGameEnded }" @click="didPicked (1)">
          <i class="far fa-hand-paper"></i>
        </button>

        <button :class="{ disabled: !isGameStarted || isGameEnded }" @click="didPicked (2)">
          <i class="far fa-hand-peace"></i>
        </button>

        <button :class="{ disabled: !isGameStarted || isGameEnded }" @click="didPicked (3)">
          <i class="far fa-hand-rock"></i>
        </button>
      </div>

      <div class="score">
        {{ userWins }} : {{ computerWins }}
        <div>{{ result }}</div>
      </div>

      <div class="right">
        <h4>Calculator:</h4>

        <button v-html="computerHtml"></button>
      </div>

    </div>
    <!-- /.buttons-container -->
    </div>

    <div class="game-controls">

      <template v-if="!isGameStarted">
        <b-button class="btn-float" size="lg" variant="outline-dark " @click="isGameStarted = true"> <b-icon icon="play"></b-icon> </b-button>
      </template>
      <template v-else>

        <b-button class="mb-3 px-5" variant="outline-primary" v-show="isGameStarted && isGameEnded" @click="restart">Restart</b-button>

        <h4>Sumar: </h4>
        <ul>
          <li v-for="record in records" :key="record" :class="record.type">{{ record.message }}</li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isGameStarted: false,
      isGameEnded: false,
      computerHtml: '',
      userPicked: 0,
      computerPicked: 0,
      whoWin: '',
      userWins: 0,
      computerWins: 0,
      result: '',
      rounds: 0,
      records: [],
      resultCssClass: ''
    }
  },
  methods: {
    restart: function () {
      this.isGameStarted = false
      this.isGameEnded = false
      this.computerHtml = '<i class="far fa-hand-paper"></i>'
      this.userPicked = 0
      this.computerPicked = 0
      this.whoWin = ''
      this.userWins = 0
      this.computerWins = 0
      this.result = ''
      this.rounds = 0
      this.records = []
      this.resultCssClass = ''
    },
    didPicked: function (picked) {
      console.log(picked)

      this.userPicked = picked

      const randomFrom1To3 = Math.floor(Math.random() * 3) + 1
      this.computerPicked = randomFrom1To3

      const symbolHtml = {
        1: 'paper',
        2: 'peace',
        3: 'rock'
      }

      this.computerHtml = `<i class="far fa-hand-${symbolHtml[randomFrom1To3]}"></i>`

      // Logic
      if (this.userPicked === this.computerPicked) {
        this.whoWin = 'draw'
      } else {
        switch (this.userPicked) {
          case 1:

            if (this.computerPicked === 2) {
              this.whoWin = 'computer'
            } else {
              this.whoWin = 'user'
            }

            break

          case 2:

            if (this.computerPicked === 3) {
              this.whoWin = 'computer'
            } else {
              this.whoWin = 'user'
            }

            break

          case 3:

            if (this.computerPicked === 1) {
              this.whoWin = 'computer'
            } else {
              this.whoWin = 'user'
            }

            break

          default:
        }
      } // If
      console.log('Who Wins: ' + this.whoWin)
      this.rounds++
    }
  },
  watch: {
    rounds: function () {
      if (this.whoWin === 'user') {
        this.records.unshift({
          message: 'Ai câștigat！',
          type: 'win'
        })

        this.userWins++
      } else if (this.whoWin === 'computer') {
        this.records.unshift({
          message: 'Ai pierdut！',
          type: 'lose'
        })

        this.computerWins++
      } else if (this.whoWin === 'draw') {
        this.records.unshift({
          message: 'Egal！',
          type: 'draw'
        })
      }

      if (this.userWins >= 2) {
        this.result = 'Ai câștigat！'
        this.isGameEnded = true
      } else if (this.computerWins >= 2) {
        this.result = 'Ai pierdut！'
        this.isGameEnded = true
      }
    }
  }
}
</script>

<style>
</style>
