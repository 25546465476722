<template>
  <div style="width: 300px; margin: 30px auto;">
    <b-form @submit.prevent="onSubmit" >
      <b-form-group
        id="input-group-1"
        label="Login:"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          v-model="form.email"
          type="email"
          placeholder="Enter email"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-2"
        label="Password:"
        label-for="input-2"
      >
        <b-form-input
          id="input-2"
          v-model="form.password"
          placeholder="Enter name"
          type="password"
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">Submit</b-button>
    </b-form>
  </div>
</template>

<script>
import auth from '@/services/auth'
import router from '@/router'

export default {
  name: 'Login',
  data () {
    return {
      form: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    onSubmit () {
      auth.login(this.form.email, this.form.password)
        .then(() => {
          router.push('/dashboard')
          this.$toasted.success('You successfully logged in')
        })
    }
  }
}
</script>

<style scoped>

</style>
