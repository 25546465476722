import config from '@/config'

function placeOrder (order, vm) {
  const action = order.id !== null ? 'dispatcherDashboard/updateOrder' : 'dispatcherDashboard/createOrder'
  order.departure_time = vm.$day().format('YYYY-MM-DD HH:mm:ss')
  order.service_id = 1
  vm.$store.state.dispatcherDashboard.orderInView = order
  return vm.$store.dispatch(action)
    .then((response) => {
      if (response.data.call && vm.$store.state.sip.calls[response.data.call.phone] && vm.$store.state.sip.calls[response.data.call.phone].callSession.isOnHold().local === false) {
        vm.$store.state.sip.calls[response.data.call.phone].callSession.hold(vm.$store.getters['auth/useSipUpdate'])
      }
      for (const prop in response.data) {
        order[prop] = response.data[prop]
      }
      vm.$toasted.success('Order created', {
        duration: 3000
      })
      vm.$store.state.dispatcherDashboard.quickEditOrderId = null
      vm.$eventBus.triggerEvent('quickOrderPlaced', {})
    })
    .catch((error) => {
      Object.entries(error.response.data.errors).forEach(([key, group]) => {
        group.forEach((message) => {
          vm.$toasted.error(message, {
            duration: 5000
          })
        })
      })
    })
}

function retryOrder (order, vm) {
  return vm.$axios.patch(config.baseApiUrl + '/api/orders/' + order.id + '/retry')
    .then(() => {
      order.in_pending_queue = false
      order.state = 'created'
    })
}

export default {
  placeOrder,
  retryOrder
}
