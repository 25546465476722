<template>
  <div>
    <b-input-group class="mb-3 d-flex justify-content-start" style="white-space: nowrap;">
      <template #prepend>
        <b-input-group-text>
          <strong class="text-dark">
            {{ fieldLetterPrefix }}
          </strong>
        </b-input-group-text>
      <b-input-group-append>
        <b-button squared @click="resetPlace" variant="outline-secondary" class="shadow-none px-2" style="margin: 0 0 0 0px; border-color: rgba(0,0,0,0.2);">
          <b-icon variant="danger" icon="x"></b-icon>
        </b-button>
      </b-input-group-append>
      </template>
      <AddressSearch
        ref="addresses"
        :order-place="orderPlace"
        :client="client"
        :load-user-addresses="parseInt($route.params.id) !== 0"
        class="order-form-addresses big"
        :disabled="isInputDisabled"
      ></AddressSearch>
        <b-input-group-append class="d-none d-md-block">
          <b-form-input v-model="orderPlace.bloc" :disabled="isInputDisabled" class="mini-input"  placeholder="BL"></b-form-input>
        </b-input-group-append>
        <b-input-group-append class="d-none d-md-block">
          <b-form-input v-model="orderPlace.scara" :disabled="isInputDisabled" class="mini-input"  placeholder="SC"></b-form-input>
        </b-input-group-append>
        <b-input-group-append class="d-none d-md-block">
          <b-form-input v-model="orderPlace.reper" :disabled="isInputDisabled" class="med-input"  placeholder="REPER"></b-form-input>
        </b-input-group-append>
      <b-input-group-append v-if="index !== 0">
        <b-button @click="removePlace" v-b-toggle.preluare-detalii variant="danger" class="shadow-none">
          <b-icon icon="trash"></b-icon>
        </b-button>
      </b-input-group-append>
      <b-input-group-append class="d-none d-md-block">
        <b-button squared v-if="!inUpdate" @click="inUpdate = true" variant="outline-secondary" title="Modifica" class="shadow-none">
          <b-icon icon="pencil" />
        </b-button>
        <b-button squared v-if="inUpdate" @click="inUpdate = false" variant="secondary" title="Salveaza" class="shadow-none">
          <b-icon icon="check2" />
        </b-button>
      </b-input-group-append>
      <b-input-group-append class="d-none d-md-block">
        <b-button :disabled="disabled" @click="addNewPlace" v-if="isLast" v-b-toggle.preluare-detalii variant="secondary" class="shadow-none">
          <b-icon icon="plus"></b-icon>
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import _ from 'lodash'
import AddressSearch from '@/components/dashboard/Operator/Orders/v2/AddressSearch'
import config from '@/config'

export default {
  name: 'OrderPlaceField',
  components: {
    AddressSearch
  },
  props: {
    orderPlace: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isLast: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    client: {
      type: Object,
      default: null
    }
  },
  mounted () {
    if (this.index === 0) {
      this.$nextTick(() => {
        this.$refs.addresses.$refs.select.$el.querySelector('input').click()
        this.$refs.addresses.$refs.select.$el.querySelector('input').focus()
      })
    }
  },
  data () {
    return {
      inUpdate: false,
      searchResults: []
    }
  },
  computed: {
    fieldLetterPrefix () {
      return 'ABCDEFGHIGKLMNOPQRSTUVWXYZ'[this.index]
    },
    placeholder () {
      return this.index === 0 ? 'Adresa preluare' : 'Destinatie'
    },
    isSdkLoaded () {
      return this.$store.state.isGoogleApiLoaded
    },
    isInputDisabled () {
      return this.disabled && !this.inUpdate
    },
    searchInputProps () {
      const props = { class: 'form-control', style: 'width: 710px' }
      if (this.isInputDisabled) {
        props.disabled = 'disabled'
      }
      return props
    },
    clientId () {
      if (this.client && typeof this.client.id !== 'undefined') {
        return this.client.id
      }
      return undefined
    }
  },
  methods: {
    getOptionLabel (option) {
      if (typeof option === 'object') {
        return option.label
      }
      return '-'
    },
    onSearch (search) {
      if (search.length) {
        this.search = search
        this.apiSearch(search, this)
      }
    },
    apiSearch: _.debounce((search, vm) => {
      vm.$axios.get(config.addrApiUrl + '/places-search?input=' + search + '&language=ro&components=country:ro&locationbias=circle:25000@' + this.$store.getters['auth/companyLocation'].lat + ',' + this.$store.getters['auth/companyLocation'].lng)
        .then((response) => {
          vm.searchResults = response.data.predictions.map((item) => {
            return {
              id: item.place_id,
              label: item.description
            }
          })
        })
    }, 500),
    fetchPlaceDetails (item, placeName) {
      this.$axios.get(config.addrApiUrl + '/place-details?place_id=' + item.id + '&fields=geometry,formatted_address,address_component,name&language=ro')
        .then((response) => {
          this.placeChanged(response.data.result, item.id, item.label)
        })
    },
    placeChanged (place, placeId, placeName) {
      if (this.inUpdate) {
        this.$store.dispatch('dispatcherDashboard/updateOrderPlace', {
          orderPlace: {
            id: this.orderPlace.id,
            order_id: this.orderPlace.order_id,
            name: this._formatGooglePlaceName(place, placeName),
            lat: place.geometry.location.lat,
            long: place.geometry.location.lng,
            place_id: placeId
          }
        })
          .then(() => {
            this.changePlaceInOrder(place, placeId, placeName)
            this.$toasted.success('Adresa actualizata')
          })
          .catch(() => {
            this.$toasted.error('Adresa invalida')
          })
          .then(() => {
            this.inUpdate = false
          })
      } else {
        this.changePlaceInOrder(place, placeId, placeName)
      }
    },
    changePlaceInOrder (place, placeId, placeName) {
      this.orderPlace.name = this._formatGooglePlaceName(place, placeName)
      this.orderPlace.lat = place.geometry.location.lat
      this.orderPlace.long = place.geometry.location.lng
      this.orderPlace.place_id = placeId
    },
    _formatGooglePlaceName (place, placeName) {
      let isPremise = false
      let premise = ''
      let streetNumber = ''
      let isStreetNumber = false
      let street = ''
      let city = ''
      place.address_components.forEach((item) => {
        if (item.types.includes('premise')) {
          isPremise = true
          premise = item.long_name
        } else if (item.types.includes('street_number')) {
          streetNumber = item.long_name
          isStreetNumber = true
        } else if (item.types.includes('route')) {
          street = item.long_name
        } else if (item.types.includes('locality')) {
          city = item.long_name
        }
      })

      if (!isStreetNumber) {
        return placeName
      }

      const parts = []
      if (isPremise) {
        parts.push(premise)
      } else {
        parts.push(street + ' ' + streetNumber)
      }
      if (city) {
        parts.push(city)
      }
      return ((street + ' ' + streetNumber) === place.name ? '' : '(' + place.name + ') ') + parts.join(', ')
    },
    addNewPlace () {
      this.$store.commit('dispatcherDashboard/addNewPlaceToOrder')
    },
    removePlace () {
      this.$store.commit('dispatcherDashboard/removePlaceFromOrder', {
        index: this.index
      })
    },
    resetPlace () {
      this.orderPlace.name = ''
      this.orderPlace.lat = null
      this.orderPlace.long = null
      this.orderPlace.place_id = null
    }
  },
  watch: {
    inUpdate: function (value) {
      if (value === true) {
        try {
          const nameParts = this.orderPlace.name.split(') - ')
          if (nameParts.length > 1) {
            nameParts.shift()
          }
          this.orderPlace.name = nameParts.join(') - ')
        } catch (e) {}
      }
    }

  }
}
</script>

<style>
  #autosuggest-autosuggest__results {
    position: relative;
  }
  #autosuggest-autosuggest__results ul {
    position: absolute;
    z-index: 9999;
    background-color: #fff;
    width: 100%;
    list-style: none;
    padding: 0;
  }
  #autosuggest-autosuggest__results ul li.autosuggest__results-item {
    border: 1px solid #ddd;
    margin-top: -1px;
    padding: 1px 5px;
  }
  #autosuggest-autosuggest__results ul li.autosuggest__results-item:hover {
    background-color: #eee;
    cursor: pointer;
  }

</style>
