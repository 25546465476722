<template>
  <div class="mt-2">
    <template v-if="orders">
      <OrdersList key="LiveOrders" :orders="orders" @order-click="viewOrder"></OrdersList>
      <div v-if="showMore" class="text-center mt-2">
        <b-button :disabled="showMoreInProgress" @click="loadMore" size="sm" variant="info" style="width: 130px;">
          <template v-if="showMoreInProgress">
            <b-icon icon="three-dots" animation="cylon"></b-icon>
          </template>
          <template v-else>SHOW MORE</template>
        </b-button>
      </div>
    </template>
    <div v-else class="text-center">Loading...</div>
  </div>

</template>

<script>
import OrdersList from '@/components/dashboard/Operator/Orders/OrdersList'
import config from '@/config'

export default {
  name: 'OrdersByState',
  components: {
    OrdersList
  },
  props: {
    ordersView: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      orders: [],
      offset: 0,
      showMore: true,
      showMoreInProgress: false
    }
  },
  mounted () {
    this.fetchOrders()
  },
  methods: {
    fetchOrders () {
      return this.$axios.get(config.baseApiUrl + '/api/dispatcher-orders/' + this.type, { params: { offset: this.offset } }).then((response) => {
        if (this.offset > 0) {
          this.orders = [...this.orders, ...response.data]
        } else {
          this.orders = response.data
        }

        if (response.data.length < 60) {
          this.showMore = false
        }
      })
    },
    viewOrder (order) {
      this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: order.id })
    },
    loadMore () {
      this.showMoreInProgress = true
      this.offset += 60
      this.fetchOrders().finally(() => {
        this.showMoreInProgress = false
      })
    }
  },
  watch: {
    ordersView: function (val) {
      if (val === this.type) {
        this.offset = 0
        this.$emit('mounted')
        this.fetchOrders()
      }
    }
  }
}
</script>

<style scoped>

</style>
