<template>
  <div class="fixed-order-inputs">
    <div>
      <AddressSearch class="address" ref="addresses" :order-place="localOrder.places[0]" :client-id="localOrder.client.id" :load-user-addresses="true"></AddressSearch>
    </div>
    <div>
      <b-input placeholder="Observații ..." v-model="localOrder.places[0].comment" size="lg"></b-input>
    </div>
    <div>
      <b-input class="name" placeholder="Telefon" v-model="clientPhone" size="lg"></b-input>
    </div>
    <div>
      <b-input placeholder="Nume" v-model="clientName" size="lg"></b-input>
    </div>
    <div>
      <b-input-group class="d-flex" style="flex-wrap: nowrap;" size="sm">
        <b-input-group-prepend  is-text>
          <b-checkbox v-model="isProtocol">Protocol?</b-checkbox>
        </b-input-group-prepend>
        <b-input-group-text style="max-width:150px;">
          <UserSelect :disabled="!isProtocol" :roles="['company']" v-model="localOrder.client.protocol_parent_user" :multiple="false" placement="bottom"></UserSelect>
        </b-input-group-text>
      </b-input-group>
    </div>
    <b-button class="addOrderBtn" type="button" variant="success" squared v-shortkey.once="['ctrl', 'enter']" @shortkey="placeOrder">Comandă</b-button>
  </div>
</template>

<script>
import AddressSearch from '@/components/dashboard/Operator/Orders/v2/AddressSearch'
import UserSelect from '@/components/common/UserSelect'
export default {
  name: 'AddOrderForm',
  components: { UserSelect, AddressSearch },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isProtocol: false,
      localOrder: null
    }
  },
  created () {
    this.localOrder = JSON.parse(JSON.stringify(this.order))
  },
  mounted () {
    this.$refs.addresses.$refs.select.$refs.search.focus()
  },
  computed: {
    clientName: {
      get () {
        return typeof this.localOrder.client.name !== 'undefined' ? this.localOrder.client.name : this.localOrder.name
      },
      set (value) {
        if (typeof this.localOrder.client.name !== 'undefined') {
          this.localOrder.client.name = value
        } else {
          this.localOrder.name = value
        }
      }
    },
    clientPhone: {
      get () {
        return typeof this.localOrder.client.phone !== 'undefined' ? this.localOrder.client.phone : this.localOrder.phone
      },
      set (value) {
        if (typeof this.localOrder.client.phone !== 'undefined') {
          this.localOrder.client.phone = value
        } else {
          this.localOrder.phone = value
        }
      }
    }
  },
  methods: {
    placeOrder () {
      this.$emit('place-order', this.localOrder)
    }
  }
}
</script>

<style scoped>

</style>
