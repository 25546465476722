<template>
  <div class="Chat">

    <div ref="ChatMessages" class="ChatMessages">
      <InfiniteLoading
        direction="top"
        @infinite="infiniteLoadingHandler"
      ></InfiniteLoading>
      <div
        v-for="(message, index) in $store.getters['messages/getMessages']"
        class="message"
        :class="{ 'from': message.sender_id !== messageFilters.user_id, 'to': message.sender_id === messageFilters.user_id }"
        :key="'message-' + index"
      >
        <p>
          {{ message.message }}
          <span class="pull-right" v-if="message.is_read === true ">
            <b-icon icon="check2-all" variant="primary"></b-icon>
          </span>
          <span class="pull-right" v-else>
            <b-icon icon="check2" variant="dark"></b-icon>
          </span>
        </p>
        <time>{{ message.created_at }}</time>
      </div>
    </div>

    <b-form @submit.prevent="sendMessage" class="ChatForm">
      <b-form-textarea
        id="textarea"
        v-model="message"
        placeholder="Mesaj ..."
        :disabled="sendMessageInProgress"
      ></b-form-textarea>
      <b-button :disabled="sendMessageInProgress" type="submit" squared variant="success"><b-icon icon="envelope"></b-icon></b-button>
    </b-form>

  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'ChatWindow',
  components: {
    InfiniteLoading
  },
  props: {
    messageFilters: {
      type: Object,
      required: true
    },
    sendMessageType: {
      type: String,
      default () {
        return 'default'
      }
    }
  },
  mounted () {
    this.$eventBus.addEventListener('ws_message_received', (payload) => {
      if (
        this.$store.getters['messages/getOpenedConversation'] &&
        this.$store.getters['messages/getOpenedConversation'].interlocutor_id === payload.message.sender_id
      ) {
        this.$store.commit('messages/pushMessage', {
          message: payload.message
        })
        this.$nextTick(() => {
          if (this.$refs.ChatMessages.scrollHeight - this.$refs.ChatMessages.scrollTop <= 800) {
            this.$refs.ChatMessages.scrollTo(0, this.$refs.ChatMessages.scrollHeight)
          }
        })
      }
    }, 'onChatMessage')
  },
  beforeDestroy () {
    this.$eventBus.removeEventListener('ws_message_received', 'onChatMessage')
  },
  data () {
    return {
      message: '',
      page: 1,
      messages: [],
      sendMessageInProgress: false
    }
  },
  methods: {
    sendMessage () {
      if (this.message) {
        this.sendMessageInProgress = true
        this.$store.dispatch('messages/sendMessage', {
          user_id: this.messageFilters.interlocutor_id,
          title: null,
          message: this.message,
          sender_name: this.$store.state.auth.user.id === 1 ? 'Asistență' : 'Dispecerat',
          type: this.sendMessageType
        })
          .then((response) => {
            this.$store.commit('messages/pushMessage', {
              message: response.data
            })
          })
          .catch(() => {
            this.$toasted.show('Error on message send. Try again.', {
              duration: 2000
            })
          })
          .then(() => {
            this.sendMessageInProgress = false
            this.message = ''
            this.$nextTick(() => {
              this.$refs.ChatMessages.scrollTo(0, this.$refs.ChatMessages.scrollHeight)
            })
          })
      }
    },
    infiniteLoadingHandler ($state) {
      this.$store.dispatch('messages/fetchMessages', {
        page: this.page,
        type: this.sendMessageType,
        interlocutor_id: this.messageFilters.interlocutor_id
      })
        .then(({ data }) => {
          if (data.length) {
            this.page += 1
            this.$store.commit('messages/unshiftMessages', {
              messages: data
            })
            $state.loaded()
          } else {
            $state.complete()
          }
        })
    }
  }
}
</script>
