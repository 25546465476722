<template>
  <div class="home">
    <OperatorDashboard></OperatorDashboard>
  </div>
</template>

<script>

import OperatorDashboard from '@/components/dashboard/OperatorDashboard'
export default {
  name: 'Dashboard',
  components: { OperatorDashboard }
}
</script>
