<template>
  <div>
    <b-list-group style="height: 300px; overflow-y: scroll;">
      <b-list-group-item :variant="(order.state === 'finished') ? 'success' : ''" @click="onRowClicked(order.id)" button v-for="order in driversLocations" :key="order.id">
        <b-row>
          <b-col md="4">#{{ order.id }} - {{ order.created_at }}</b-col>
          <b-col md="4">

            <div v-if="order.places">
              <span v-if="order.places[0] && order.places[0].name.length >= 30">
                {{ order.places[0].name.substring(0,30)+".." }}
              </span>
              <span v-else-if="order.places[0]">{{ order.places[0].name }}</span>
            </div>
          </b-col>
          <b-col md="2">
            <span v-if="order.driver">
             {{ order.driver.default_car.indicativ }} - {{ order.dispatcher_id ? 'apel' : 'online' }}
            </span>
            <span v-else>
              XXX - {{ order.dispatcher_id ? 'apel' : 'online' }}
            </span>
          </b-col>
          <b-col>
            <span v-if="order.state === 'finished'">F</span>
            <span v-if="order.state === 'canceled'">A</span>
            <span v-if="order.state === 'assigned'">I</span> -
            {{ order.cost ? order.cost : '0.00' }} RON
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>

    <b-row align-content="center" class="mt-2">
      <b-col>
        <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
        >
        </b-pagination>
      </b-col>
      <b-col>

        <b-list-group horizontal>
          <b-list-group-item class="p-2" >Total comenzi: </b-list-group-item>
          <b-list-group-item class="p-2" >{{ meta.total }}</b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'GetOrdersByIndicativ',
  components: {
  },
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      driversLocations: [],
      meta: null,
      perPage: 30,
      currentPage: 1
    }
  },
  watch: {

    currentPage: function () {
      this.getUserOrders()
    },
    userId: function () {
      this.$axios.get(config.baseApiUrl + '/api/orders?client_id=' + this.$props.userId + '&page=' + this.currentPage + '&per_page=' + this.perPage)
        .then(response => {
          this.driversLocations = response.data.data

          this.meta = response.data.meta
        })
    },
    props: function (props) {
      this.$store.state.dispatcherDashboard.orderInPreview = props
      this.$root.$emit('bv::toggle::collapse', 'order-sidebar')
    }
  },
  mounted () {
    this.getUserOrders()
  },
  methods: {

    onRowClicked (props) {
      this.$store.state.dispatcherDashboard.orderInPreview = props
      this.$root.$emit('bv::toggle::collapse', 'order-sidebar')
    },
    getUserOrders () {
      this.$axios.get(config.baseApiUrl + '/api/orders?client_id=' + this.$props.userId + '&page=' + this.currentPage + '&per_page=' + this.perPage)
        .then(response => {
          this.driversLocations = response.data.data

          this.meta = response.data.meta
        })
    }
  },
  computed: {

    rows () {
      return this.meta ? this.meta.total : 0
    }
  }
}
</script>

<style scoped>
</style>
