<template>
  <div class="">
    <div class="col-12">
      <div v-if="order.state === 'created'">
        <h5>Așteptare șofer ... <b-badge variant="dark">{{ searchDuration }}</b-badge> </h5>
        <div >
          <b-skeleton animation="wave" width="85%"></b-skeleton>
          <b-skeleton animation="wave" width="55%"></b-skeleton>
          <b-skeleton animation="wave" width="70%"></b-skeleton>
        </div>
      </div>
      <div v-else-if="order.state !== 'canceled' && driver">
        <b-card no-body bg-variant="transparent" style="border:0;" text-variant="dark" :sub-title="'Șofer alocat - ' + endSearchDuration">
          <b-card-text>
            <div class="row">
            <table class="table table-borderless">
              <tbody>
              <tr class="d-flex align-items-center">
                <td v-if="driver.identifier">
                  <b-button-group>
                    <b-button variant="success" size="lg">
                      {{ driver.identifier }} - <span v-if="order.waiting_driver_time !== null">{{ order.waiting_driver_time }} min</span>
                      <b-badge v-if="order.manually_assigned" variant="light" class="ml-3">Manual</b-badge>
                    </b-button>
                    <b-button v-if="order.state === 'assigned'" @click="showLocalisation = !showLocalisation" size="lg" variant="info">
                      <b-icon icon="geo-alt-fill"></b-icon>
                      {{ zoneName }}
                    </b-button>
                  </b-button-group>
                </td>
              </tr>
              </tbody>
            </table>
            </div>
          </b-card-text>
          <b-button-group size="sm" v-if="false">
            <b-button variant="success">
              <b-icon icon="check"></b-icon>
              Aprobă
            </b-button>
            <b-button variant="warning">
              <b-icon icon="arrow-clockwise"></b-icon>
              Caută din nou
            </b-button>
            <b-button variant="danger">
              <b-icon icon="x"></b-icon>
              Anulează
            </b-button>
          </b-button-group>
        </b-card>
      </div>
      <div  v-if="order.state === 'assigned' && showLocalisation">
        <b-modal
          v-model="showLocalisation" size="xl" hide-footer hide-header>
            <OrderLocalisation v-if="showLocalisation" :order="order"></OrderLocalisation>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import OrderLocalisation from '@/components/dashboard/Operator/OrderLocalisation'

export default {
  name: 'OrderDriverSearch',
  components: { OrderLocalisation },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  beforeDestroy () {
    this.showLocalisation = false
  },
  data () {
    return {
      metaList: [],
      showLocalisation: false,
      time: 0,
      isRunning: false,
      interval: null,
      zoneName: null
    }
  },
  computed: {
    driver () {
      return this.order.driver
    },
    orderData () {
      return this.order
    },
    searchDuration () {
      return this.$day.duration(
        this.$store.state.now.diff(
          this.$day.utc(this.order.start_at).local()
        )
      ).format('mm:ss')
    },
    endSearchDuration () {
      return this.$day.duration(
        this.$day.utc(this.order.assigned_at).diff(
          this.$day.utc(this.order.start_at)
        )
      ).format('mm:ss')
    },
    metaMap () {
      return Object.fromEntries(this.metaList.map(({ key, value }) => [key, value]))
    }
  },
  mounted () {
    if (this.order.driver) {
      this.getUserMeta()
      this.$axios.get(config.baseApiUrl + '/api/polygons/' + this.driver.current_polygon_id)
        .then((response) => {
          this.zoneName = response.data.name
        })
    }
  },
  methods: {
    getUserMeta () {
      this.$axios.get(config.baseApiUrl + '/api/users/' + this.driver.id + '/meta')
        .then((response) => {
          this.metaList = response.data.data
        })
    }
  },
  watch: {
    'order.driver': function () {
      if (this.order.driver) {
        this.$axios.get(config.baseApiUrl + '/api/polygons/' + this.driver.current_polygon_id)
          .then((response) => {
            this.zoneName = response.data.name
          })
      } else {
        this.zoneName = null
      }
    },
    'order.id': function () {
      this.showLocalisation = false
    }
  }
}
</script>

<style scoped>

</style>
