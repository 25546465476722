<template>
  <b-input-group>
    <b-form-input
      ref="clientName"
      :value="value"
      @input="$emit('input', $event)"
      id="input-2"
      type="text"
      placeholder="Nume client"
      required
      autocomplete="off"
      :disabled="isDisabled"
    ></b-form-input>
      <b-input-group-append>
        <b-form-input :disabled="isDisabled" :value="about" v-model="aboutInternal" @update="$emit('about-changed', $event)" class="med-input" style="border-radius: 0 3px 3px 0; width: 400px"  placeholder="Observații client"></b-form-input>
      </b-input-group-append>
    <b-input-group-append>
      <b-button v-if="inUpdate" @click="updateName" variant="success" class="shadow-none"><b-icon icon="check2-square"></b-icon></b-button>
      <b-button v-if="inUpdate" @click="inUpdate = false" variant="danger" class="shadow-none"><b-icon icon="x-square"></b-icon> </b-button>
      <b-button v-if="!inUpdate && isFulfilledOrder" @click="inUpdate = true" variant="info" class="shadow-none"><b-icon icon="pencil-square"></b-icon></b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>

export default {
  name: 'OrderClientNameField',
  props: {
    value: {
      type: String,
      required: true
    },
    about: {
      type: String,
      default: ''
    },
    isFulfilledOrder: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inUpdate: false,
      aboutInternal: ''
    }
  },
  mounted () {
    this.aboutInternal = this.about
  },
  computed: {
    isDisabled () {
      return this.isFulfilledOrder && !this.inUpdate
    }
  },
  methods: {
    updateName () {
      this.$store.dispatch('dispatcherDashboard/updateClientName', {
        name: this.value,
        about: this.aboutInternal
      })
        .then(() => {
          this.inUpdate = false
          this.$toasted.success('Nume client actualizat', {
            duration: 2000
          })
        })
    }
  }
}
</script>

<style scoped>

</style>
