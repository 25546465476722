import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import Toasted from 'vue-toasted'
import 'font-awesome/css/font-awesome.min.css'
import './assets/css/main.scss'
import config from '@/config'
import './plugins/dayjs'
import './plugins/event-bus'
import VueNativeSock from 'vue-native-websocket'
import vMultiselectListbox from 'vue-multiselect-listbox'
import 'vue-multiselect-listbox/src/scss/vue-multi-select-listbox.scss'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.use(require('vue-shortkey'), { prevent: ['input', '.vs__dropdown-option'] })

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(Toasted, {
  position: 'top-right',
  duration: 3000,
  router
})
Vue.component('VueFontawesome', require('vue-fontawesome-icon/VueFontawesome.vue').default)

Vue.component('v-multiselect-listbox', vMultiselectListbox)
store.commit('initStore')

Vue.config.productionTip = false
Vue.use(VueNativeSock, config.wsUrl + '?token=' + store.getters['auth/apiToken'], {
  format: 'json',
  reconnection: true,
  reconnectionAttempts: 100,
  reconnectionDelay: 3000,
  connectManually: true
})

Vue.use(VuejsDialog)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
