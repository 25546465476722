<template>
  <div>
    <div v-if="order.call" class="pr-3 text-right">
      <span v-if="order.call.state !== 'ended'">
        {{ activeCallDuration }}
      </span>
      <span v-else>
        {{ endedCallDuration }}
      </span>
    </div>
    <div v-else class="pr-3 text-right">
      --:-- &nbsp;
    </div>
  </div>
</template>

<script>
export default {
  name: 'CallDuration',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    activeCallDuration () {
      return this.$day.duration(
        this.$store.state.now.diff(
          this.$day.utc(this.order.call.started_at).local()
        )
      ).format('mm:ss')
    },
    endedCallDuration () {
      return this.$day.duration(
        this.$day.utc(this.order.call.ended_at).diff(
          this.$day.utc(this.order.call.started_at)
        )
      ).format('mm:ss')
    }
  }
}
</script>

<style>

</style>
