<template>
  <div v-if="order" class="orderView" >
    <div class="orderView-btns mb-4">
      <b-button variant="outline-secondary" size="sm" v-b-toggle:order-sidebar>
        <b-icon icon="arrow-left"></b-icon>
        Înapoi
      </b-button>
    </div>
    <b-card :sub-title="'Statut: ' + order.state" :title="'#' + order.id + ' / ' + toLocalDate(order.created_at)">
      <p v-if="order.cancellation_reason_text">{{ order.cancellation_reason_text }}</p>
    </b-card>

    <div v-if="order.client" class="grey-block my-4">
        <h4 class="mb-0">{{ order.client ? (order.client.name ? order.client.name : 'Fără nume') : '' }}</h4>
        <sub>Client</sub>
        <div class="my-3">
          <b-row>
            <b-col md="12">
              <table class="w-100">
                <tr>
                  <td>Înregistrat din:</td>
                  <td>{{ order.client.created_at }}</td>
                </tr>
                <tr>
                  <td>Telefon:</td>
                  <td>{{ order.client.phone }}</td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </div>
    </div>

    <b-tabs class="my-4" pills  content-class="mt-3">
      <b-tab title="Despre comandă" active v-if="order.client">
      <strong class="d-block mb-2">Despre comandă</strong>
        <b-row>
          <b-col md="6">
            <table class="w-100">
              <tr>
                <td>Procesat prin</td>
                <td>
                  OGO TAXI
                </td>
              </tr>
              <tr v-if="order.driver">
                <td>Șofer</td>
                <td>
                  <span v-if="order.driver.default_car">
                    <b-badge class="mr-2">{{ order.driver.default_car.indicativ ? order.driver.default_car.indicativ : 'xxx' }}</b-badge>
                  </span>
                  {{ order.driver.name }}
                </td>
              </tr>
              <tr v-if="order.dispatcher">
                <td>Operator</td>
                <td>
                  {{ order.dispatcher.name }}
                </td>
              </tr>
              <tr>
                <td>Modalitate de plată</td>
                <td class="text-uppercase">
                  {{ order.payment_type }}
                </td>
              </tr>
              <tr>
                <td>Total comandă</td>
                <td>
                  {{ order.cost ? order.cost : '0,00 RON' }}
                </td>
              </tr>
            </table>
          </b-col>
          <b-col md="6">
            <table class="w-100">
              <tr>
                <td>Timp estimat de șofer</td>
                <td>{{ order.waiting_driver_time }} minute</td>
              </tr>
              <tr>
                <td>Creată</td>
                <td>{{ toLocalDate(order.created_at) }}</td>
              </tr>
              <tr v-if="order.assigned_at">
                <td>Alocată</td>
                <td>{{ toLocalDate(order.assigned_at) }}</td>
              </tr>
              <tr v-if="order.trip_started_at">
                <td>Cursa a început la</td>
                <td>{{ toLocalDate(order.trip_started_at) }}</td>
              </tr>
              <tr v-if="order.trip_finished_at">
                <td>Cursa finalizată la</td>
                <td>{{ toLocalDate(order.trip_finished_at) }}</td>
              </tr>
            </table>
          </b-col>
        </b-row>

        <div class="my-4" v-if="order.places !== null">

          <strong>Traseu:</strong>
            <ul style="padding:0 15px">
              <li>{{ order.trip_started_at ? toLocalDate(order.trip_started_at) : ''  }} - {{ order.places[0].name ? order.places[0].name : ''  }} </li>
              <li v-if="order.places[1]">{{ order.trip_finished_at ? toLocalDate(order.trip_finished_at) : ''  }} - {{ order.places[1].name ?  order.places[1].name : '' }} </li>
            </ul>

            <GmapMap
              ref="map"
              :center="{lat: order.places[0].lat, lng: order.places[0].long}"
              :zoom="15"
              style="width: 100%; height: 250px; border: 1px solid #bdc1c6; border-radius: 5px; overflow: hidden;"
              :key="''"
            >
                <GmapMarker
                  :position="{lat: order.places[0].lat, lng: order.places[0].long}"

                  :clickable="false"
                  :draggable="false"
                  :label="{color:'#fff', fontSize:'9px',fontWeight:'bold', text: 'A'}"
                />
                <GmapMarker v-if="order.places[1]"
                  :position="{lat: order.places[1].lat, lng: order.places[1].long}"

                  :clickable="false"
                  :draggable="false"
                  :label="{color:'#fff', fontSize:'9px',fontWeight:'bold', text: 'B'}"
                />
            </GmapMap>

        </div>
      </b-tab>
      <b-tab title="Timeline">
        <OrderTimeline :order-id="order.id"></OrderTimeline>
      </b-tab>
      <b-tab v-if="order.driver" title="Istoric client">
          <strong class="d-block mb-2">Istoric client</strong>
          <UserOrders  v-if="order.client"  :user-id="order.client_id" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import config from '@/config'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-best-google-maps'
import UserOrders from '@/components/orders/GetOrdersByUser'
import dayjs from 'dayjs'
import OrderTimeline from '@/components/common/OrderTimeline'

Vue.use(VueGoogleMaps, {
  load: {
    key: config.googleMapsApiKey,
    libraries: 'places'
  }
})
export default {
  name: 'OrderViewDetails',
  components: {
    OrderTimeline,
    UserOrders
  },
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      order: null
    }
  },
  mounted () {
    this.$axios.get(config.baseApiUrl + '/api/orders/' + this.orderId)
      .then(response => {
        this.order = response.data
      })
  },
  methods: {
    closeReport (id) {
      this.hide(id)
    },
    toLocalDate (value) {
      return dayjs(value + 'Z').local().format('DD.MM.YYYY HH:mm')
    }
  },
  watch: {
    orderId: function (orderId) {
      this.$axios.get(config.baseApiUrl + '/api/orders/' + orderId)
        .then(response => {
          this.order = response.data
        })
    }
  }
}
</script>

<style scoped>

</style>
