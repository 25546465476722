<template>
  <div class="orderList">
    <b-list-group v-if="orders.length > 0">
    <b-list-group-item class="p-2 pb-1" style="min-height:20px">
      <b-input-group size="sm" style="border: 0">
        <b-form-input style="border: 0" variant="success" type="text" v-model="searchValue" placeholder="Caută adresă sau indicativ ..." ></b-form-input>
        <b-input-group-append>
          <b-button style="border: 0" @click="searchValue = null" class="ml-0" variant="outline-secondary"> <b-icon icon="x"></b-icon> </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-list-group-item>
      <OrderListItem
        v-for="(order, index) in filteredOrders" :key="'no-'+index"
        :order="order" :idx="index" @remove-from="removeFromList"
      >
      </OrderListItem>
    </b-list-group>
    <div v-else class="no-orders-smile">
      <div><b-icon   icon="emoji-smile"></b-icon></div>
      <div class="clearfix"></div>
      <p>Bravo, nu ai niciun client în așteptare!</p>
    </div>
    <button v-if="localStorage.linesAnswer" v-shortkey="{f1: ['f1'], f2: ['f2'], f3: ['f3'], f4: ['f4'], f5: ['f5'], f6: ['f6'], f7: ['f7'], f8: ['f8'], f9: ['f9']}" @shortkey="sipAction" class="d-none"></button>
    <button v-shortkey="['esc']" @shortkey="sipEndCall" class="d-none"></button>
  </div>
</template>

<script>
import OrderListItem from '@/components/dashboard/Operator/Orders/OrderListItem'

export default {
  name: 'OrdersList',
  components: { OrderListItem },
  props: {
    orders: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      localStorage: {
        lineAnswer: null
      },
      searchValue: ''
    }
  },
  computed: {
    filteredOrders () {
      const orders = []
      this.decoratedOrders.forEach((order) => {
        const exists = orders.find((o) => {
          return o.id === order.id
        })
        if (!exists) {
          if (this.searchValue === '' || !this.searchValue) {
            orders.push(order)
          } else if (order.car_indicativ === this.searchValue || order.address.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1) {
            orders.push(order)
          }
        }
      })
      return orders
    },
    decoratedOrders () {
      const orders = []
      let index = 1
      this.orders.forEach((order) => {
        if (order.client_phone && typeof this.$store.state.sip.calls[order.client_phone] !== 'undefined') {
          order.__call_index = index++
        } else {
          order.__call_index = null
        }
        orders.push(order)
      })
      return orders
    }
  },
  mounted () {
    this.getShortkeysType()
  },
  methods: {
    getShortkeysType () {
      if (localStorage.lineAnswer) {
        return localStorage.lineAnswer
      } else {
        return 'simple'
      }
    },
    removeFromList (orderId) {
      let index = null
      this.$store.getters['dispatcherDashboard/getOrders'].forEach((order, idx) => {
        if (order.id === orderId) {
          index = idx
        }
      })
      this.$store.getters['dispatcherDashboard/getOrders'].splice(index, 1)
      console.log(index)
    },
    sipAction (event) {
      const index = parseInt(event.srcKey.replace('f', '')) - 1
      const holdUnholdControls = document.querySelectorAll('.sip-hold-unhold-control')
      if (typeof holdUnholdControls[index] !== 'undefined') {
        holdUnholdControls[index].click()
      }
    },
    sipEndCall (event) {
      const endControl = document.querySelector('.sip-end-control.active-call')
      console.log(endControl)
      if (endControl !== null) {
        endControl.click()
      }
    }
  }
}
</script>

<style scoped>

</style>
