<template>
  <div>
    <b-input-group size="md" >
      <b-input-group-append>
        <b-button @click="sendContact" class="ml-0" variant="info"> <b-icon icon="telephone-forward"></b-icon> </b-button>
      </b-input-group-append>
      <b-form-input style="width:max-content" v-model="message.message" :placeholder="'Trimite un mesaj către - ' + (driver.default_car ? driver.default_car.indicativ : 'XXX')"></b-form-input>
      <b-input-group-append>
        <b-button @click="sendMessage" class="ml-0" variant="success"> <b-icon icon="envelope"></b-icon> </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import config from '@/config'
import { formatForDisplay } from '@/services/sipPhoneFormatter'

export default {
  name: 'OrderMessenger',
  props: {
    driver: {
      type: Object,
      required: true
    },
    phone: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      message: {
        title: null,
        message: null,
        type: 'default'
      }
    }
  },
  computed: {
    messageData () {
      return Object.assign({}, this.message, { user_id: this.driver.id })
    }
  },
  methods: {
    sendContact () {
      this.$axios({
        method: 'post',
        url: config.baseApiUrl + '/api/messages/to-user',
        data: {
          user_id: this.driver.id,
          title: null,
          message: 'Telefon client: ' + formatForDisplay(this.phone),
          type: 'default',
          sender_name: 'Dispecerat'
        }
      })
        .then((response) => {
          this.$toasted.success('Mesaj trimis!', {
            duration: 3000
          })
          this.message.message = null
        })
    },
    sendMessage () {
      this.$axios({
        method: 'post',
        url: config.baseApiUrl + '/api/messages/to-user',
        data: this.messageData
      })
        .then((response) => {
          this.$toasted.success('Mesaj trimis!', {
            duration: 3000
          })
          this.message.message = null
        })
    }
  }
}
</script>
