<template>
  <div>
    <b-popover :show="$store.state.messages.isOpen" custom-class="header-notifications" placement="bottomleft" triggers="click" no-fade target="showNotifications">
      <template #title>Mesaje </template>
      <b-tabs v-model="$store.state.messages.activeConversationIndex" pills fill card vertical>
        <b-tab>
          <template #title>
             <b-icon-plus-circle></b-icon-plus-circle> Conversație nouă
          </template>
          <b-card-text >
            <b-tabs  pills no-fade active-tab-class="card p-2 my-2">
              <b-tab>
                <template  #title>Caută</template>
                <div class="py-3">
                  <UserSelect v-model="$store.state.messages.selectedUser" @input="$store.state.messages.selectedUser = $event" style="width: 270px"></UserSelect>
                  <b-button @click="startNewConversation" :disabled="!$store.state.messages.selectedUser" variant="success" size="sm" class="mt-3">Începe conversația</b-button>
                </div>
              </b-tab>
              <b-tab>
                <template #title>Toți șoferii</template>
                <b-card-text class="py-3">
                  <b-textarea v-model="allDriversMessage"></b-textarea>
                  <b-button
                    :disabled="!allDriversMessage"
                    @click="sendToAllDrivers"
                    variant="success" size="sm" class="mt-3"
                  >Trimite mesaj</b-button>
                </b-card-text>
              </b-tab>
              <b-tab>
                <template #title>Toți clienții</template>
                <b-card-text class="py-3">
                  <b-textarea v-model="allClientsMessage"></b-textarea>
                  <b-button
                    :disabled="!allClientsMessage"
                    @click="sendToAllClients"
                    variant="success" size="sm" class="mt-3"
                  >Trimite mesaj</b-button>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card-text>
        </b-tab>
        <b-tab
          v-for="(conversation, index) in $store.getters['messages/getConversations']"
          @click="openConversation(conversation, index)"
          :key="'conversation-' + index"
          lazy
        >
          <template #title>
            {{ conversation.identifier ? conversation.identifier : 'XXX' }}
            <b-badge v-if="conversation.has_unread" variant="info" class="position-relative" style="top: -1px">
              {{ conversation.unread_count }}
            </b-badge>
          </template>
          <b-card-text>
            <ChatWindow
              :message-filters="{ user_id: $store.state.auth.user.id, type: 'default', interlocutor_id: conversation.interlocutor_id }"
            ></ChatWindow>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-popover>
  </div>
</template>

<script>
import ChatWindow from './ChatWindow.vue'
import UserSelect from '@/components/common/UserSelect'
import config from '@/config'
export default {
  name: 'Chat',
  components: { UserSelect, ChatWindow },
  data () {
    return {
      allDriversMessage: '',
      allClientsMessage: ''
    }
  },
  methods: {
    openConversation (conversation, index) {
      this.$store.dispatch('messages/openConversation', { conversation, index })
    },
    startNewConversation () {
      this.$store.commit('messages/addNewConversation', {
        conversation: {
          last_message_at: null,
          interlocutor_id: this.$store.state.messages.selectedUser.id,
          name: this.$store.state.messages.selectedUser.name,
          phone: this.$store.state.messages.selectedUser.phone,
          email: this.$store.state.messages.selectedUser.email,
          has_unread: false,
          unread_count: 0
        }
      })
    },
    sendToAllDrivers () {
      this.$axios.post(config.baseApiUrl + '/api/messages/to-all-drivers', {
        message: this.allDriversMessage
      })
        .then(() => {
          this.allDriversMessage = ''
        })
        .catch(() => {
          this.$toasted.error('An error occurred. Please try again.', {
            duration: 2000
          })
        })
    },
    sendToAllClients () {
      this.$axios.post(config.baseApiUrl + '/api/messages/to-all-clients', {
        message: this.allClientsMessage
      })
        .then(() => {
          this.allClientsMessage = ''
        })
        .catch(() => {
          this.$toasted.error('An error occurred. Please try again.', {
            duration: 2000
          })
        })
    }
  }
}
</script>
