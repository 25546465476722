<template>
  <div id="app">
    <FindBy :roles="['driver']" v-if="$route.path !== '/live'"></FindBy>
    <OrderView v-if="$route.path !== '/live'"></OrderView>
    <AppHeader v-if="$store.getters['auth/isUserLogged'] && !$route.meta.hideNav && !isCompany && $route.path !== '/live'"></AppHeader>
    <PosHeader v-if="$store.getters['auth/isUserLogged'] && !$route.meta.hideNav && isCompany"></PosHeader>
    <SiteHeader v-if="!$store.getters['auth/isUserLogged'] && !$route.meta.hideNav && $route.path !== '/live'"></SiteHeader>
    <PendingCalls
      v-if="isDispatcherLoggedIn && !$route.meta.hideNav && $route.path !== '/live' && this.$store.state.sip.isSipConnected === true"
    ></PendingCalls>
    <router-view class="viewAll" />
    <Footer class="d-none d-md-block" v-if="$store.getters['auth/isUserLogged'] && !$route.meta.hideNav && $route.path !== '/live'"></Footer>
    <DispatcherEvents v-if="isDispatcherLoggedIn && $route.path !== '/live'"></DispatcherEvents>
    <ActiveCalls></ActiveCalls>
  </div>
</template>

<style>

</style>
<script>
import FindBy from '@/components/common/FindByIndicativ'
import OrderView from '@/components/common/OrderView'
import AppHeader from '@/components/common/AppHeader'
import PosHeader from '@/components/common/PosHeader'
import Footer from '@/components/common/Footer'
import SiteHeader from '@/components/common/SiteHeader'
import DispatcherEvents from '@/components/common/DispatcherEvents'
import PendingCalls from '@/components/common/calls/PendingCalls'
import ActiveCalls from '@/components/common/calls/ActiveCalls'

export default {
  components: { ActiveCalls, PendingCalls, DispatcherEvents, FindBy, OrderView, AppHeader, PosHeader, SiteHeader, Footer },
  data () {
    return {

    }
  },
  computed: {
    isDispatcherLoggedIn () {
      return this.$store.getters['auth/isUserLogged'] && this.$store.getters['auth/loggedUser'].roles.includes('dispatcher')
    }
  },
  methods: {

    isCompany () {
      if (this.$store.getters['auth/loggedUser'].companies[0]) {
        return this.$store.getters['auth/loggedUser'].companies[0].type.includes('delivery')
      }
    }
  },
  created () {
    window.onbeforeunload = function () {
      return 'You have unsaved changes!'
    }
  }
}
</script>
