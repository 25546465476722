<template>
  <div>
    <DriversMap></DriversMap>
  </div>
</template>

<script>
import DriversMap from '@/components/dashboard/Operator/DriversMap'
export default {
  name: 'LiveMap',
  components: { DriversMap }
}
</script>

<style scoped>

</style>
