<template>
  <b-sidebar
      v-if="orderInPreview"
      :visible="orderInPreview != null"
      id="order-sidebar"
      :title="'Comanda ' + orderInPreview"
      backdrop
      shadow
      right
      no-header
      width="900px"
    >
      <!-- TODO UI -->
    <OrderViewDetails :order-id="orderInPreview" />
    </b-sidebar>
</template>

<script>
import OrderViewDetails from '@/components/common/OrderViewDetails'
export default {
  components: { OrderViewDetails },
  computed: {
    orderInPreview:
      {
        get () {
          return this.$store.state.dispatcherDashboard.orderInPreview
        },
        set (value) {
          this.value = value
        }
      }
  }
}
</script>
