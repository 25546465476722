<script>
import auth from '@/services/auth'

export default {
  name: 'Logout',
  created () {
    auth.logout()
    this.$disconnect()
    this.$router.push('/')
  },
  render () {
    return ''
  }
}
</script>
