import { render, staticRenderFns } from "./BuilderInterval.vue?vue&type=template&id=66d52eec&scoped=true&"
import script from "./BuilderInterval.vue?vue&type=script&lang=js&"
export * from "./BuilderInterval.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66d52eec",
  null
  
)

export default component.exports