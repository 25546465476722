<template>
  <div>
    <div class="row-wp">
      <div class="left-bar">
        <OperatorSideBar/>
      </div>
      <div class="main-bar">
        <div class="order-bar py-2 d-block d-md-flex justify-content-between">
          <div class="order-bar-btn d-flex">

            <router-link :to="{ name: 'OperatorOrderView', params: { id: 0 } }" class="btn btn-sm btn-success mr-3 px-0 px-md-5">
              Comandă nouă
            </router-link>
            <router-link :to="{ name: 'OperatorDriversMap' }" class="btn btn-sm btn-outline-dark  px-0 px-md-5">
              Hartă
            </router-link>

          </div>
          <div class="d-flex justify-content-between">

            <div class="operatorLiveStats mr-0 mr-md-auto ">
              <b-button size="sm" variant="light" class="statItem" v-b-tooltip.hover.bottom title="Comenzi în ultimele 24 ore">
                <b-icon icon="list"></b-icon> <strong>{{ commonStatistics.ordersTodayCount }}</strong>
              </b-button>
              <b-button size="sm" variant="light" class="statItem" v-b-tooltip.hover.bottom title="Comenzi anulate">
                <b-icon icon="x-square"></b-icon> <strong>{{ commonStatistics.canceledOrdersTodayCount }}</strong>
              </b-button>
              <b-button size="sm" variant="light" class="statItem" v-b-tooltip.hover.bottom title="Șoferi online">
                <b-icon icon="broadcast"></b-icon> <strong>{{ commonStatistics.driversOnlineCount }}</strong>
              </b-button>
              <b-button size="sm" variant="light" class="statItem" v-b-tooltip.hover.bottom title="Șoferi ocupați">
                <b-icon icon="people-fill"></b-icon> <strong>{{ commonStatistics.driversBusyCount }}</strong>
              </b-button>
              <b-button size="sm" variant="light" class="statItem d-none d-md-block" v-b-tooltip.hover.bottom title="Șoferi liberi">
                <b-icon icon="people"></b-icon> <strong>{{ commonStatistics.driversOnlineCount - commonStatistics.driversBusyCount }}</strong>
              </b-button>
              <b-button size="sm" variant="outline-info" class="statItem" v-b-tooltip.hover.bottom title="Șoferi cu prioritate" @click="showDriversPriorityWindow()">
                <b-icon icon="star"></b-icon> <strong></strong>
              </b-button>
            </div>

            <b-button size="sm" variant="outline-danger" class="ml-2 d-none d-md-block" @click="openBlocked"><b-icon icon="lock"></b-icon></b-button>
            <b-button size="sm" variant="outline-dark" class="ml-2 d-none d-md-block" @click="$bvModal.show('orders-modal')"><b-icon icon="search"></b-icon></b-button>
            <b-button class="ml-2 mt-3 mt-md-0 btn-outline-dark btnStats  align-items-center d-none d-md-flex" size="sm" v-b-toggle.zone-stats>
              <b-icon icon="card-list" font-scale="1"></b-icon>
            </b-button>
          </div>
        </div>
        <router-view></router-view>
      </div>
    </div>
    <b-sidebar v-model="isStatisticsSidebarOpen" right id="zone-stats" title="Statistici zone" bg-variant="light" text-variant="dark" backdrop backdrop-variant="light">
      <div class="px-3 py-2">
        <PolygonsStatistics v-if="isStatisticsSidebarOpen"></PolygonsStatistics>
      </div>
    </b-sidebar>

    <DriversPriority ref="driversPriorityWindow" />
    <b-modal hide-footer centered v-model="blockedModal" size="lg" title="Blocate">
        <b-table-simple bordered  v-if="blockedList.length >= 1">
          <thead>
            <tr>
              <th>Telefon</th>
              <th>Motiv</th>
              <th>Data</th>
              <th>Acțiune <b-button size="sm" variant="default" @click="$store.dispatch('sip/getList')">
                <b-icon icon="arrow-repeat" />
              </b-button></th>
            </tr>
          </thead>
          <tr v-if="addBlockNumber">
            <td>
              <b-form-input size="sm" v-model="blockedPhone.phone" placeholder="Telefon"></b-form-input>
            </td>
            <td colspan="2">
              <b-form-input size="sm" v-model="blockedPhone.reason" placeholder="Motiv"></b-form-input>
            </td>
            <td>
              <b-button @click="addBlocked" variant="danger" size="sm">Blocare</b-button>
              <b-button @click="addBlockNumber = !addBlockNumber" variant="secondary" class="ml-3" size="sm"><b-icon icon="x" /></b-button>
            </td>
          </tr>
          <tr v-else>
            <td colspan="4">
              <b-button @click="addBlockNumber = !addBlockNumber" block variant="success">+ Blochează număr nou</b-button>
            </td>
          </tr>
          <tr v-for="(item, idx) in blockedList" :key="idx">
            <td>{{ item.phone }}</td>
            <td>{{ item.reason }}</td>
            <td>{{ toLocalDate(item.created_at) }}</td>
            <td>
              <b-button class="p-0 text-danger" variant="default" size="sm" @click="removeBlocked(item.id)">
                <b-icon icon="x" /> șterge
              </b-button>
            </td>
          </tr>
        </b-table-simple>
        <p v-else>
          <b-table-simple borderless>
            <tr v-if="addBlockNumber">
            <td>
              <b-form-input size="sm" v-model="blockedPhone.phone" placeholder="Telefon"></b-form-input>
            </td>
            <td colspan="2">
              <b-form-input size="sm" v-model="blockedPhone.reason" placeholder="Motiv"></b-form-input>
            </td>
            <td>
              <b-button @click="addBlocked" variant="danger" size="sm">Blocare</b-button>
              <b-button @click="addBlockNumber = !addBlockNumber" variant="secondary" class="ml-3" size="sm"><b-icon icon="x" /></b-button>
            </td>
          </tr>
          <tr v-else>
            <td colspan="4">
              <b-button @click="addBlockNumber = !addBlockNumber" block variant="success">+ Blochează număr nou</b-button>
            </td>
          </tr>
          </b-table-simple>
          <br>Nimic de afișat.</p>
    </b-modal>

  </div>
</template>

<script>
import OperatorSideBar from '@/components/dashboard/Operator/SideBar'
import PolygonsStatistics from '@/components/dashboard/PolygonsStatistics'
import config from '@/config'
import dayjs from 'dayjs'
import DriversPriority from '../users/DriversPriority.vue'

export default {
  name: 'OperatorDashboard',
  components: { PolygonsStatistics, OperatorSideBar, DriversPriority },
  data () {
    return {
      googleLoaded: true,
      isStatisticsSidebarOpen: false,
      commonStatistics: {
        driversCount: 0,
        driversOnlineCount: 0,
        driversBusyCount: 0,
        clientsCount: 0,
        ordersTodayCount: 0,
        ordersTotalCount: 0,
        announcesCount: 0,
        incidentsCount: 0
      },
      driversPriorityWindow: false,
      commonStatisticsUpdateInterval: null,
      addBlockNumber: false,
      blockedModal: false,
      blockedPhone: {
        phone: null,
        reason: null
      }
    }
  },
  computed: {
    blockedList () {
      return this.$store.state.sip.list
    }
  },
  mounted () {
    this.$store.dispatch('sip/getList')
    this.getCommonStatistics()
    this.commonStatisticsUpdateInterval = setInterval(() => {
      this.getCommonStatistics()
    }, 30000)
  },
  beforeDestroy () {
    clearInterval(this.commonStatisticsUpdateInterval)
  },
  methods: {
    toLocalDate (value) {
      return dayjs(value + 'Z').local().format('DD.MM.YYYY HH:mm')
    },
    openBlocked () {
      this.blockedModal = true
    },
    addBlocked () {
      this.$axios.post(config.baseApiUrl + '/api/blocked-phone', this.blockedPhone).then(() => {
        this.$toasted.success('Blocat!', {
          duration: 1000
        })
        this.blockedPhone.phone = null
        this.blockedPhone.reason = null
        this.$store.dispatch('sip/getList')
      })
    },
    removeBlocked (id) {
      this.$axios.delete(config.baseApiUrl + '/api/blocked-phone/' + id).then(() => {
        this.$toasted.success('Sters!', {
          duration: 1000
        })
        this.$store.dispatch('sip/getList')
      })
    },
    showDriversPriorityWindow () {
      this.$refs.driversPriorityWindow.show()
    },
    showMap () {
      this.$router.push({ name: 'OperatorDriversMap' })
    },
    getCommonStatistics () {
      this.$axios.get(config.baseApiUrl + '/api/statistics/common')
        .then((response) => {
          this.commonStatistics = response.data
        })
    }
  }
}
</script>

<style scoped>
  .btn.btn-outline-dark {
    outline: none !important;
    box-shadow: none;
  }
  .btn.btn-outline-dark.router-link-exact-active {
    color: #ffffff;
    background-color: #343a40 !important;
  }
</style>
