<template>
  <!-- rowData is order -->
  <div>
    <b-row>
      <b-col md="6" v-if="rowData.driver">
        <table class="table table-sm table-borderless">
          <tr>
            <td>Șofer:</td>
            <td> <router-link  :to="'/user/' + rowData.driver.id + '/driver'">{{ rowData.driver.name }} {{ rowData.driver.default_car ? '/ ' + rowData.driver.default_car.indicativ : '' }} </router-link></td>
          </tr>

          <tr>
            <td>Telefon:</td>
            <td>{{ rowData.driver.phone }} </td>
          </tr>
        </table>
        <ul class="px-4">
          <li>{{ rowData.places[0].name  }} <small>( {{ rowData.trip_started_at  }} )</small></li>
          <li>{{ rowData.places[1].name  }} <small>( {{ rowData.trip_finished_at  }} )</small></li>
        </ul>
        <b-button variant="success" size="sm">Sună client</b-button>
        <b-dropdown size="sm" class="mx-1" right text="Contact șofer">
          <b-dropdown-item variant="success">Sună șoferul</b-dropdown-item>
          <b-dropdown-item variant="info">Trimite mesaj</b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col v-if="rowData.places[1]">
        <img class="w-100" :src="'https://maps.googleapis.com/maps/api/staticmap?center='+ rowData.places[0].lat + ','+ rowData.places[0].long + '&zoom=12&size=1600x200&maptype=roadmap&markers=color:red%7Clabel:A%7C'+ rowData.places[0].lat + ','+ rowData.places[0].long + '&markers=color:red%7Clabel:B%7C'+ rowData.places[1].lat + ','+ rowData.places[1].long + '&key=AIzaSyDKybss0A61GSXuEs1zUnRa0qf9ijvJZcE'" alt="">
      </b-col>
      <b-col v-else-if="rowData.places[0]">
        <img class="w-100" :src="'https://maps.googleapis.com/maps/api/staticmap?center='+ rowData.places[0].lat + ','+ rowData.places[0].long + '&zoom=12&size=1600x200&maptype=roadmap&markers=color:red%7Clabel:A%7C'+ rowData.places[0].lat + ','+ rowData.places[0].long + '&key=AIzaSyDKybss0A61GSXuEs1zUnRa0qf9ijvJZcE'" alt="">
      </b-col>
      <b-col v-else>

      </b-col>
    </b-row>

    </div>
</template>

<script>
export default {
  name: 'OrderDetails',
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  }
}
</script>

<style scoped>

</style>
