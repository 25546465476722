import store from '@/store'
import Vue from 'vue'
import config from '@/config'

function login (username, password) {
  return Vue.axios.post(config.baseApiUrl + '/api/access-tokens', {
    email: username,
    password: password
  }, {
    headers: {
      'company-id': 1
    }
  })
    .then(function (response) {
      return Vue.axios.get(config.baseApiUrl + '/api/verify-access-tokens', {
        headers: {
          Authorization: `Bearer ${response.data.token}`,
          'company-id': 1
        }
      })
        .then(function (response2) {
          store.commit('auth/setUserData', {
            user: response2.data.user,
            token: response.data.token,
            meta: response2.data.meta
          })
        })
    })
}

function logout () {
  store.commit('auth/logout')
}

export default {
  login,
  logout
}
