<template>
  <b-input-group style="position: relative;">
    <b-form-input
    v-if="!showAlt"
      ref="clientName"
      v-model="client.name"
      id="input-2"
      type="text"
      placeholder="Nume client"
      required
      autocomplete="off"
      :disabled="isDisabled"
      class="main-name"
    ></b-form-input>
    <b-form-input
    v-if="showAlt"
      v-model="$store.state.dispatcherDashboard.orderInView.client_name"
      id="input-3"
      type="text"
      placeholder="Nume client"
      required
      autocomplete="off"
      :disabled="isDisabled"
    ></b-form-input>
    <div class="client-history-btn" v-if="client.phone">
      <b-btn @click="showClientHistory" variant="link">Istoric</b-btn>
    </div>
      <b-input-group-append v-if="client.name">
        <b-button @click="showAltName" :variant="showAlt ? 'danger': 'secondary'">
          <b-icon :icon="showAlt ? 'x': 'plus'" />
        </b-button>
      </b-input-group-append>
      <b-input-group-append>
        <b-form-input class="d-none d-md-block" style="min-width: 15vw !important" :disabled="isDisabled" v-model="client.about"  placeholder="Observații client"></b-form-input>
        <b-btn @click="saveObservation">SAVE</b-btn>
      </b-input-group-append>
    <b-input-group-append>
      <b-button v-if="inUpdate" @click="updateName" variant="outline-secondary" class="shadow-none"><b-icon icon="check2-square"></b-icon></b-button>
      <b-button v-if="inUpdate" @click="inUpdate = false" variant="outline-secondary" class="shadow-none"><b-icon icon="x-square"></b-icon> </b-button>
      <b-button v-if="!inUpdate && isFulfilledOrder" @click="inUpdate = true" variant="outline-secondary" class="shadow-none"><b-icon icon="pencil-square"></b-icon></b-button>
    </b-input-group-append>
    <b-modal v-model="clientHistoryModal" hide-header hide-footer centered no-fade size="xl" scrollable>
      <ClientHistory :client-id="client.id" />
    </b-modal>
  </b-input-group>
</template>
<style>
.main-name {
  position: relative;
}
.client-history-btn {
  position: relative;
  width: 0px;
  margin: 0 -2px 0 0 ;
  padding: 0;
}
.client-history-btn button {
  position: absolute;
  top: 0;
  right: 10px;
}

</style>
<script>
import ClientHistory from './ClientHistory.vue'
import config from '@/config'

export default {
  name: 'OrderClientNameField',
  components: { ClientHistory },
  props: {
    client: {
      type: Object,
      required: true
    },
    isFulfilledOrder: {
      type: Boolean,
      default: false
    },
    phone: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      inUpdate: false,
      showAlt: false,
      clientHistoryModal: false
    }
  },
  mounted () {
    if (this.$store.state.dispatcherDashboard.orderInView.client_name) {
      this.showAlt = true
    }
  },
  computed: {
    order () {
      return this.$store.state.dispatcherDashboard.orderInView
    },
    isDisabled () {
      return this.isFulfilledOrder && !this.inUpdate
    }
  },
  watch: {
    order (newVal, oldVal) {
      if (this.$store.state.dispatcherDashboard.orderInView.client_name) {
        this.showAlt = true
      } else {
        this.showAlt = false
      }
    }
  },
  methods: {
    saveObservation () {
      if (!this.phone) {
        this.$toasted.error('Numarul de telefon este obligatoriu', { duration: 3000 })
        return
      }
      this.$axios.patch(config.baseApiUrl + '/api/users/' + this.phone + '/about', {
        about: this.client.about
      }).then(() => {
        this.$toasted.success('Actualizat', { duration: 3000 })
      })
    },
    showClientHistory () {
      this.clientHistoryModal = true
    },
    showAltName () {
      this.showAlt = !this.showAlt
    },
    updateName () {
      this.$store.dispatch('dispatcherDashboard/updateClientName', {
        name: this.client.name,
        about: this.client.about
      })
        .then(() => {
          this.inUpdate = false
          this.$toasted.success('Nume client actualizat', {
            duration: 2000
          })
        })
    }
  }
}
</script>

<style scoped>

</style>
