<template>
  <div>
    <b-list-group>
      <b-list-group-item @click="getPolygon(item.polygonId)" class="statisticsItem" v-for="(item, index) in data" :key="'psi-' + index" v-b-toggle="'collapse-' + index">
        <div class="clearfix">
          <div class="pull-left">{{ item.name }}</div>
          <div class="pull-right">
            {{ item.busyDrivers }} / {{ item.freeDrivers }}
          </div>
        </div>
        <b-collapse :id="'collapse-' + index" accordion="my-accordion">
          <ul style="list-style-type: none; margin: 0; padding: 0">
            <li v-for="(item, idx) in indicatives" :key="idx" class="d-flex justify-content-between align-items-center">
              <b-badge>{{ item.indicativ }}</b-badge>
              <span>
                {{ Math.floor((Math.abs(new Date(item.idle_from) - new Date())) / 60000) }} min.
              </span>
            </li>
          </ul>
        </b-collapse>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'PolygonsStatistics',
  data () {
    return {
      interval: null,
      data: [],
      indicatives: []
    }
  },
  mounted () {
    this.getStatistics()
    this.interval = setInterval(() => {
      this.getStatistics()
    }, 30000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    getStatistics () {
      this.$axios.get(config.baseApiUrl + '/api/polygons/statistics')
        .then((response) => {
          this.data = response.data
        })
    },
    getPolygon (id) {
      this.$axios.get(config.baseApiUrl + '/api/statistics/drivers-info?polygon_id=' + id)
        .then((response) => {
          this.indicatives = response.data
        })
    }
  }
}
</script>

<style scoped>

</style>
