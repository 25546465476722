<template>
  <div>
    <div class="container-fluid mt-3">
      <b-card  no-body border-variant="white">
        <b-tabs pills  nav-wrapper-class="tabs-fixed" active-nav-item-class="bg-dark text-white" active-tab-class="text-dark" no-key-nav  >
          <template #tabs-end>
            <li>
            </li>
            <li class="d-flex ml-5" style="flex: 1; justify-content: flex-end;">
              <div class="operatorLiveStats">
                <b-button size="sm" variant="white" class="statItem" v-b-tooltip.hover.top title="Comenzi în ultimele 24 ore">
                  <b-icon icon="list"></b-icon> {{ commonStatistics.ordersTodayCount }}
                </b-button>
                <b-button size="sm" variant="white" class="statItem" v-b-tooltip.hover.top title="Comenzi anulate">
                  <b-icon icon="x-square"></b-icon> {{ commonStatistics.canceledOrdersTodayCount }}
                </b-button>
                <b-button size="sm" variant="white" class="statItem" v-b-tooltip.hover.top title="Șoferi online">
                  <b-icon icon="broadcast"></b-icon> {{ commonStatistics.driversOnlineCount }}
                </b-button>
                <b-button size="sm" variant="white" class="statItem" v-b-tooltip.hover.top title="Șoferi ocupați">
                  <b-icon icon="people-fill"></b-icon> {{ commonStatistics.driversBusyCount }}
                </b-button>
                <b-button size="sm" variant="white" class="statItem" v-b-tooltip.hover.top title="Șoferi liberi">
                  <b-icon icon="people"></b-icon> {{ commonStatistics.driversOnlineCount - commonStatistics.driversBusyCount }}
                </b-button>
                <b-button variant="white" size="sm" v-b-toggle.zone-stats>
                  <b-icon icon="card-list" ></b-icon>
                  <span class="d-none ml-md-2 d-md-inline">Zone</span>
                </b-button>
              </div>
            </li>
          </template>
          <b-tab title="In apel" active lazy @click="$store.state.dispatcherDashboard.newOrdersList = []">
            <b-card-text class="order-height">
              <OrdersListTable type="inCall"></OrdersListTable>
            </b-card-text>
          </b-tab>
          <b-tab title="Confirmate" lazy @click="$store.state.dispatcherDashboard.newOrdersList = []">
            <b-card-text class="order-height">
              <OrdersListTable type="live"></OrdersListTable>
            </b-card-text>
          </b-tab>
          <b-tab title="Finalizate" lazy @click="$store.state.dispatcherDashboard.newOrdersList = []">
            <b-card-text class="order-height">
              <OrdersListTable type="finished"></OrdersListTable>
            </b-card-text>
          </b-tab>
          <b-tab title="Anulate" lazy @click="$store.state.dispatcherDashboard.newOrdersList = []">
            <b-card-text class="order-height">
              <OrdersListTable type="canceled"></OrdersListTable>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <b-sidebar v-model="isStatisticsSidebarOpen" right id="zone-stats" title="Statistici zone" bg-variant="light" text-variant="dark" backdrop backdrop-variant="light">
      <div class="px-3 py-2">
        <PolygonsStatistics v-if="isStatisticsSidebarOpen"></PolygonsStatistics>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import OrdersListTable from '@/components/dashboard/Operator/Orders/v2/OrdersListTable'
import PolygonsStatistics from '@/components/dashboard/PolygonsStatistics'
import config from '@/config'

export default {
  components: { OrdersListTable, PolygonsStatistics },
  data () {
    return {
      googleLoaded: true,
      isStatisticsSidebarOpen: false,
      commonStatistics: {
        driversCount: 0,
        driversOnlineCount: 0,
        driversBusyCount: 0,
        clientsCount: 0,
        ordersTodayCount: 0,
        ordersTotalCount: 0,
        announcesCount: 0,
        incidentsCount: 0
      },
      commonStatisticsUpdateInterval: null
    }
  },

  mounted () {
    this.getCommonStatistics()

    this.commonStatisticsUpdateInterval = setInterval(() => {
      this.getCommonStatistics()
    }, 30000)
  },
  beforeDestroy () {
    clearInterval(this.commonStatisticsUpdateInterval)
  },
  methods: {
    getCommonStatistics () {
      this.$axios.get(config.baseApiUrl + '/api/statistics/common')
        .then((response) => {
          this.commonStatistics = response.data
        })
    }
  }
}
</script>
