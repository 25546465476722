<template>
  <div class="active-calls d-none" style="background: #fff;">
    <div v-for="(call, index) in $store.state.sip.calls" class="d-flex" :key="'ac-' + index">
      <ActiveCall :phone="index" :active-call="call" />
    </div>
  </div>
</template>

<script>

import ActiveCall from '@/components/common/calls/ActiveCall.vue'

export default {
  name: 'ActiveCalls',
  components: { ActiveCall }
}
</script>

<style scoped>
  .active-calls {
    position: fixed;
    bottom: 5px;
    left: 5px;
    border: 1px solid #ccc;
    padding: 4px;
    background-color: #ccc;
    border-radius: 4px;
    z-index: 99999999999;
  }
</style>
