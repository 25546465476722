<template>
  <div>
    <b-button class="d-none" v-shortkey.once="['f6']" @shortkey="toggleModal()" >Launch modal</b-button>
    <b-modal top id="orders-modal" ref="searchBy" hide-footer centered :size="driver ? 'xl' : 'xs'" title="Căutare comandă după indicativ">
      <b-form class="pb-3" inline>
        <div class="row w-100 mb-2">
          <div class="col-12" style="min-height: 38px;">
            <UserSelect
              :roles="['driver', 'client']"
              :use-identifier="false"
              v-model="driver"
              class="w-100"
              ref="indicativ"
            >
            </UserSelect>
          </div>
        </div>
      </b-form>
      <div v-if="driver != null" id="orders_by_indicative">
          <DriverOrders :height-h="50"  v-if="driver"  :user-id="driver.id" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import config from '@/config'
import OrderDetails from '@/components/reports/OrderDetails'
import 'vue-select/dist/vue-select.css'
import UserSelect from '@/components/common/UserSelect'
import queryString from 'query-string'
import CssConfig from '@/assets/other/VuetableBootstrap4Config'
import DriverOrders from '@/components/orders/GetOrders'

export default {
  name: 'FindByIndicativ',
  components: {
    UserSelect,
    DriverOrders
  },
  data () {
    return {
      isModalShown: false,
      driver: null,
      css: CssConfig,
      OrderDetails: OrderDetails,
      filters: {
        date_from: null,
        date_to: null,
        time_from: null,
        time_to: null,
        states: [],
        driver_id: null,
        client_id: null,
        dispatcher_id: null,
        company_id: null,
        per_page: 15,
        page: 1,
        payment_method: null
      },
      orderStatesList: [
        { label: 'Nou', state: 'new' },
        { label: 'Creat', state: 'created' },
        { label: 'Alocat', state: 'assigned' },
        { label: 'Finalizat', state: 'finished' },
        { label: 'Anulat', state: 'canceled' }
      ],
      orderPaymentMethods: [
        { label: 'Card', state: 'card' },
        { label: 'Cash', state: 'cash' },
        { label: 'Protocol', state: 'protocol' }
      ],
      orderSources: [
        { label: 'Apel telefonic', state: 'from_call' },
        { label: 'Aplicatie mobila', state: 'from_app' }
      ],
      currentPaginationData: null,
      tableFields: [
        {
          name: 'created_at',
          title: 'Data',
          formatter (value) {
            return new Date(value).toLocaleDateString('ro-RO',
              {
                hour: '2-digit',
                minute: '2-digit'
              }).replace(',', ' - ')
          },
          sortField: 'created_at'
        },
        {
          name: 'places',
          title: 'Adresa preluare',
          formatter (value) {
            return value && typeof value[0] !== 'undefined' ? (value[0].name) : ''
          }
        },
        {
          name: 'client',
          title: 'Client',
          formatter (value) {
            return value ? value.name : '-'
          },
          sortField: 'client'
        },
        {
          name: 'payment_type',
          title: 'Plată',
          formatter (value) {
            let plata = '-'
            if (value === 'card') {
              plata = 'Card'
            }
            if (value === 'cash') {
              plata = 'Numerar'
            }
            if (value === 'protocol') {
              plata = 'Protocol'
            }
            return plata
          },
          sortField: 'payment_type'
        },
        {
          name: 'state',
          title: 'Statut',
          formatter (value) {
            let statut = '-'
            if (value === 'created') {
              statut = '*'
            }
            if (value === 'new') {
              statut = 'Nou'
            }
            if (value === 'finished') {
              statut = 'Finalizat'
            }
            if (value === 'assigned') {
              statut = 'În curs'
            }
            if (value === 'canceled') {
              statut = 'Anulat'
            }
            return statut
          },
          sortField: 'state'
        },
        {
          name: 'dispatcher_id',
          title: 'Sursa',
          formatter (value) {
            if (value != null) {
              return 'Apel dispecerat'
            } else {
              return 'Aplicatie mobila'
            }
          },
          sortField: 'order_source'
        }
      ]
    }
  },
  methods: {
    toggleModal () {
      this.isModalShown = true
      this.$refs.searchBy.show()
    },
    fetchApiData (apiUrl, httpOptions) {
      document.getElementById('orders_by_indicative').classList.add('data-loading')
      return this.$axios.get(apiUrl, httpOptions)
    },
    transformApiResponse (response) {
      document.getElementById('orders_by_indicative').classList.remove('data-loading')
      return response
    },
    onPaginationData (paginationData) {
      this.currentPaginationData = paginationData
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      if (page === 'next' && this.filters.page < this.currentPaginationData.last_page) {
        this.filters.page++
      } else if (page === 'prev' && this.filters.page > 1) {
        this.filters.page--
      } else if (typeof page === 'number') {
        this.filters.page = page
      }
    },
    onRowClicked (props) {
      this.$store.state.dispatcherDashboard.orderInPreview = props.data.id
      this.$root.$emit('bv::toggle::collapse', 'order-sidebar')
    }
  },

  computed: {
    apiFilters () {
      const filters = JSON.parse(JSON.stringify(this.filters))
      filters.state = filters.states ? filters.states.join(',') : null
      delete filters.states
      return filters
    },
    reactiveApiUrl () {
      return config.baseApiUrl + '/api/orders?' + queryString.stringify(this.apiFilters)
    },
    ordersOnPage () {
      return this.$refs.vuetable.tableData.length
    }
  }
}
</script>

<style scoped>
#orders-modal___BV_modal_outer_ {
  z-index: 1034 !important;
}

</style>
