<template>
  <div>
    <div v-if="$store.state.isGoogleApiLoaded" class="position-relative">
      <keep-alive>
      <GmapMap
        v-if="driversLocations"
        ref="map"
        :center="mapCenter"
        :zoom="13"
        :options="MapStyle"
        style="width: 100%; height: calc(50vh - 113px); border-bottom: 1px solid #bdc1c6; border-top: 1px solid #bdc1c6"
        :key="''"
      >
        <GmapCircle
          :center="mapCenter"
          :radius="1500"
          :visible="true"
          :options="{fillColor:'red',fillOpacity:0.2}"
        ></GmapCircle>
        <!-- <GmapCluster
          :gridSize="30"
          :zoomOnClick="true"
          :minimumClusterSize="2"

        > -->
          <GmapMarker
            :key="index"
            v-for="(m, index) in driversLocationsFiltered"
            :position="{lat: m.lat, lng: m.long}"

            :clickable="true"
            :icon="markerIcon(m)"
            :draggable="false"
            :label="{color:'#fff', fontSize:'9px',fontWeight:'bold', text: m.indicativ}"
            @click="driverInfo(m.userId)"
          />
        <!-- </GmapCluster> -->
      </GmapMap>
      </keep-alive>

      <div class="driverInfo"  v-click-outside="hideDriverInfo" v-show="showDriverInfo" :class="{ showDriver: showDriverInfo }">
        <b-card>
          <b-card-body body-class="p-0">
            <b-card-title>{{ Sofer.default_car ? Sofer.default_car.indicativ : '' }} <b-button variant="danger" size="sm" class="pull-right" @click="hideDriverInfo"><b-icon icon="x"></b-icon></b-button></b-card-title>
            <b-card-sub-title class="mb-2">{{ Sofer.name }}  </b-card-sub-title>
            <b-card-text>
              <table class="w-100">
                <tr>
                  <td>Blocat: </td>
                  <td v-if="Sofer.ban_until !== null" class="text-danger">
                    Da
                    <span v-if="Sofer.ban_reason">
                      &nbsp;({{ Sofer.ban_reason }})
                    </span>
                  </td>
                  <td v-else class="text-success">Nu</td>
                </tr>
                <tr>
                  <td>Online: </td>
                  <td v-if="Sofer.is_online" class="text-success">Da</td>
                  <td v-else class="text-danger">Nu</td>
                </tr>
                <tr>
                  <td>Ocupat: </td>
                  <td v-if="Sofer.is_busy" class="text-danger">Da</td>
                  <td v-else class="text-success">Nu</td>
                </tr>
                <tr v-if="Sofer.default_car">
                  <td>Auto: </td>
                  <td>{{ Sofer.default_car.model + ' - ' + Sofer.default_car.color }}</td>
                </tr>
                <tr v-if="Sofer.default_car">
                  <td></td>
                  <td>{{ Sofer.default_car.number }}</td>
                </tr>
                <tr>
                  <td>Balanța: </td>
                  <td>{{ Sofer.balance }} RON</td>
                </tr>
              </table>
            </b-card-text>
          </b-card-body>

            <template #footer>
              <b-row>
                <b-button class="ml-3 mr-1" size="sm" variant="secondary" target="_blank" :href="'/user/' + Sofer.id + '/driver'"> Edit <b-icon icon="pencil-square"></b-icon></b-button>
                <UserBanControl v-if="Sofer" :user="Sofer"></UserBanControl>
                <b-button class="mx-1" size="sm" variant="warning" target="_blank" :href="'/user/' + Sofer.id + '/driver'"> Apel <b-icon icon="telephone-outbound"></b-icon></b-button>
                <b-button @click="openMessages" class="ml-1 mr-3" size="sm" variant="success"> Mesaj <b-icon icon="envelope"></b-icon></b-button>
              </b-row>

            </template>
        </b-card>
      </div>
    </div>

  </div>
</template>

<script>
import mapIcoFree from '@/assets/driverLocFree.svg'
import mapIcoBusy from '@/assets/driverLocBusy.svg'
// import mapIcoBan from '@/assets/driverLocBan.svg'
import config from '@/config'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-best-google-maps'
import ClickOutside from 'vue-click-outside'
import UserBanControl from '@/components/users/UserBanControl'
// import GmapCluster from 'vue2-best-google-maps/dist/components/cluster' // replace src with dist if you have Babel issues

Vue.use(VueGoogleMaps, {
  load: {
    key: config.googleMapsApiKey,
    libraries: 'places'
  }
})

export default {
  name: 'DriversMap',
  components: { UserBanControl/*,  GmapCluster */ },
  data () {
    return {
      driversLocations: [],
      driversData: [],
      driverD: '',
      selected: null,
      optionsSaved: [
        { value: null, text: 'Barbu Lautaru 12' },
        { value: 'a', text: 'Aleea Florilor 7B' }
      ],
      MapStyle: {
        mapTypeControl: false,
        streetViewControl: false
      },
      showDriverInfo: false,
      Sofer: '',
      getDriverPositionsInterval: null,
      markerSearch: '',
      mapCenter: this.$store.getters['auth/companyLocation']
    }
  },
  mounted () {
    this.popupItem = this.$el
    this.getDriverPositions()

    this.getDriverPositionsInterval = setInterval(this.getDriverPositions, 25000)
  },
  beforeDestroy () {
    clearInterval(this.getDriverPositionsInterval)
  },
  computed: {
    driversLocationsFiltered () {
      if (this.markerSearch) {
        return this.driversLocations.filter((item) => {
          return item.indicativ === this.markerSearch
        })
      }
      return this.driversLocations
    }
  },
  methods: {
    toggleDriverInfo () {
      this.showDriverInfo = true
    },
    hideDriverInfo () {
      this.showDriverInfo = false
    },
    getDriverPositions () {
      this.$axios.get(config.baseApiUrl + '/api/drivers-positions')
        .then(response => {
          this.driversLocations = response.data
        })
    },
    getDriverData (driverId) {
      this.$axios.get(config.baseApiUrl + '/api/users/' + driverId)
        .then(response => {
          this.Sofer = response.data
        })
    },
    driverInfo (driverId) {
      this.showDriverInfo = true
      this.getDriverData(driverId)
    },
    markerIcon (data) {
      if (data.is_busy) {
        return {
          url: mapIcoBusy,
          strokeColor: '#ffffff',
          scaledSize: { width: 22, height: 22 },
          labelOrigin: { x: 11, y: 11 }
        }
      }
      return {
        url: mapIcoFree,
        strokeColor: '#ffffff',
        scaledSize: { width: 22, height: 22 },
        labelOrigin: { x: 11, y: 11 }
      }
    },
    openMessages () {
      this.$store.state.messages.isOpen = true
      this.$store.dispatch('messages/fetchConversations').then(() => {
        setTimeout(() => {
          this.$store.commit('messages/addNewConversation', {
            conversation: {
              last_message_at: null,
              interlocutor_id: this.Sofer.id,
              name: this.Sofer.name,
              phone: this.Sofer.phone,
              email: this.Sofer.email,
              has_unread: false,
              unread_count: 0
            }
          })
        }, 500)
      })
    }
  },
  watch: {
    driversLocationsFiltered: function (results) {
      if (results.length === 1) {
        this.mapCenter.lat = results[0].lat
        this.mapCenter.long = results[0].long
      }
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style scoped>

</style>
