<template>
  <div>
    <b-input-group class="mt-1 mb-3">
      <b-form-input v-model="address.name" placeholder="Adresa" class="w-50"/>
      <b-form-input v-model="address.bloc" placeholder="Bloc"/>
      <b-form-input v-model="address.scara" placeholder="Scara"/>
      <b-form-input v-model="address.reper" placeholder="Reper"/>
    </b-input-group>
    <b-card no-body>
      <GmapMap v-if="$store.state.isGoogleApiLoaded" ref="map" :center="this.$store.getters['auth/companyLocation']" :zoom="15" style="width: 100%; height: 500px" :options="options">
        <GmapMarker @dragend="onMapDragEnd" :position="this.$store.getters['auth/companyLocation']" :draggable="true"/>
      </GmapMap>
      <div class="map-datas" v-if="markerPosition">
        <p>
          Latitudine: <strong>{{ markerPosition.lat }}</strong>
        </p>
        <p>
          Longitudine: <strong>{{ markerPosition.lng }}</strong>
        </p>
        <b-btn class="mt-3" variant="success" block @click="save">Salvează adresa</b-btn>
      </div>
    </b-card>
  </div>
</template>

<script>
import { uuid } from 'uuidv4'
import config from '@/config'

export default {
  name: 'ManualAddress',
  props: {
    address: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      options: {
        disableDefaultUi: true
      },
      markerPosition: null
    }
  },
  methods: {
    onMapDragEnd (event) {
      console.log(event)
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
    },
    save () {
      const newAddress = {
        place_id: uuid().toString(),
        name: this.address.name,
        scara: this.address.scara,
        bloc: this.address.bloc,
        reper: this.address.reper,
        comment: this.address.comment,
        lat: this.markerPosition.lat,
        lng: this.markerPosition.lng
      }
      console.log(newAddress)

      this.$axios.post(config.addrApiUrl + '/add-place', newAddress)
        .then((response) => {
          this.$toasted.success('Adresa salvată', {
            duration: 3000
          })
          this.$emit('address-saved', newAddress)
        })
        .catch((error) => {
          error.response.data.errors.forEach(e => {
            this.$toasted.error(e.msg + ' for field &nbsp; <b>' + e.path + '</b>', {
              duration: 3000
            })
          })
        })
    }
  }
}
</script>
