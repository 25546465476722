<template>
    <div v-if="type === 'tooltip'">
        <b-tooltip :target="'tooltipDriver' + userId" triggers="hover" placement="right">
            {{ metaMap.app_version }} <br>
            {{ metaMap.device }}
        </b-tooltip>
        <b-icon icon="info-square" :id="'tooltipDriver' + userId"></b-icon>
    </div>
    <div v-else>
        {{ metaMap.app_version ? metaMap.app_version : '- - - -'  }}
    </div>
</template>

<script>
import config from '@/config'
export default {
  name: 'UserMeta',
  props: {
    userId: {
      type: Number,
      default: 1,
      required: false
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      metaList: []
    }
  },
  computed: {
    metaMap () {
      return Object.fromEntries(this.metaList.map(({ key, value }) => [key, value]))
    }
  },
  mounted () {
    this.getUserMeta()
  },
  methods: {
    getUserMeta () {
      this.$axios.get(config.baseApiUrl + '/api/users/' + this.userId + '/meta')
        .then((response) => {
          this.metaList = response.data.data
        })
    }
  },
  watch: {
    userId: function () {
      this.$axios.get(config.baseApiUrl + '/api/users/' + this.userId + '/meta')
        .then((response) => {
          this.metaList = response.data.data
        })
    }
  }
}
</script>
