<template>
  <div>
    <div v-if="phoneNumber === '+437367311180'">PROBA (Ignorați)</div>
    <div v-else>Apel de intrare: {{ phoneNumber }}</div>
    <div class="mt-1">
      <b-button ref="acceptBtn" v-shortkey.once="['shift','enter']" @shortkey="acceptCall()" @click="acceptCall" size="sm" variant="success">ACCEPT</b-button>
      <b-button  @click="declineCall" size="sm" variant="danger" class="ml-4">DECLINE</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewClientCallToast',
  props: {
    phoneNumber: {
      type: String,
      required: true
    }
  },
  methods: {
    acceptCall () {
      this.$refs.acceptBtn.disabled = true
      this.$emit('accept-call', { phoneNumber: this.phoneNumber })
    },
    declineCall () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
