import Vue from 'vue'

const eventBus = {}

eventBus.handlers = []

/**
 * @param {string} event
 * @param {function} handler
 * @param {string} handlerKey
 */
eventBus.addEventListener = function (event, handler, handlerKey) {
  if (typeof this.handlers[event] === 'undefined') {
    this.handlers[event] = []
  }
  this.handlers[event][handlerKey] = handler
}

/**
 * @param {string} event
 * @param {string} handlerKey
 */
eventBus.removeEventListener = function (event, handlerKey) {
  if (typeof this.handlers[event] !== 'undefined' && typeof this.handlers[event][handlerKey] !== 'undefined') {
    delete this.handlers[event][handlerKey]
  }
}

/**
 * @param {string} event
 * @param {any} payload
 */
eventBus.triggerEvent = function (event, payload) {
  if (typeof this.handlers[event] !== 'undefined') {
    for (const handlerKey in this.handlers[event]) {
      this.handlers[event][handlerKey](payload)
    }
  }
}

Vue.prototype.$eventBus = eventBus
