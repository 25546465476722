<!--suppress EqualityComparisonWithCoercionJS -->
<template>
  <div>
    <audio id="sip-audio" autoplay="autoplay" muted></audio>
  </div>
</template>

<script>
import NewClientCallToast from '@/components/common/NewClientCallToast'
import config from '@/config'
import store from '@/store'
import { format as formatPhone } from '@/services/sipPhoneFormatter'
import jsSip from '@/services/jsSip'
import axios from 'axios'
import circularJSON from 'circular-json'

export default {
  name: 'DispatcherEvents',
  mounted () {
    this.slackWebhookInterval = setInterval(() => {
      try {
        axios.post('https://hooks.slack.com/services/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', {
          text: '==================================================== \n' + circularJSON.stringify(this.$store.state) + '\n|\n|\nMemory usage: ' + JSON.stringify(window.performance.memory.usedJSHeapSize) + '|\n'
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
      } catch (e) {
        console.log('Slack log failed', e)
      }
    }, 60000)
    this.$connect(config.wsUrl + '?token=' + store.getters['auth/apiToken'])
    this.$options.sockets.onmessage = (data) => {
      const message = JSON.parse(data.data)
      this.$eventBus.triggerEvent('ws_' + message.event, message.payload)
    }

    // comment old call toasts
    // this.$eventBus.addEventListener('sip_new_call', (data) => {
    // if (this.$route.name !== 'OrdersList') {
    // this.showNewCallToast(data)
    // }
    // }, 'showNewCallToast')

    this.$eventBus.addEventListener('ws_client_call_accepted', (data) => {
      this.$bvToast.hide('new-call-toast-' + data.phoneNumber)
      this.$store.commit('sip/removeCall', {
        phoneNumber: data.phoneNumber
      })
    }, 'clientCallAccepted')

    this.$eventBus.addEventListener('ws_order_canceled', (data) => {
      if (data.order.state === 'canceled' && this.$route.name !== 'OperatorOrderView' && this.$route.name !== 'OrdersList') {
        if (data.reason === 'no_drivers_found') {
          this.$toasted.show('Șoferii nu au fost găsiți', {
            duration: 60000,
            action: [
              {
                text: 'Comanda',
                push: {
                  path: '/operator/order/' + data.order.id
                }
              },
              {
                text: 'Închide',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                }
              }
            ]
          })
        }
        if (data.reason === 'dispatcher_canceled') {
          if (data.order.cancellation_reason_type === 'call_ended' && data.order.cancellation_reason_text === 'Client finished call') {
            this.$toasted.show('Сlientul a încheiat apelul', {
              duration: 5000,
              action: [
                {
                  text: 'Comanda',
                  push: {
                    path: '/operator/order/' + data.order.id
                  }
                },
                {
                  text: 'Închide',
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0)
                  }
                }
              ]
            })
          }
        }
      }
    }, 'noDriversListener')

    // this.initSipml()
    jsSip.init({
      onConnected: this.onSipConnected,
      onDisconnect: this.onSipDisconnect,
      onRegistrationFailed: this.onSipRegistrationFailed,
      onNewCall: this.notifyAboutNewCall,
      onCallEnded: this.onSipCallEnded,
      onCallFailed: this.onSipCallFailed
    })
  },
  beforeDestroy () {
    this.$disconnect()
    // this.$eventBus.removeEventListener('sip_new_call', 'showNewCallToast')
    this.$eventBus.removeEventListener('ws_client_call_accepted', 'clientCallAccepted')
    this.$eventBus.removeEventListener('ws_order_canceled', 'noDriversListener')
    clearInterval(this.slackWebhookInterval)
  },
  data () {
    return {
      sipStack: null,
      slackWebhookInterval: null
    }
  },
  methods: {
    onSipConnected () {
      this.$store.state.sip.isSipConnected = true
    },
    onSipDisconnect () {
      this.$store.state.sip.isSipConnected = false
    },
    onSipRegistrationFailed () {
      this.$bvToast.toast('Can not connect to SIP server. Please check your network connection or SIP credentials', {
        title: 'SIP is disabled',
        variant: 'danger',
        noAutoHide: true
      })
    },
    onSipCallEnded (e) {
      const reason = e.originator === 'remote' ? 'Client finished call' : 'Dispatcher finished call'
      if (e.originator === 'remote') {
        this.cancelCall(formatPhone(e.message.headers.From[0].parsed._uri._user), 'call_ended', reason)
      }
    },
    onSipCallFailed (e) {
      console.log('Call failed', e)
      const phoneNumber = formatPhone(e.message.headers.From[0].parsed._uri._user)
      this.$bvToast.hide('new-call-toast-' + phoneNumber)
      this.$store.commit('sip/removeCall', {
        phoneNumber: phoneNumber
      })
    },
    notifyAboutNewCall (phoneNumber, callSession) {
      this.$bvToast.hide('new-call-toast-' + phoneNumber)

      const payload = {
        phoneNumber: phoneNumber,
        callSession: callSession,
        orderId: null,
        acceptStarted: false,
        acceptFinished: false
      }

      this.$store.commit('sip/addCall', payload)
      this.$eventBus.triggerEvent('sip_new_call', payload)

      const sound = localStorage.getItem('default_sound') ? localStorage.getItem('default_sound') : '/audio/sound.wav'

      const audio = new window.Audio(sound)
      audio.muted = true
      audio.muted = false
      audio.play()
    },
    cancelCall (phoneNumber, reasonType, reasonText) {
      const orderId = this.$store.state.sip.calls[phoneNumber].orderId
      this.$store.commit('sip/removeCall', {
        phoneNumber: phoneNumber
      })
      this.$store.dispatch('sip/callEnded', {
        phoneNumber: phoneNumber,
        reasonType: reasonType,
        reasonText: reasonText
      })
        .then(() => {
          this.$eventBus.triggerEvent('remove_live_order', {
            orderId: orderId
          })
          // eslint-disable-next-line eqeqeq
          if (this.$router.currentRoute.name === 'OperatorOrderView' && orderId == this.$router.currentRoute.params.id) {
            // this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: orderId })
          }
        })
      this.$bvToast.hide('new-call-toast-' + phoneNumber)
    },
    showNewCallToast (data) {
      const toastId = 'new-call-toast-' + data.phoneNumber
      const self = this
      const comp = self.$createElement(NewClientCallToast, {
        props: {
          phoneNumber: data.phoneNumber
        },
        on: {
          'close' () {
            self.$bvToast.hide(toastId)
          },
          'accept-call' (payload) {
            for (const phone in self.$store.state.sip.calls) {
              if (self.$store.state.sip.calls[phone].callSession._is_confirmed === true) {
                if (payload.phoneNumber !== phone && self.$store.state.sip.calls[phone].callSession.isOnHold().local === false) {
                  self.$store.commit('dispatcherDashboard/markOrderAsHold', {
                    orderId: self.$store.state.sip.calls[phone].orderId
                  })
                  self.$store.state.sip.calls[phone].callSession.hold(self.$store.getters['auth/useSipUpdate'])
                }
              }
            }
            self.$store.state.sip.calls[payload.phoneNumber].callSession.answer(jsSip.answerOptions)
            self.$bvToast.hide(toastId)
          }
        }
      })
      self.$bvToast.toast([comp], {
        id: toastId,
        title: false,
        solid: true,
        variant: 'info',
        appendToast: true,
        noCloseButton: true,
        noAutoHide: true,
        toaster: 'b-toaster-bottom-right'
      })
    }
  }
}
</script>

<style scoped>

</style>
