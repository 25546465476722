<template>
  <div >
    <div v-for="(item, index) in items" :key="'li-' + index" class="px-1 mb-2">
      <strong>
        Căutare {{ index+1 }}:
        <span v-if="item.params.search_order_by === 'by_idle_from'">în zonă</span>
        <span v-else-if="item.params.search_type === 'by_near_zone'">în zonă apropiată</span>
        <span v-else-if="item.params.search_order_by === 'by_route_distance'">cel mai apropiat</span>
        <span v-else-if="item.params.search_order_by === 'any'">alertă</span>
      </strong>
        <div class="orderDrivers">
            <div class="item"  :class="{'bg-success text-light': driver.accepted, 'border-light': !driver.accepted, 'border-success': driver.viewed, 'border-light': !driver.viewed, 'mx-1': true}" v-for="(driver, index) in item.drivers" :key="'di' + index">
              <strong>{{ driver.driver.identifier }}</strong>
              <div>
                <b-badge :class="{'bg-success': driver.viewed, 'bg-secondary': !driver.viewed, 'mx-1': true}">V</b-badge>
                <b-badge :class="{'bg-success': driver.accepted, 'bg-secondary': !driver.accepted}">A</b-badge>
              </div>
            </div>
          </div>
    <hr>
    </div>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'OrderDrivers',
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      items: []
    }
  },
  mounted () {
    this.onOrderChanged(this.orderId, null)
  },
  methods: {
    onOrderChanged (newId, oldId) {
      if (oldId) {
        this.$eventBus.removeEventListener('ws_order_drivers:' + oldId, 'order_drivers')
      }
      this.$eventBus.addEventListener('ws_order_drivers:' + newId, (data) => {
        this.items = data.searches
      }, 'order_drivers')
      this.fetchData()
    },
    fetchData () {
      this.$axios.get(config.wsApiUrl + '/order-drivers', {
        params: {
          orderId: this.orderId
        }
      })
        .then((response) => {
          if (response.data) {
            this.items = response.data
          }
        })
    }
  },
  watch: {
    orderId (newId, oldId) {
      this.onOrderChanged(newId, oldId)
    }
  }
}
</script>

<style scoped>

</style>
