<template>
  <div class="order-address" >
    <v-select
      ref="select"
      :options="uniqueSearchResults"
      :filterable="false"
      append-to-body
      :clearable="false"
      :calculate-position="withPopper"
      @search="onSearch"
      @input="fetchPlaceDetails($event)"
      :value="orderPlace.name"
      :disabled="disabled"
      :key="'pvs-' + orderPlace.order_id"
      @open="$store.state.dispatcherDashboard.selectOpen = true"
      @close="onCloseSelect"
    >
      <template #no-options="">
        Introduce-ți adresa (ex.: 13 Calea București)
      </template>
      <template #option="option" v-shortkey.avoid>
        {{ option.label }} <template v-if="option.comment"> - {{ option.comment }}</template>
      </template>
    </v-select>
  </div>
</template>

<script>
import _ from 'lodash'
import config from '@/config'
import vSelect from 'vue-select'
import { createPopper } from '@popperjs/core'

export default {
  name: 'AddressSearch',
  components: {
    vSelect
  },
  props: {
    orderPlace: {
      type: Object,
      required: true
    },
    clientId: {
      type: Number,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loadUserAddresses: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchResults: [],
      selectTitle: 'Adresa ...'
    }
  },
  mounted () {
    if (this.loadUserAddresses && this.clientId) {
      this.getClientAddressesHistory()
    } else {
      if (this.orderPlace.place_id) {
        this.searchResults = [{
          id: this.orderPlace.place_id,
          label: this.orderPlace.name
        }]
      }
    }
  },
  computed: {
    searchInputProps () {
      const props = { class: 'form-control form-control-sm', style: 'width: 400px' }
      if (this.isInputDisabled) {
        props.disabled = 'disabled'
      }
      return props
    },
    uniqueSearchResults () {
      const results = {}
      this.searchResults.forEach((address) => {
        results[address.id] = address
      })
      console.log(results)
      return Object.entries(results).map((item) => {
        return item[1]
      })
    }
  },
  methods: {
    onCloseSelect () {
      // this.$nextTick(() => { this.$store.state.dispatcherDashboard.selectOpen = false })
      setTimeout(() => { this.$store.state.dispatcherDashboard.selectOpen = false }, 500)
    },
    optionSelecting (e) {
      console.log(arguments)
    },
    onSearch (search) {
      if (search.length) {
        this.search = search
        this.apiSearch(search, this)
      }
    },
    apiSearch: _.debounce((search, vm) => {
      vm.$axios.get(config.addrApiUrl + '/places-search?input=' + search + '&language=ro&components=country:ro&locationbias=circle:25000@' + config.mapCenter.lat + ',' + config.mapCenter.lng)
        .then((response) => {
          vm.searchResults = response.data.predictions.map((item) => {
            return {
              id: item.place_id,
              label: item.description,
              comment: null
            }
          })
        })
    }, 500),
    fetchPlaceDetails (item, placeName) {
      this.$axios.get(config.addrApiUrl + '/place-details?place_id=' + item.id + '&fields=geometry,formatted_address,address_component,name&language=ro')
        .then((response) => {
          this.placeChanged(response.data.result, item.id, item.label, item.comment)
        })
    },
    placeChanged (place, placeId, placeName, comment) {
      if (this.inUpdate) {
        this.$store.dispatch('dispatcherDashboard/updateOrderPlace', {
          orderPlace: {
            id: this.orderPlace.id,
            order_id: this.orderPlace.order_id,
            name: this._formatGooglePlaceName(place, placeName),
            lat: place.geometry.location.lat,
            long: place.geometry.location.lng,
            place_id: placeId,
            comment: comment
          }
        })
          .then(() => {
            this.changePlaceInOrder(place, placeId, placeName, comment)
            this.$toasted.success('Adresa actualizata')
          })
          .catch(() => {
            this.$toasted.error('Adresa invalida')
          })
          .then(() => {
            this.inUpdate = false
          })
      } else {
        this.changePlaceInOrder(place, placeId, placeName, comment)
      }
    },
    changePlaceInOrder (place, placeId, placeName, comment) {
      this.orderPlace.name = this._formatGooglePlaceName(place, placeName)
      this.orderPlace.lat = place.geometry.location.lat
      this.orderPlace.long = place.geometry.location.lng
      this.orderPlace.place_id = placeId
      this.orderPlace.comment = comment
      if (this.orderPlace.order_id) {
        this.$axios.patch(config.baseApiUrl + '/api/orders/' + this.orderPlace.order_id + '/places/' + this.orderPlace.id, this.orderPlace)
      }
    },
    _formatGooglePlaceName (place, placeName) {
      let isPremise = false
      let premise = ''
      let streetNumber = ''
      let isStreetNumber = false
      let street = ''
      let city = ''
      place.address_components.forEach((item) => {
        if (item.types.includes('premise')) {
          isPremise = true
          premise = item.long_name
        } else if (item.types.includes('street_number')) {
          streetNumber = item.long_name
          isStreetNumber = true
        } else if (item.types.includes('route')) {
          street = item.long_name
        } else if (item.types.includes('locality')) {
          city = item.long_name
        }
      })

      if (!isStreetNumber) {
        return placeName
      }

      const parts = []
      if (isPremise) {
        parts.push(premise)
      } else {
        parts.push(street + ' ' + streetNumber)
      }
      if (city) {
        parts.push(city)
      }
      return ((street + ' ' + streetNumber) === place.name ? '' : '(' + place.name + ') ') + parts.join(', ')
    },
    getClientAddressesHistory () {
      this.searchResults = []
      this.$axios.get(config.baseApiUrl + '/api/dispatcher-orders/' + this.clientId + '/address-history')
        .then((response) => {
          this.searchResults = response.data.map((address) => {
            return {
              id: address.place_id,
              label: address.name,
              comment: address.comment
            }
          })
        })
    },
    withPopper (dropdownList, component, { width }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle(
                'drop-bottom',
                state.placement === 'bottom'
              )
            }
          }
        ]
      })
      return () => popper.destroy()
    }
  },
  watch: {
    'orderPlace.order_id' () {
      if (this.loadUserAddresses && this.clientId) {
        this.getClientAddressesHistory()
      } else {
        if (this.orderPlace.place_id) {
          this.searchResults = [{
            id: this.orderPlace.place_id,
            label: this.orderPlace.name
          }]
        }
      }
      this.$nextTick(() => {
        this.$refs.select.$refs.search.focus()
      })
    }
  }
}
</script>

<style>
  .vs__selected-options {
    width: 450px;
  }
</style>
