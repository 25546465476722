<template>
  <div class="card">
    <GmapMap
      ref="map"
      :center="mapCenter"
      :options="{ mapTypeControl: false, streetViewControl: false }"
      :zoom="17"
      style="width: 100%; height:500px;"
    >
      <GmapMarker
        :position="driverLocation"
        :clickable="false"
        :draggable="false"
        :icon="driverIcon"
        :label="{color:'#fff', fontSize:'19px',fontWeight:'bold', text: 'Ș'}"
      />
      <GmapMarker
        :position="clientLocation"
        :clickable="false"
        :draggable="false"
        :icon="clientIcon"
        :label="{color:'#fff', fontSize:'19px',fontWeight:'bold', text: 'C'}"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: 'OrderLocalisation',
  components: {},
  data () {
    return {
      zoom: 13,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: false
    }
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  methods: {
    zoomUpdate (zoom) {
      this.currentZoom = zoom
    },
    centerUpdate (center) {
      this.currentCenter = center
    },
    showLongText () {
      this.showParagraph = !this.showParagraph
    },
    innerClick () {
      alert('"Click!"')
    }
  },
  computed: {
    mapCenter () {
      return {
        lat: this.order.driver.current_location.coordinates[1],
        lng: this.order.driver.current_location.coordinates[0]
      }
    },
    driverLocation () {
      return {
        lat: this.order.driver.current_location.coordinates[1],
        lng: this.order.driver.current_location.coordinates[0]
      }
    },
    clientLocation () {
      return {
        lat: this.order.places[0].lat,
        lng: this.order.places[0].long
      }
    },
    driverIcon () {
      return {
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 18,
        fillColor: 'red',
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: 'white'
      }
    },
    clientIcon () {
      return {
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 18,
        fillColor: 'green',
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: 'white'
      }
    }
  }
}
</script>

<style scoped>

</style>
