<template>
  <div>
    Redirectionare ...
  </div>
</template>

<script>
export default {

  name: 'AppDownload',
  mounted () {
    if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.cluj.taxi'
    }
    if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
      window.location.href = 'https://apps.apple.com/ro/app/cluj-taxi/id1585849176'
    }
  }

}
</script>
