<template>
  <div>
    <div class="orderHeader" v-if="order.id">
      <h1>Comanda <strong>#{{ order.id }}</strong></h1>
      <b-badge v-if="(order.state !== 'pending' || order.state !== 'created') && order.in_pending_queue === true" variant="info">Alertă</b-badge>
      <span v-if="order.state === 'created'" style="position: relative; top: -3px;" class="badge badge-warning">nouă</span>
      <span v-if="order.state === 'assigned'" style="position: relative; top: -3px;" class="badge badge-success">alocată</span>
      <span v-if="order.state === 'finished'" style="position: relative; top: -3px;" class="badge badge-success">finalizată</span>
      <span style="position: relative; top: -3px;" class="badge badge-dark">{{ order.payment_type }} <strong v-if="order.state === 'finished' && order.payment_type === 'card'">: {{ order.cost }} RON</strong></span>
      <b-badge v-if="order.trip_state === 'waiting_driver'" variant="warning" >spre client</b-badge>
              <b-badge v-if="order.trip_state === 'waiting_client'" variant="info">șoferul a sosit</b-badge>
              <b-badge v-else-if="order.trip_state === 'started'" variant="primary">șoferul a început cursa</b-badge>
      <template v-if="order.state === 'canceled'">
        <span style="position: relative; top: -3px;" class="badge badge-danger">
          anulată
        </span>
        <span class="p-0" v-if="order.cancellation_reason_text === 'Client finished call'">
          Сlientul a încheiat apelul
        </span>
        <span class="p-0" v-if="order.cancellation_reason_type === 'driver_canceled' && canceledDriver">
          Sofer &nbsp;<b>{{ canceledDriver.default_car ? canceledDriver.default_car.indicativ : 'xxx' }}</b>&nbsp; a anulat comanda: {{ this.order.cancellation_reason_text.split('~')[1] }}
        </span>
        <span class="p-0" v-else>
          Operatorul a anulat comanda
        </span>
      </template>

      <div v-if="orderCallSession" class="orderPhone">
        <b-button :disabled="callInUpdate" @click="holdCall" v-if="!orderCallSession.isOnHold().local" size="sm" variant="warning">
          <b-icon icon="pause-circle"></b-icon>
        </b-button>
        <b-button :disabled="callInUpdate" @click="unholdCall" v-if="orderCallSession.isOnHold().local" size="sm" variant="success">
          <b-icon icon="play-circle"></b-icon>
        </b-button>
        <b-button :disabled="callInUpdate" @click="endCall"  id="cancel-call" size="sm" variant="danger">
          <b-icon icon="stop-circle"></b-icon>
        </b-button>
        <b-button size="sm" :disabled="true">
          {{ callDuration }}
        </b-button>
      </div>
    </div>
    <div class="px-2 py-3">
      <b-form>
        <b-row no-gutters>
          <b-col md="12" class="px-2">
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="12">
                    <OrderPlaces :order="order"></OrderPlaces>
                  </b-col>
                  <b-col md="12">
                    <b-collapse  v-model="isCommentVisible" class="mb-3" id="preluare-detalii">
                      <OrderPlaceComment @commentAddons="setCommentAddons = $event" :order="order">
                      </OrderPlaceComment>
                    </b-collapse>
                  </b-col>
                </b-row>
                <div class="clearfix"></div>
                <b-collapse v-if="false && !isFulfilledOrder && order.client_id" class="mt-3" id="istoric-add">
                  <ClientAddressesHistory @selected="setStartLocation" :user-id="order.client_id"></ClientAddressesHistory>
                </b-collapse>
              </b-col>
            </b-row>
            <b-row >
              <b-col md="12">
                <b-input-group style="flex-wrap: nowrap;">
                <b-form-input
                  ref="clientPhone"
                  v-model="clientPhone"
                  id="input-1"
                  type="text"
                  placeholder="Telefon"
                  required
                  autocomplete="off"
                  style="width:300px; margin-right:-1px;"
                  :disabled="isFulfilledOrder"
                ></b-form-input>
                <OrderClientNameField
                class="custom-client-name"
                  v-model="clientName"
                  :about="aboutClient"
                  :is-fulfilled-order="isFulfilledOrder"
                  @about-changed="order.about_client = $event"
                  style="width: 70%"
                ></OrderClientNameField>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col md="3" class="d-none">
                <b-form-select
                  v-model="order.service_id"
                  id="input-3"
                  :disabled="isFulfilledOrder"
                  v-if="Array.isArray(services)"
                >
                  <b-form-select-option v-for="service in services" :value="service.id" :key="'so-' + service.id">
                    {{ service.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
              <b-col md="12">
                <b-row v-if="!isFulfilledOrder">
                  <b-col md="8">
                    <b-input-group class="mb-2">
                      <b-input-group-prepend is-text>
                        <label class="m-0">
                          <input v-model="isCustomOrderTime" type="checkbox" aria-label="Is not now"> &nbsp;
                          <b-icon icon="gear"></b-icon>
                        </label>
                      </b-input-group-prepend>
                      <b-form-datepicker :disabled="isFulfilledOrder || !isCustomOrderTime" id="input-datepicker" locale="ro" v-model="orderDate"></b-form-datepicker>
                    </b-input-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-timepicker :disabled="isFulfilledOrder || !isCustomOrderTime" locale="ro" v-model="orderTime" show-seconds></b-form-timepicker>
                  </b-col>
                </b-row>
                <div v-else>
                  <b-input-group class="mb-2">
                    <b-input-group-prepend is-text>
                      <b-icon-calendar-range></b-icon-calendar-range>
                    </b-input-group-prepend>
                    <b-form-input :disabled="true" :value="stringifiedOrderDate"></b-form-input>
                  </b-input-group>
                </div>
              </b-col>
            </b-row>

            <b-row class=" mt-2 mb-3 align-items-center d-none" >
              <b-col md="2">
                <b-form-checkbox v-model="isProtocol" :disabled="isFulfilledOrder" style="font-size: 17px;">Protocol
                </b-form-checkbox>
              </b-col>
              <b-col md="2">
                <UserSelect v-if="isProtocol" :disabled="isFulfilledOrder" v-model="order.client.protocol_parent_user" :multiple="false" :roles="['company']"></UserSelect>
              </b-col>
              <b-col md="4">
                <CompanySelect v-model="order.search_company_ids" :multiple="true"></CompanySelect>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <b-col md="8" class="deliveryBtn mt-2 align-items-center">
                <div class="buttons">
                  <b-form-group
                    label="Ridicare în:"
                    v-slot="{ ariaDescribedby }"
                    class="w-100"
                  >
                    <b-form-radio-group :disabled="                   isCustomOrderTime"
                      class="w-100 "
                      id="btn-radios-2"
                      v-model="selected"
                      :options="orderReq"
                      :aria-describedby="ariaDescribedby"
                      button-variant="outline-secondary"
                      size="lg"
                      name="radio-btn-outline"
                      buttons
                    ></b-form-radio-group>
                  </b-form-group>
                </div>
              </b-col>
              <b-col md="9" class="order-time-request mt-2 align-items-center d-none">
                <div class="buttons">
                  <b-form-group
                    label="Livrare în:"
                    v-slot="{ ariaDescribedby }"
                    class="w-100"
                  >
                    <b-form-radio-group
                      class="w-100"
                      id="btn-radios-2"
                      v-model="selected"
                      :options="orderDelivery"
                      :aria-describedby="ariaDescribedby"
                      button-variant="outline-dark"
                      size="lg"
                      name="radio-btn-outline"
                      buttons
                    ></b-form-radio-group>
                  </b-form-group>
                </div>
              </b-col>
              <b-col class="order-time-request mt-2 align-items-center d-flex p-1">
              <b-button-group v-if="!isFulfilledOrder" class="w-100" style="z-index: 2; height: 100%;">
                <b-button
                  :disabled="isCustomDriver || inProgress"
                  @click="updateOrder"
                  v-shortkey="['enter']"
                  @shortkey="updateOrder"
                  variant="success" block
                  class="text-uppercase font-weight-bolder"
                >
                  Plasează comandă
                </b-button>
              </b-button-group>
              </b-col>
            </b-row>
            <div :class="canCancelOrder ? 'order-buttons mt-2' : 'mt-2'">
              <b-button v-if="canCancelOrder" @click="cancelOrder" size="md" variant="danger">
                <b-icon icon="stop-fill"></b-icon>
                Anulează comanda
              </b-button>
              <b-button v-if="order.state === 'canceled' || order.state === 'pending'" @click="retryOrder" size="md" variant="info" >
                <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
                Încearcă din nou
              </b-button>
              <b-button v-if="order.state !== 'new'" @click="duplicateOrder" size="md" variant="outline-dark" >
                <b-icon icon="front"></b-icon>
                Dublează comanda
              </b-button>
              <b-button v-if="order.state === 'canceled' || order.state === 'pending'" v-b-modal.assign-and-finish size="md" variant="outline-success">
                <b-icon-file-earmark-person-fill></b-icon-file-earmark-person-fill>
                Indică manual
              </b-button>
              <b-modal v-if="order.state === 'canceled' || order.state === 'pending'" id="assign-and-finish" hide-footer>
                <template #modal-title>
                  Indică manual și confirmă
                </template>
                <div class="d-block text-center">
                  <UserSelect v-model="driverToAssign" :multiple="false" :roles="['driver']"></UserSelect>
                </div>
                <b-button variant="success" class="mt-3" block @click="assignDriverAndFinish">Finalizează</b-button>
              </b-modal>
              <b-collapse id="indicativ-collapse" v-model="isCustomDriver">
                <div class="row p-3 pt-4">
                  <div class="col-12">
                    <b-input-group size="lg">
                      <UserSelect
                        v-model="order.custom_driver_ids"
                        :roles="['driver']"
                        :multiple="true"
                        :disabled="isFulfilledOrder"
                      ></UserSelect>
                      <b-input-group-append>
                        <b-button :disabled="isFulfilledOrder" @click="notifyDrivers" variant="success" class="shadow-none">Notify driver</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </div>
              </b-collapse>
            </div>
          </b-col>
          <b-col  class="px-2 d-none">
            <b-card v-if="potentialDriver" header="Potential driver" header-tag="header">
              {{ potentialDriver.email }}
              <span v-if="potentialDriver.default_car">
                ({{ potentialDriver.default_car.indicativ }})
              </span>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
      <b-button v-if="false" class="my-5" variant="outline-success" :to="{ name: 'OperatorOrderView', params: { id: 0 } }">
        <b-icon icon="plus"></b-icon>
        Comandă nouă
      </b-button>
    </div>

    <DriversMap />
  </div>
</template>

<script>
import OrderPlaces from '@/components/dashboard/Delivery/OrderPlaces'
import VeeValidate from 'vee-validate'
import Vue from 'vue'
import config from '@/config'
import ClientAddressesHistory from '@/components/dashboard/Delivery/ClientAddressesHistory'
import OrderClientNameField from '@/components/dashboard/Delivery/OrderClientNameField'
import UserSelect from '@/components/common/UserSelect'
import OrderPlaceComment from '@/components/dashboard/Delivery/OrderPlaceComment'
import DriversMap from '@/components/dashboard/Delivery/DriversMap'
import CompanySelect from '@/components/common/CompanySelect'

Vue.use(VeeValidate, {
  fieldsBagName: '$fields'
})

export default {
  name: 'OrderForm',
  components: {
    CompanySelect,
    OrderPlaceComment,
    UserSelect,
    OrderClientNameField,
    ClientAddressesHistory,
    OrderPlaces,
    DriversMap
  },
  data () {
    return {
      dismissSecs: 2,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      driverFinded: 0,
      isCustomOrderTime: false,
      callInUpdate: false,
      refreshInterval: null,
      isCustomDriver: false,
      potentialDriver: null,
      driverToAssign: null,
      isProtocol: false,
      canceledDriver: null,
      inProgress: false,
      isCommentVisible: true,
      setCommentAddons: '',
      orderReq: [
        { text: '5', value: '5' },
        { text: '15', value: '15' },
        { text: '30', value: '30' },
        { text: '45', value: '45' },
        { text: '60', value: '60' },
        { text: '90', value: '90' }
      ],
      orderDelivery: [
        { text: '5', value: '5' },
        { text: '15', value: '15' },
        { text: '30', value: '30' },
        { text: '45', value: '45' },
        { text: '60', value: '60' },
        { text: '90', value: '90' }
      ]
    }
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this.refreshInterval = setInterval(() => {
      if (this.$store.getters['auth/loggedUser']) {
        this.order.dispatcher_id = this.$store.getters['auth/loggedUser'].id
        if (!this.isCustomOrderTime) {
          this.order.departure_time = this.$day().utc().format('YYYY-MM-DD HH:mm:ss')
        }
      }
      if (this.order.service_id === null) {
        this.order.service_id = 1
      }
      if (this.$store.state.auth.user.companies && this.order.search_company_ids === null) {
        this.order.search_company_ids = this.$store.state.auth.user.companies.map(company => {
          return company.id
        })
      }
    }, 1000)

    this.$eventBus.addEventListener('ws_order_driver_found', (data) => {
      if (data.order.id === this.order.id) {
        this.potentialDriver = null
        this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: this.order.id })
      }
    }, 'orderForm')

    this.$eventBus.addEventListener('ws_order_canceled', (data) => {
      if ((data.order.state === 'canceled' || data.order.state === 'pending') && data.order.id === this.order.id) {
        this.potentialDriver = null

        if (data.reason !== 'dispatcher_canceled') {
          this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: this.order.id })
        } else if (this.order.call) {
          this.order.call.state = 'ended'
        }

        if (data.reason === 'no_drivers_found') {
          this.$toasted.show('Șoferii nu au fost găsiți', {
            duration: 3000
          })
        }
        if (data.reason === 'dispatcher_canceled') {
          if (data.order.cancellation_reason_type === 'call_ended' && data.order.cancellation_reason_text === 'Client finished call') {
            this.$toasted.show('Сlientul a încheiat apelul', {
              duration: 3000
            })
          }
        }
      }
    }, 'orderForm2')

    this.$eventBus.addEventListener('ws_order_updated', (data) => {
      if (data.order.id === this.order.id && (data.order.state === 'finished' || data.order.state === 'assigned')) {
        this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: this.order.id })
      }
    }, 'orderForm3')

    this.$eventBus.addEventListener('ws_order_sent_to_driver', (data) => {
      if (data.order.id === this.order.id) {
        this.potentialDriver = data.driver
      }
    }, 'orderForm4')

    if (Array.isArray(this.order.custom_driver_ids) && this.order.custom_driver_ids.length > 0) {
      this.isCustomDriver = true
    }

    this.isProtocol = this.order.payment_type === 'protocol'

    if (this.order.cancellation_reason_type === 'driver_canceled') {
      this.$axios.get(config.baseApiUrl + '/api/users/' + this.order.cancellation_reason_text.split('~')[0])
        .then((response) => {
          this.canceledDriver = response.data
        })
    }
  },
  beforeDestroy () {
    this.$eventBus.removeEventListener('ws_order_driver_found', 'orderForm')
    this.$eventBus.removeEventListener('ws_order_canceled', 'orderForm2')
    this.$eventBus.removeEventListener('ws_order_updated', 'orderForm3')
    this.$eventBus.removeEventListener('ws_order_sent_to_driver', 'orderForm4')
    clearInterval(this.refreshInterval)
  },
  computed: {
    clientPhone: {
      get () {
        return this.order.client ? this.order.client.phone : ''
      },
      set (value) {
        this.order.phone = value
      }
    },
    clientName: {
      get () {
        return this.order.client && this.order.client.name ? this.order.client.name : ''
      },
      set (value) {
        if (this.order.client) {
          this.order.client.name = value
          this.order.name = value
        } else {
          this.order.name = value
        }
      }
    },
    aboutClient () {
      return this.order.client ? this.order.client.about : ''
    },
    orderDate: {
      get () {
        return this.isCustomOrderTime ? this.$day.utc(this.order.created_at).local().format('YYYY-MM-DD') : this.$day().local().format('YYYY-MM-DD')
      },
      set (value) {
        const date = value ? this.$day(value).utc() : this.$day().utc()
        date.$H = parseInt(this.$day().local().format('HH'))
        date.$m = parseInt(this.$day().local().format('mm'))
        date.$s = parseInt(this.$day().local().format('ss'))
        this.order.created_at = date.utc().format('YYYY-MM-DD HH:mm:ss')
        this.order.departure_time = date.utc().format('YYYY-MM-DD HH:mm:ss')
        this.$forceUpdate()
      }
    },
    orderTime: {
      get () {
        return this.isCustomOrderTime ? this.$day.utc(this.order.created_at).local().format('HH:mm:ss') : this.$day().local().format('HH:mm:ss')
      },
      set (value) {
        const parts = this.order.created_at.split(' ')
        this.order.created_at = parts[0] + ' ' + value
        this.order.departure_time = parts[0] + ' ' + value
      }
    },
    services () {
      return this.$store.getters['dispatcherDashboard/getServices']
    },
    isFulfilledOrder () {
      return this.order.state !== 'new'
    },
    canCancelOrder () {
      return ['created', 'assigned'].includes(this.order.state)
    },
    stringifiedOrderDate () {
      return this.$day.utc(this.order.created_at).local().format('DD MMM YYYY HH:mm:ss')
    },
    callDuration () {
      return this.$day.duration(
        this.$store.state.now.diff(
          this.$day.utc(this.order.call.started_at).local()
        )
      ).format('mm:ss')
    },
    orderCallSession () {
      if (typeof this.$store.state.sip.calls[this.clientPhone] !== 'undefined') {
        return this.$store.state.sip.calls[this.clientPhone].callSession
      }
      return null
    }
  },
  methods: {
    updateOrder () {
      if (this.$store.state.dispatcherDashboard.selectOpen) return
      this.inProgress = true
      // eslint-disable-next-line eqeqeq
      const action = this.$route.params.id != '0' ? 'dispatcherDashboard/updateOrder' : 'dispatcherDashboard/createOrder'
      // this.order.places[0].comment += ' -- ' + this.setCommentAddons.filter((i) => i !== '').join(', ')
      this.$store.dispatch(action)
        .then((response) => {
          if (response.data.call && this.$store.state.sip.calls[response.data.call.phone] && this.$store.state.sip.calls[response.data.call.phone].callSession.isOnHold().local === false) {
            this.$store.state.sip.calls[response.data.call.phone].callSession.hold(this.$store.getters['auth/useSipUpdate'])
          }
          this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: response.data.id })
            .then(() => {
              this.inProgress = false
              this.$toasted.success('Order created', {
                duration: 3000
              })
            })
        })
        .catch((error) => {
          Object.entries(error.response.data.errors).forEach(([key, group]) => {
            group.forEach((message) => {
              this.$toasted.error(message, {
                duration: 5000
              })
            })
          })
          this.inProgress = false
        })
    },
    cancelOrder () {
      this.$axios.patch(config.baseApiUrl + '/api/orders/' + this.order.id + '/cancel')
        .then(() => {
          this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: this.order.id })
          this.$toasted.success('Order canceled', {
            duration: 3000
          })
        })
    },
    holdCall () {
      this.callInUpdate = true
      this.$store.state.sip.calls[this.clientPhone].callSession.hold(this.$store.getters['auth/useSipUpdate'])
      this.callInUpdate = false
    },
    unholdCall () {
      this.callInUpdate = true
      for (const phone in this.$store.state.sip.calls) {
        if (phone !== this.clientPhone && this.$store.state.sip.calls[phone].callSession._is_confirmed && this.$store.state.sip.calls[phone].callSession.isOnHold().local === false) {
          this.$store.state.sip.calls[phone].callSession.hold(this.$store.getters['auth/useSipUpdate'])
        }
      }
      this.$store.state.sip.calls[this.clientPhone].callSession.unhold(this.$store.getters['auth/useSipUpdate'])
      this.callInUpdate = false
    },
    endCall () {
      this.callInUpdate = true
      this.$axios.patch(config.baseApiUrl + '/api/calls/cancel/', {
        phone_number: this.order.call.phone
      })
        .then(() => {
          this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: this.order.id })
          this.$store.state.sip.calls[this.clientPhone].callSession.terminate()
          this.$store.commit('sip/removeCall', {
            phoneNumber: this.order.call.phone
          })
        })
        .catch(() => {
        })
        .then(() => {
          this.callInUpdate = false
        })
    },
    setStartLocation (address) {
      this.order.places[0].name = address.name
      this.order.places[0].long = address.long
      this.order.places[0].lat = address.lat
      this.order.places[0].place_id = address.place_id
      this.order.places[0].comment = address.comment ? address.comment : null
      console.log('startlocation')
    },
    retryOrder () {
      this.$axios.patch(config.baseApiUrl + '/api/orders/' + this.order.id + '/retry')
        .then(() => {
          this.$store.dispatch('dispatcherDashboard/viewOrder', { orderId: this.order.id })
          this.$toasted.success('Сomanda retrimisa', {
            duration: 2000
          })
        })
    },
    duplicateOrder () {
      this.$axios.post(config.baseApiUrl + '/api/orders/' + this.order.id + '/duplicate')
        .then((response) => {
          this.$router.push('/operator/order/' + response.data.id)
          this.$toasted.success('Сomanda dublicata', {
            duration: 2000
          })
        })
    },
    notifyDrivers () {
      if (this.order.custom_driver_ids.length === 0) {
        this.$toasted.error('Vă rugăm să selectați soferi', {
          duration: 2000
        })
        return
      }

      this.updateOrder()
    },
    assignDriverAndFinish () {
      if (this.driverToAssign === null) {
        this.$toasted.error('Please select driver', {
          duration: 3000
        })
        return
      }

      this.$axios.patch(config.baseApiUrl + '/api/orders/' + this.order.id + '/set-driver-and-finish', {
        driverId: this.driverToAssign.id
      })
        .then(() => {
          this.$bvModal.hide('assign-and-finish')
          this.driverToAssign = null
        })
    }
  },
  watch: {
    isProtocol (value) {
      if (value) {
        this.order.payment_type = 'protocol'
      } else {
        this.order.payment_type = 'cash'
      }
    },
    order: {
      handler: function () {
        this.isProtocol = this.order.payment_type === 'protocol'
        if (this.order.cancellation_reason_type === 'driver_canceled') {
          this.$axios.get(config.baseApiUrl + '/api/users/' + this.order.cancellation_reason_text.split('~')[0])
            .then((response) => {
              this.canceledDriver = response.data
            })
        }
      },
      deep: true
    },
    'order.client.protocol_parent_user': function (protocolUser) {
      this.order.protocol_user = protocolUser
    },
    'order.places.0.comment': function (value) {
      this.isCommentVisible = !!value
    }
  }
}
</script>

<style scoped>
  #indicativ-collapse {
    border-right: 1px solid rgb(204, 204, 204);
    border-bottom: 1px solid rgb(204, 204, 204);
    border-left: 1px solid rgb(204, 204, 204);
    border-radius: 0 0 4px 4px;
    z-index: 1;
    border-top: none;
    margin-top: -4px;
  }
</style>
