<template>
  <div>
      <div role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class=" d-flex align-items-center" role="tab">
            <b-button size="sm" variant="light" class="mr-3" style="cursor:move">
              <b-icon icon="arrows-move"></b-icon>
            </b-button>
            <div>
              <strong>Interval {{ idx+1 }}</strong>
            </div>
            <b-button size="sm" class="mx-3" v-b-toggle="'interval-' + idx" variant="outline-info">Vezi condițiile <b-icon icon="arrow-down"></b-icon> </b-button>
            <b-form  @submit.prevent="save" class="col-8 p-0">
              <b-input-group size="sm" >
                <b-form-select class="col-4" size="sm" v-model="item.type" :options="select_type"></b-form-select>
                <b-form-timepicker v-if="item.type !== null" v-model="item.value.time_from" locale="ro" placeholder="De la"></b-form-timepicker>
                <b-form-timepicker v-if="item.type !== null" v-model="item.value.time_to" locale="ro" placeholder="Până la"></b-form-timepicker>
                <b-form-select v-if="item.type === 'weekdays'" size="sm" v-model="item.value.selected_day" :options="select_days"></b-form-select>
                <b-form-datepicker v-if="item.type === 'custom'" v-model="item.value.custom_day" locale="ro" placeholder="Zi specială"></b-form-datepicker>
                <template #append>
                  <b-button size="sm" type="submit" variant="success">Salvează</b-button>
                </template>
              </b-input-group>
            </b-form>
            <b-button size="sm" class="ml-auto px-2" variant="outline-danger"   @click="$emit('removeItem', item.id)"><b-icon icon="x"></b-icon> șterge</b-button>
          </b-card-header>
          <b-collapse :id="'interval-' + idx"  accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>Începe să <code>adaugi</code> și să <code>modifici</code> condițiile pentru intervalul selectat.</b-card-text>

              <BuilderItemParent :method="item.search_method"  :key="'condition-' + idx" />

            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
  </div>
</template>

<script>
import config from '@/config'
import BuilderItemParent from '@/components/settings/builder/BuilderItemParent'

export default {
  name: 'BuilderInterval',
  order: 5,
  components: {
    BuilderItemParent
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    idx: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      id: false,
      intervals: null,
      dragging: false,
      select_days: [
        { value: null, text: '- toate zilele' },
        { value: 1, text: 'Luni' },
        { value: 2, text: 'Marți' },
        { value: 3, text: 'Miercuri' },
        { value: 4, text: 'Joi' },
        { value: 5, text: 'Vineri' },
        { value: 6, text: 'Sâmbătă' },
        { value: 7, text: 'Duminică' }
      ],
      select_type: [
        { value: null, text: '- tip interval' },
        { value: 'weekdays', text: 'Zile din săptămână' },
        { value: 'custom', text: 'Personalizat' }
      ]
    }
  },
  computed: {
    draggingInfo () {
      return this.dragging ? 'under drag' : ''
    }
  },
  methods: {

    save () {
      this.$axios({
        method: this.$props.itemId === null ? 'post' : 'patch',
        url: config.baseApiUrl + '/api/search-intervals' + (this.$props.itemId === null ? '' : ('/' + this.item.id)),
        data: this.item
      })
        .then((response) => {
          this.$toasted.success('Salvat cu succes!', {
            duration: 3000
          })

          if (this.$props.itemId === null) {
            this.item = response.data
            this.itemId = response.data.id
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
<style scoped>
</style>
