<template>
  <VueSelect
  ref="usersInd"
  style="border:none;"
    :options="options"
    @search="onSearch"
    @input="$emit('input', $event)"
    :value="value"
    :get-option-label="getOptionLabel"
    :filterable="false"
    :multiple="multiple"
    :disabled="disabled"
    append-to-body
    :calculate-position="withPopper"
  ></VueSelect>
</template>

<script>
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import _ from 'lodash'
import config from '@/config'
import queryString from 'query-string'
import { createPopper } from '@popperjs/core'

export default {
  name: 'UserSelect',
  components: {
    VueSelect
  },
  props: {
    value: {
      default: ''
    },
    useIdentifier: {
      type: Boolean,
      default: false
    },
    roles: {
      type: Array,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'bottom'
    }
  },
  data () {
    return {
      options: [],
      search: ''
    }
  },
  computed: {
    filters () {
      const data = {
        roles: this.roles
      }
      if (this.useIdentifier) {
        data.identifier = this.search
      } else {
        data.search = this.search
      }
      return queryString.stringify(data, { arrayFormat: 'index' })
    }
  },
  methods: {
    getOptionLabel (option) {
      if (typeof option === 'object') {
        return option.name ? option.name + (option.default_car ? ' - indicativ ' + option.default_car.indicativ : '') : option.phone
      }
      return '-'
    },
    onSearch (search, loading) {
      if (search.length) {
        this.search = search
        loading(true)
        this.apiSearch(loading, search, this)
      }
    },
    apiSearch: _.debounce((loading, search, vm) => {
      vm.$axios.get(config.baseApiUrl + '/api/users?' + vm.filters)
        .then((response) => {
          vm.options = response.data.data
        })
        .catch()
        .then(() => {
          loading(false)
        })
    }, 500),
    withPopper (dropdownList, component, { width }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle(
                'drop-bottom',
                state.placement === 'bottom'
              )
            }
          }
        ]
      })
      return () => popper.destroy()
    }
  }
}
</script>

<style scoped>

</style>
